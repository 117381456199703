import React from 'react'
import ReferalSetup from '../../../components/Setups/ReferalSetup'

export function Referral(props) {
    return (
        <div className='content'>
            <ReferalSetup />
        </div>
    )
}

