import React, { useEffect, useState } from 'react'
import { getUserFromToken } from '../../methods/api/auth/auth'

export default function Bubble(props) {
    const [className, setClassName] = useState('bubble')
    const [isMe, setIsMe] = useState(true)
    const [message, setMessage] = useState("")
    const [konselors, setKonselors] = useState([])
    const [username, setUsername] = useState("")

    useEffect(() => {
        props.userID == getUserFromToken().id ? setIsMe(true) : setIsMe(false)
        if(props.conference){
            props.UserID == getUserFromToken().id ? setIsMe(true) : setIsMe(false)
            const kons = props.conference.detail_conferences.map((item, index) => {
                return ({
                    id: item.konselor.user.id,
                    nama_konselor: item.konselor.nama_konselor
                })
            })
            setKonselors(kons)
            console.log(kons)
            console.log(kons.length)
            // findKonselor(props.UserID)
            for(let i=0; i<kons.length; i++){
                console.log(kons[i])
                if(kons[i].id == props.UserID){
                    setUsername(kons[i].nama_konselor)
                }
            }
        }
        const charlimit = 100;
        var lines = atob(props.chat_konseling).split('\n');
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].length <= charlimit) continue;
            var j = 0; let space = charlimit;
            while (j++ <= charlimit) {
                if (lines[i].charAt(j) === ' ') space = j;
            }
            lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
            lines[i] = lines[i].substring(0, space);
        }
        setMessage(lines.slice(0, 10).join('\n'))
    }, [])
    const leftPad = (text) => {
        if (text.toString().length == 1) {
            return "0" + text
        }
        return text
    }

    const findKonselor = (id) => {



    }
    return (
        <div className='bubble' style={{
            flexDirection: isMe ? 'row-reverse' : 'row',
            backgroundColor: isMe ? '#64E171' : '#EBF0F4',
            marginLeft: isMe ? 'auto' : 0,
            alignSelf: 'flex-end',
            maxWidth: '90%',
            width: 'fit-content',
            // flexShrink: 1,
            marginTop: 8,
            marginBottom: 8,
            padding: 8,
            borderRadius: 10,

        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div style={{
                    fontSize: 12
                }}>{username}</div>
                <div>
                    {message.split('\n').map((item, index) => {
                        return (
                            <div>{item}</div>
                        )
                    })}
                </div>

                <div style={{
                    fontSize: 12,
                    textAlign: 'right'
                }}>
                    {leftPad(new Date(props.created_at).getHours()) + ':' + leftPad(new Date(props.created_at).getMinutes())}
                </div>
            </div>
        </div>
    )
}