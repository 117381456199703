import React, { useState } from 'react'
import Button from '../../components/Buttons'
import { getUserFromToken } from '../../methods/api/auth/auth'

export default function Appbar(props) {
    // const [user, setUser] = useState()
    const user = getUserFromToken(false)
    const toDashboard = () => {
        window.location.href = '/dashboard'
    }
    return (
        <header className='header'>
            {/* <img src={require('../../assets/images/logo.png')} /> */}
            <img src={require('../../assets/images/landing/logo_baru.svg')} onClick={()=>window.location.href='/'} style={{cursor: 'pointer'}}/>
            <div>
                <nav>
                    <ul>
                        <li>
                            <a href="#pengumuman">
                                Pengumuman
                                </a>
                        </li>
                        <li>
                            <a href="#layanan">
                                Layanan
                                </a>
                        </li>
                        <li>
                            <a href="#konselor">
                                Konselor
                                </a>
                        </li>
                        <li>
                            {
                                user == null ? <Button title='Login' width={120} height={32} onClick={props.showPopup} background='#4E73DF' /> :
                                user.role == 'admin' ? <Button title='Login' width={120} height={32} onClick={props.showPopup} background='#4E73DF' /> :
                                    user.role == 'konseli' ? <Button title={user?.info?.nim} width={120} height={32} onClick={toDashboard} background='#4E73DF' />
                                        : <Button title={user?.info?.nama_konselor?.split(" ")[0]} width={120} height={32} onClick={toDashboard} background='#4E73DF' />

                            }
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}