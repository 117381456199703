import { headerTemplate, host } from "../config"


const createAgreement = async (konseling_id, konseli_id) => {
    console.log('create agreement', konseling_id, konseli_id)
    const request = await fetch(host + "/api/referral/createagreement", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            konseling_id: konseling_id,
            konseli_id: konseli_id
        })
    })
    const response = await request.json()
    return response
}

const declineReferralAgreement = async (konseling_id) => {
    const request = await fetch(host + "/api/referral/declineagreement", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            konseling_id: konseling_id,
        })
    })
    const response = await request.json()
    return response
}

const storeReferral = async (judul_referral, pesan_referral, jadwal_konselor_id, konseling_id, konselor_tujuan_id) => {
    const body = JSON.stringify({
        judul_referral, pesan_referral, jadwal_konselor_id, konseling_id, konselor_tujuan_id
    })
    console.log(body)
    const request = await fetch(host + "/api/referral", {
        "method": "POST",
        "headers": headerTemplate(),
        body
    })
    const response = await request.json()
    return response
}

const showReferral = async (id_referral) => {
    const request = await fetch(host+'/api/referral/'+id_referral, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await  request.json()
    return response
}

export {
    createAgreement,
    storeReferral,
    showReferral,
    declineReferralAgreement
}