import React, { useState, useEffect, Component } from 'react'
import './selector.css'
import { getJadwals } from '../../methods/api/auth/konselor'
export default class ScheduleSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDay: 'Senin',
            selectedHour: null,
            days: [],
            hours: [],
            jadwal: []
        }
    }
    days = [
        'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'
    ]
    hours = [
        '10.00-11.00', '11.00-12.00', '12.00-13.00', '13.00-14.00',
        '14.00-15.00', '15.00-16.00', '16.00-17.00', '17.00-18.00',
    ]
    onSelectedHour = (hour) => {
        console.log(hour)
        this.setState({ selectedHour: hour }, () => {
            this.props.onScheduleSelected({
                hour
            })
        })

    }
    onSelectedDay = (day) => {
        console.log(day)
        console.log(this.state.jadwal[day])
        if (!this.state.jadwal[day]) return
        const newHours = this.state.jadwal[day].map((item, index) => {
            return { data: item.jam_mulai + '.00-' + item.jam_akhir + '.00', id: item.id, available: item.available, hari: item.hari }
        })
        this.setState({ hours: newHours, selectedHour: null, selectedDay: day }, () => this.props.onScheduleSelected(null))
        console.log('select day', newHours)
    }

    componentDidUpdate(prevProps) {
        if (this.props.konselor.id !== prevProps.konselor.id) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.getjadwal();
        }
    }


    componentDidMount() {
        console.log('get jadwal')
        this.getjadwal()
        this.onSelectedDay('Senin')
    }
    getjadwal = async () => {
        const jadwal = await getJadwals(this.props.konselor.id)
        const days = Object.keys(jadwal.data)

        this.setState({ jadwal: jadwal.data, days })
        this.onSelectedDay(days[0])
    }
    render() {
        return (
            <div className='schedule-container'>
                <div className='days-selector'>
                    {this.days.map((day, index) => {
                        return (
                            <div style={{ color: this.state.jadwal[day] ? 'black' : 'gray' }} className={this.state.selectedDay == day ? 'day active-day' : 'day'} onClick={() => { this.onSelectedDay(day) }}>
                                {day}
                            </div>
                        )
                    })}
                </div>
                <div className='hours-selector'>
                    {this.state.hours.map((hour, index) => {
                        return (
                            <div className={this.state.selectedHour == hour ? 'hour active-hour' : 'hour'} onClick={() => this.onSelectedHour(hour)} style={{}}>
                                {hour.data}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}