import { combineReducers } from "redux";
import { conselies } from "../../utils/dummies/data";


const initialState = {
    conseli: false,
    menu: 'Home',
    isChat: false,
    isCaseConferenceOpen: false,
    isReferralOpen: false,
    conselingSession: null,
    showArrow: false,
    konselingAktif: 0,
    konselingSelesai: 0,
    jadwals: null,
    jadwalOnline: null,
    konselingList: null,
    sessions: null,
    conference: null
}

const conference = (state = initialState.conference, action) =>{
    switch (action.type) {
        case 'SET_CONFERENCE':
            return action.conference
        default:
            return state
    }
}

const konselingList = (state = initialState.konselingList, action) => {
    switch (action.type) {
        case 'SET_KONSELING_LIST':
            return action.konselingList
        default:
            return state
    }
}

const konselingAktif = (state = initialState.konselingAktif, action) => {
    switch (action.type) {
        case 'SET_KONSELING_AKTIF':
            return action.konselingAktif
        default:
            return state
    }
}
const konselingSelesai = (state = initialState.konselingSelesai, action) => {
    switch (action.type) {
        case 'SET_KONSELING_SELESAI':
            return action.konselingSelesai
        default:
            return state
    }
}

const jadwals = (state = initialState.jadwals, action) => {
    switch (action.type) {
        case 'SET_JADWAL':
            return {
                jadwals: action.jadwals
            }
        default:
            return state
    }
}

const jadwalOnline = (state = initialState.jadwalOnline, action) => {
    switch (action.type) {
        case 'SET_JADWAL_ONLINE':
            return action.jadwal
        default:
            return state
    }
}

const activeConseli = (state = initialState.conseli, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_CONSELI':
            return {
                ...action.conseli
            }
        default:
            return state
    }
}

const activeMenu = (state = initialState.menu, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_MENU':
            return action.menu
        default:
            return state
    }
}

const isChat = (state = initialState.isChat, action) => {
    switch (action.type) {
        case 'SET_IS_CHAT':
            return action.status
        default:
            return state
    }
}

const isCaseConferenceOpen = (state = initialState.isCaseConferenceOpen, action) => {
    switch (action.type) {
        case 'OPEN_CASE_CONFERENCE':
            return action.status
        default:
            return state
    }
}
const isReferralOpen = (state = initialState.isReferralOpen, action) => {
    switch (action.type) {
        case 'OPEN_REFERRAL':
            return action.status
        default:
            return state
    }
}

const conselingSession = (state = initialState.conselingSession, action) => {
    switch (action.type) {
        case 'SET_SESSION':
            return action.conselingSession
        default:
            return state
    }
}

const showArrow = (state = initialState.showArrow, action) => {
    switch (action.type) {
        case 'SET_ARROW':
            return action.state
        default:
            return state
    }
}

const sessions = (state = initialState.sessions, action) => {
    switch (action.type) {
        case 'SET_SESSIONS':
            return action.sessions
        default:
            return state
    }
}


const reducers = combineReducers({
    activeConseli,
    activeMenu,
    isChat,
    isCaseConferenceOpen,
    conselingSession,
    showArrow,
    jadwals,
    konselingAktif,
    konselingSelesai,
    jadwalOnline,
    konselingList,
    sessions,
    isReferralOpen,
    conference
})

export default reducers