import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import {adminLogin} from './../../methods/api/auth/register'
import Button from '../../components/Buttons'
import './style.css'

const LoginAdmin = (props) =>{
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    useEffect(()=>{
        setLoading(false)
        if(error.length>0){
            closeSnackbar()
            if(Array.isArray(error)){
                error.map((item, index) => {
                    enqueueSnackbar(item, {variant: 'warning'})
                })
            }else{
                enqueueSnackbar(error, {variant: 'warning'})
            }    
        }
    }, [error])

    const submit = async (e) => {
        e.preventDefault()
        if(isLoading){
            return
        }
        setLoading(true)
        enqueueSnackbar("Sedang memproses data", {
            variant: 'info',
            persist: true,
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom'
            }
        })
        const response = await adminLogin(username, password)
        closeSnackbar()
        if(response.success){

        }else{
            const keys = Object.keys(response.message)
            let errors = keys.map((item, index) => {
                console.log(item)
                return response.message[item]
            })
            console.log(errors)
            setError(errors)
        }
    }
    
    return (
        <div style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <form className='admin-login-popup' onSubmit={(e)=>submit(e)}>
                <div className='admin-login-popup-title'>
                    Login sebagai admin
                </div>
                <input value={username} onChange={(e)=>setUsername(e.target.value)} placeholder='Username'>
                </input>
                <input value={password} type="password" onChange={(e)=>setPassword(e.target.value)} placeholder='password'>
                </input>
                <div >
                    <Button disabled={isLoading} type='submit' height={34} title="Login" onClick={()=>{}}/>
                </div>
            </form>
        </div>
    )
}

export default LoginAdmin