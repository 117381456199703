import React, {Fragment, useEffect, useState} from 'react'
import './style.css'
import Button from "../Buttons";
import {MdClose} from 'react-icons/md'
import {createAgreement as createConferenceAgreement, declineConferenceAgreement} from '../../methods/api/conference'
import {createAgreement, declineReferralAgreement, showReferral} from "../../methods/api/referral";
import ConselorSelector from "../Selector/ConselorSelector";
import {endKonseling} from "../../methods/api/konseling";
import {createRangkuman} from "../../methods/api/rangkuman.js";
import { covertTime } from "../../methods/helper";
import { getUserFromToken } from '../../methods/api/auth/auth';

export function PesanRujukan(props){
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const assignData = async () => {
        const response = await showReferral(props.referral_id)
        console.log(response)
        if(response.success){
            setData(response.data)
            setLoading(false)
        }
    }
    useEffect(()=>{
        assignData()
    }, [])
    return(
        <div className={'agreement-container'}>
            <div className={'dashboard-popup-container'}>
                <BackDrop onPopupDismiss={props.onPopupDismiss}/>
            </div>
            <div className={'dashboard-popup-container'}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '1005'}}>
                        {
                            loading === false &&
                            <Fragment>
                                <div className={'dashboard-popup-card'}>
                                    <div className={'dashboard-popup-card-title'}>
                                        Pesan Rujukan
                                    </div>
                                    <div>
                                        Topik:
                                        <div style={{height: 32, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', padding: 12}}>
                                            {data.judul_referral}
                                        </div>
                                        Pesan:
                                        <div style={{height: 72, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', marginTop: 24, padding: 12}}>
                                            {data.pesan_referral}
                                        </div>
                                    </div>
                                </div>  
                            </Fragment>
                        }

                </div>
            </div>
            <div className={'dashboard-popup-close'} onClick={()=>props.onPopupDismiss()}>
                <MdClose/>
            </div>
        </div>
    )
}
export function RangkumanKonselingPopup(props){
    const [rumusan, setRumusan] = useState("")
    const [treatment, setTreatment] = useState("")
    useEffect(()=> {
        if(props.activeArsip){
            setRumusan(props.activeArsip.rangkuman_konseling.rangkuman)
            setTreatment(props.activeArsip.rangkuman_konseling.treatment)
        }
    }, [])
    const submitRangkuman = async () => {
        console.log(props)
        console.log(rumusan, treatment, props.konseling.id)
        const response = await createRangkuman(rumusan, treatment, props.konseling.id)
        console.log(response)
        if(response.success){
            window.location.href = '/dashboard?redirect=Daftar Konseli'
        }
    }
    return(
        <div className={'agreement-container'}>
            <div className={'dashboard-popup-container'}>
                <BackDrop onPopupDismiss={props.onPopupDismiss}/>
            </div>
            <div className={'dashboard-popup-container'}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '1005'}}>
                    <div className={'dashboard-popup-card'} style={{display: 'flex', flexDirection: 'column'}}>
                        <div className={'dashboard-popup-card-title'}>
                            Rangkuman Konseling
                        </div>
                        <div>
                            Rumusan Masalah Konseling:<br/>
                            <textarea disabled={props.activeArsip!=null} value={rumusan} onChange={(e)=>setRumusan(e.target.value)} style={{height: 32, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', padding: 12}}>
                            </textarea><br/>
                            Treatment Yang Diberikan:<br/>
                            <textarea disabled={props.activeArsip!=null} value={treatment} onChange={(e)=>setTreatment(e.target.value)} style={{height: 72, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', marginTop: 24, padding: 12}}>
                            </textarea>
                        </div>

                        {
                            props.activeArsip && props.activeArsip.referral &&
                            <Fragment>
                                <div className={'dashboard-popup-card-title'}>
                                    Rujukan
                                </div>
                                <div>
                                    Tanggal Dirujuk:<br/>
                                    <input disabled={props.activeArsip!=null} value={covertTime(props.activeArsip.referral.created_at, true)} onChange={(e)=>setRumusan(e.target.value)} style={{height: 32, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', padding: 12}}>
                                    </input><br/>
                                    Dirujuk Oleh:<br/>
                                    <input disabled={props.activeArsip!=null} value={props.activeArsip.referral.konselor.nama_konselor} onChange={(e)=>setRumusan(e.target.value)} style={{height: 32, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', padding: 12}}>
                                    </input><br/>
                                    {
                                        getUserFromToken().role !== 'konseli' &&
                                            <Fragment>
                                                Topik:<br/>
                                                <input disabled={props.activeArsip!=null} value={props.activeArsip.referral.judul_referral} onChange={(e)=>setRumusan(e.target.value)} style={{height: 32, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', padding: 12}}>
                                                </input><br/>
                                                Pesan:<br/>
                                                <textarea disabled={props.activeArsip!=null} value={props.activeArsip.referral.pesan_referral} onChange={(e)=>setTreatment(e.target.value)} style={{height: 72, width: 500, borderStyle: 'solid', borderWidth: 1, borderColor: '#EAEAEA', marginTop: 24, padding: 12}}>
                                                </textarea>
                                            </Fragment>    
                                        }
                                    
                                </div>
                            </Fragment>
                        }
                        

                        <div style={{width: 240}}>
                            {props.activeArsip == null && <Button width={42} title={'Simpan'} onClick={submitRangkuman}/>   }
                        </div>
                    </div>
                </div>
            </div>
            <div className={'dashboard-popup-close'} onClick={()=>props.onPopupDismiss()}>
                <MdClose/>
            </div>
        </div>
    )
}
export function AddConferenceMembers(props){
    const [selectedKonselors, setKonselors] = useState(null)
    const [disabled, setDisabled] = useState(false)
    return(
        <div className='agreement-container'>
            <div className='dashboard-popup-container'>
                <BackDrop onPopupDismiss={props.onPopupDismiss}/>
            </div>
            <div className={'dashboard-popup-content'}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <ConselorSelector onItemChecked={setKonselors} exclude={props.exclude} onAllDisabled={()=>setDisabled(true)}/>
                    </div>
                    <div style={{ width: 72, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button title='Tambahkan' disabled={disabled} onClick={() => {
                            props.onSubmit(selectedKonselors)
                        }} />
                    </div>
                </div>
            </div>
            <div className={'dashboard-popup-close'} onClick={()=>props.onPopupDismiss()}>
                <MdClose/>
            </div>
        </div>
    )
}

function Agreement(props){
    return(
        <div className='agreement-container'>
            <div>
                <h1>{props.title}</h1>
                {props.subTitle}
            </div>
            <div className='agreement-card'>
                <div className='agreement-card-title'>
                    {props.cardTitle}
                </div>
                {props.description}
                <div className='agreement-card-actions'>
                    <div className={'agreement-card-action-button'}>
                        <Button title={'Setuju'} width={128} height={38} onClick={()=>props.setAgreement(true)}/>
                    </div>
                    <div className={'agreement-card-action-button'}>
                        <Button title={'Tolak'} width={128} height={38} buttonStyle={'outline'} onClick={()=>props.onDecline()}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function BackDrop(props){
    return(
        <div className='dashboard-popup-backdrop'>
        </div>
    )
}

const upgradeReferal = async () => {

    const response = await createAgreement()
    if(response.success){
        return true
    }
    return false
}


export default function ReferralAgreementPopup(props){
    const {konseling} = props
    useEffect(()=>{
        if(props.konseling.refered !== 'ask'){
            window.location.href = '/dashboard'
        }
    }, [])
    const setAgreement = async () => {
        const response = await createAgreement(konseling.id, konseling.konseli_id)
        console.log(response)
        if(response.success){
            window.location='/dashboard'
        }
    }
    const declineAgreement = async () => {
        const response = await declineReferralAgreement(konseling.id)
        if(response.success){
            window.location='/dashboard'
        }
    }
    return (
     <div className='dashboard-popup-container'>
         <BackDrop onPopupDismiss={props.onPopupDismiss}/>
         <div className='dashboard-popup-content'>
             <Agreement title = 'Persetujuan Referral'
                        subTitle = 'Konselor Anda meminta persetujuan untuk merujuk Anda ke Konselor lain'
                        cardTitle = 'Pernyataan Persetujuan Referal'
                        description = 'Saya menyetujui dan mengijinkan Konselor saya untuk merujuk saya ke Konselor lain. Pernyataan ini saya buat tanpa ada paksaan dari pihak manapun.'
                        setAgreement={setAgreement}
                        onDecline={declineAgreement}
                        onDismiss={()=>props.onPopupDismiss()}
             />
         </div>
         <div className={'dashboard-popup-close'} onClick={()=>props.onPopupDismiss()}>
             <MdClose/>
         </div>
     </div>
)
}

export function ConferenceAgreementPopup(props){
    const {konseling} = props
    const setAgreement = async () => {
        const response = await createConferenceAgreement(konseling.id, konseling.konseli_id)
        console.log(response)
        if(response.success){
            window.location='/dashboard'
        }
    }
    const declineAgreement = async () => {
        const response = await declineConferenceAgreement(konseling.id)
        if(response.success){
            window.location='/dashboard'
        }
    }
    return (
        <div className='dashboard-popup-container'>
            <BackDrop onPopupDismiss={props.onPopupDismiss}/>
            <div className='dashboard-popup-content'>
                <Agreement title = 'Persetujuan Case Conference'
                           subTitle = 'Konselor Anda meminta persetujuan untuk kasus Anda didiskusikan dengan Konselor lain'
                           cardTitle = 'Pernyataan Persetujuan Case Conference'
                           description = 'Saya menyetujui dan mengijinkan Konselor saya untuk mendiskusikan kasus saya saat ini dengan Konselor lain. Pernyataan ini saya buat tanpa ada paksaan dari pihak manapun.'
                           setAgreement={setAgreement}
                           onDecline={declineAgreement}
                    // onDismiss={()=>props.onDismiss()}
                />
            </div>
            <div className={'dashboard-popup-close'} onClick={()=>props.onPopupDismiss()}>
                <MdClose/>
            </div>
        </div>
    )
}

export function SessionEndAgreementPopup(props){
    const {konseling} = props
    const setAgreement = async () => {
        const response = await endKonseling(konseling.id)
        console.log(response)
        if(response.success){
            window.location='/dashboard'
        }
    }
    return (
        <div className='dashboard-popup-container'>
            <BackDrop onPopupDismiss={props.onPopupDismiss}/>
            <div className='dashboard-popup-content'>
                <Agreement title = ''
                           subTitle = ''
                           cardTitle = 'Akhiri Sesi Konseling'
                           description = 'Anda yakin ingin mengakhiri sesi konseling?'
                           setAgreement={setAgreement}
                           onDismiss={()=>props.onPopupDismiss()}
                />
            </div>
            <div className={'dashboard-popup-close'} onClick={()=>props.onPopupDismiss()}>
                <MdClose/>
            </div>
        </div>
    )
}

ReferralAgreementPopup.defaultProps = {
    setAgreement: () => {},
    onPopupDismiss: () => {}
}

BackDrop.defaultProps = {
    setAgreement: () => {},
    onPopupDismiss: () => {}
}

Agreement.defaultProps = {
    title: 'title',
    subTitle: 'sub title',
    cardTitle: 'card title',
    description: 'very long text'
}
AddConferenceMembers.defaultProps = {
    onPopupDismiss: () => {},
    onSubmit: () => {},
    exclude: []
}