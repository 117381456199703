import React, {useEffect, useState} from 'react'
import './style.css'
import './../Landing/style.css'
import YellowBar from '../../components/Landing/YellowBar'
import {getPengumumans} from "../../methods/api/pengumuman";
export default function Pengunguman(props) {
    const [pengumumans, setPengumumans] = useState([])
    const getData = async () => {
        const response = await getPengumumans()
        if(response.success){
            setPengumumans(response.rows)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    var queryDict = {}
    window.location.search.substr(1).split("&").forEach(function (item) { queryDict[item.split("=")[0]] = item.split("=")[1] })
    if (queryDict.id) {
        return (
            <div>
                <DetailPengungumanCard id={queryDict.id} />
                {/* tes */}
            </div>
        )
    }
    return (
        <div className='landing-section container-section'>
            <div className='section-title'>
                Pengumuman
                {/* {queryDict['id']} */}
            </div>
            <YellowBar />
            <div className='pengunguman-list'>
                {pengumumans.map((item, index) => {
                    return (
                        <a href={'/pengumuman?id=' + item.id} style={{ textDecoration: 'none', color: '#373434' }}>
                            <PengungumanCard {...item} />
                        </a>
                    )
                })}
            </div>
        </div>
    )
}

function PengungumanCard(props) {
    if (props.detail) {
        return (
            <div className='pengunguman-card-detail'>
                <div className='pengunguman-card-title'>
                    {props.judul}
                </div>
                <div className='pengunguman-card-description'>
                    <div className='pengunguman-card-date'>
                        <img src={require('../../assets/images/date.png')} />
                        {new Date(props.created_at).toLocaleTimeString()}
                    </div>
                    <div>
                        {props.isi}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='pengunguman-card'>
            <div className='pengunguman-card-title'>
                {props.judul}
            </div>
            <div className='pengunguman-card-description'>
                <div className='pengunguman-card-date'>
                    <img src={require('../../assets/images/date.png')} />
                    {props.created_at}
                </div>
                <div>
                    {props.isi.substr(0, 100)+" "+(props.isi.length>100 ? "..." : "")}
                </div>
            </div>
        </div>
    )
}

function DetailPengungumanCard(props) {
    const [pengumuman, setPengumuman] = useState(null)
    const getData = async () => {
        const response = await getPengumumans(props.id)
        if(response.success){
            setPengumuman(response.data)
        }
    }
    useEffect(()=>{
        getData()
    }, [])
    return (
        <div className='landing-section container-section'>
            <div className='pengunguman-list'>
                <PengungumanCard {...pengumuman} detail={true} />
            </div>
        </div>
    )
}

// const pengungumans = [
//     {
//         id: 1,
//         title: 'Perubahan Jadwal Konselor',
//         date: '28 Mei 2020',
//         description: `Silahkan login untuk melihat perubahan detail perubahan jadwal Konselor.`
//     }
// ]