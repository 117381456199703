import React, { useEffect, useState } from 'react'
import './style.css'
import Button from '../Buttons'
import ConselingRecords from '../ConselingRecords'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../store/actions'
import { resolveJadwalKonseling, getRecords } from '../../methods/api/konseling'
import Avatar from "../Avatar/Avatar";
import {PesanRujukan, RangkumanKonselingPopup} from "../DashboardPopups";
function ConseliInformation(props) {
    const [jadwal, setJadwal] = useState(null)
    const [records, setRecords] = useState([])
    const [showPesanRujukan, setShowPesanRujukan] = useState(false)
    const [showRangkuman, setShowRangkuman] = useState(false)
    useEffect(() => {
        console.log('mount');
        console.log(props)
    })

    useEffect(() => {
        async function getJadwal() {
            const jad = props.activeConseli.jadwal
            if (jad) {
                const j = jad.hari + ", " + jad.jam_mulai + ".00 - " + jad.jam_akhir + ".00"
                setJadwal(j)
            }
        }
        async function loadData() {
            if (props.activeConseli.rekam_konselings) {
                setRecords(props.activeConseli.rekam_konselings)
                console.log(props.activeConseli.rekam_konselings)
            }
        }
        setRecords([])
        if (props.activeConseli !== false) {
            setJadwal(null)
            getJadwal()
        }
        const query= new URLSearchParams(window.location.search)
        if(query.get("id")){
            // props.onChat()
            // return
        }
        loadData()

    }, [props.activeConseli])

    const { activeConseli: conseli, activeConseli } = props
    // if (conseli == false || conseli == null) return null
    if (activeConseli.id)
        return (
            <div className='information'>
                <div className='conseli-header' >
                    Informasi Konseli
            </div>
                <div className='content'>
                    <div className='conseli-profile'>
                        {/*<div className='avatar'>*/}

                        {/*</div>*/}
                        <Avatar img={activeConseli.konseli.user.avatar}/>
                        <div className='conseli-details'>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={{
                                    fontSize: 20,
                                    fontWeight: "bold"
                                }}>
                                    {activeConseli.konseli?.nama_konseli}
                                </div>
                                <div className='jadwal-badge'>
                                    {jadwal}
                                </div>
                            </div>
                            <table style={{}}>
                                <tr>
                                    <td>
                                        {activeConseli.konseli?.nim}
                                    </td>
                                    <td>
                                        {conseli.konseli?.jenis_kelamin}
                                    </td>
                                    <td>
                                        {'Agama: ' + conseli.konseli?.agama}
                                    </td>
                                    <td>
                                        alamat:
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        {conseli.konseli?.prodi.nama_prodi}
                                    </td>
                                    <td>
                                        {'Tgl. Lahir: ' + conseli.konseli?.tgl_lahir_konseli}
                                    </td>
                                    <td>
                                        {'Suku: ' + conseli.konseli?.suku}
                                    </td>
                                    <td>
                                        {conseli.konseli?.alamat_konseli}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* {conseli.konseli?.prodi.faculty.nama_fakultas} */}
                                    FTI
                                </td>
                                    <td>
                                        {'Umur: 20 tahun'}
                                    </td>
                                    <td>
                                        {'No.HP: ' + conseli.konseli?.no_hp_konseli}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    {activeConseli.referral_id&&<div onClick={()=>setShowPesanRujukan(true)} style={{color: '#F1C40F', textDecoration: 'underline', cursor: 'pointer'}}>pesan rujukan</div>}
                    <div className='ctas'>
                        <div className='cta' >
                            <Button disabled={activeConseli.status_selesai==="E" || activeConseli.status_selesai=="expired"} title='Ruang Konseling' onClick={() => props.onChat()} fontSize={14} width={148} height={28} />
                        </div>
                        <div className='cta'>
                            <Button disabled={activeConseli.status_selesai==="E" || activeConseli.status_selesai=="expired"} title='Case Conference' onClick={() => props.openCaseConference()} fontSize={14} width={148} height={28} />
                        </div>
                        <div className='cta' >
                            <Button disabled={activeConseli.status_selesai==="E" || activeConseli.status_selesai=="expired"} title='Referal' onClick={() => props.openReferral()} fontSize={12} fontSize={14} width={100} height={28} />
                        </div>
                        <div className='cta'>
                            <Button disabled={activeConseli.status_selesai==="C"} title='Rangkuman Konseling' onClick={()=>setShowRangkuman(true)} fontSize={14} width={172} height={28} />
                        </div>
                    </div>

                    <div>
                        <ConselingRecords records={props.activeConseli.rekam_konselings} />
                    </div>
                </div>
                {showPesanRujukan && <PesanRujukan referral_id={activeConseli.referral_id} onPopupDismiss={() => setShowPesanRujukan(false)}/>}
                {showRangkuman && <RangkumanKonselingPopup arspi={props.arsip} activeArsip={props.arsip?activeConseli:null}  konseling={activeConseli} onPopupDismiss={()=>setShowRangkuman(false)}/>}
            </div>
        )
    return null
}

const mapStateToProps = ({ activeConseli, isCaseConferenceOpen, isReferralOpen }) => ({
    activeConseli,
    isCaseConferenceOpen,
    isReferralOpen
})

export default connect(mapStateToProps, mapDispatchToProps)(ConseliInformation)