import React from 'react'
import { getUserFromToken } from './auth'
import { headerTemplate, host } from "../../config"

const register = async (data) => {
    console.log('registering')
    data.role = 'konseli'
    data.c_password = data.password
    // data.tgl_lahir_konseli = "2000-01-01"
    const body = JSON.stringify(data)
    const raw = {
        "method": "POST",
        "headers": headerTemplate(),
        body
    }
    const request = await fetch(host + "/api/auth/register", raw)

    const response = await request.json()
    console.log('return')
    return response
}

const login = async (data) => {
    console.log('login')
    const body = JSON.stringify(data)
    const raw = {
        "method": "POST",
        "headers": headerTemplate(),
        body
    }
    console.log('request', host + "/api/auth/login")
    const request = await fetch(host + "/api/auth/login", raw)
    const response = await request.json()
    console.log(response)
    if (response.success == true) {
        console.log('redirect')
        localStorage.setItem('user', JSON.stringify(response.data))
        localStorage.setItem('token', JSON.stringify(response.token))
        window.location = '/dashboard/'
    }

    return response
}

const loginSiasat = async (data) => {
    console.log('login')
    const body = JSON.stringify(data)
    const raw = {
        "method": "POST",
        "headers": headerTemplate(),
        body
    }

    const request = await fetch(host + "/api/auth/siasat", raw)
    const response = await request.json()
    console.log(response)
    if (response.success == true) {
        // console.log('redirect')
        if(response.action == 'login'){
            localStorage.setItem('user', JSON.stringify(response.data))
            localStorage.setItem('token', JSON.stringify(response.token))
            window.location = '/dashboard/'
        }
    }

    return response
}

const changePassword = async (password_lama, password, repeat, role='konselor') => {
    const user = getUserFromToken()
    const body = JSON.stringify({password_lama,password,repeat,email:user.email, role})
    const raw = {
        "method": "POST",
        "headers": headerTemplate(),
        body
    }
    const request = await fetch(host + "/api/auth/changepassword", raw)
    const response = await request.json()
    return response
}


const adminLogin = async (username, password) => {
    const body = JSON.stringify({username, password})
    const raw = {
        "method": "POST",
        "headers": headerTemplate(),
        body
    }

    const request = await fetch(host + "/api/auth/login/admin", raw)
    const response = await request.json()
    console.log(response)
    if (response.success == true) {
        localStorage.setItem('token', JSON.stringify(response.token))
        window.location = '/admin/'
    }

    return response
}




export {
    register,
    login,
    loginSiasat,
    changePassword,
    adminLogin
}