import React, { useState, useEffect } from 'react'
import { getQuote } from '../../methods/api/quote';
import './style.css'
export default function Quote() {
    const [index, setQuoteIndex] = useState(0);
    const [quotes, setQuotes] = useState([])


    const getData = async () => {
        const response = await getQuote()
        if(response.success){
            setQuotes(response.rows)
        }
    }

    useEffect(()=> {
        getData()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setQuoteIndex((index + 1) % quotes.length)
        }, 5980)
        return () => clearInterval(interval)
    }, [index, quotes])
    if(quotes.length == 0){
        return <div/>
    }
    return (
        <div className='quote-container'>
            <img className='quote-img' src={require('../../assets/images/landing/quote_icon.svg')} />
            <QuoteContainer {...quotes[index]} index={index} />
        </div>
    )
}

function QuoteContainer({ quote, oleh, index }) {
    return (
        <div className='slide-right slide-right-out' style={{ textAlign: 'center', width: '60%', height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
            <div className='quote'>
                {quote}
            </div>
            <div className='author'>
                {'-'+oleh}
            </div>
        </div>
    )
}