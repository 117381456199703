import React, { useEffect, useState } from 'react'
import './style.css'
import RecordCard from './RecordCard'
import Pagination from '../Pagination'
import { getRecords } from '../../methods/api/konseling'
import { connect } from 'react-redux'
function ConselingRecords(props) {
    // useEffect(() => props.record.map((item) => console.log(item)))
    const [slice, setSlice] = useState(100)
    useEffect(() => {
        console.log(props.records)
    })
    return (
        <div className='records'>
            <div className='title'>
                History Conseling
                {/* <Pagination onSlicerChange={setSlice} data={props.records} /> */}
            </div>
            <div className='records-list'>
                <div style={{ display: 'flex', marginLeft: 16 }}>
                    <div style={{ marginRight: 56 }}>
                        Tanggal
                    </div>
                    <div style={{ marginRight: 168 }}>
                        Topik
                    </div>
                    <div>
                        Rekam Konseling
                    </div>
                </div>
                {
                    props.records.length > 0 && props.records.map((record, index) => {
                        return (
                            <RecordCard disabled={props.disabled} id={record.id} date={record.tgl_konseling} topic={record.judul_konseling} problem={record.isi_rekam_konseling} />
                        )
                    })
                }
                {/* {props.records && props.records.slice(0, parseInt(slice)).map((record, index) => {
                    return (
                        <RecordCard record={{ ...record, topic: 'Perkuliahan' }} />
                    )
                })} */}
            </div>
        </div>
    )
}

const mapStateToProps = ({ activeConseli }) => ({
    activeConseli,
})

export default connect(mapStateToProps)(ConselingRecords)