import React, { useState, useRef } from 'react'
import './style.css'
import { GoPencil } from 'react-icons/go'
import { FaSave } from 'react-icons/fa'
import { updateRecords, getKonselings } from '../../methods/api/konseling'

import ReactLoading from 'react-loading';
import { mapDispatchToProps } from '../../store/actions'
import { connect } from 'react-redux'
import { getUserFromToken } from '../../methods/api/auth/auth'
function RecordCard(props) {
    const bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
    const toStringDate = (date) => {
        let x = ""
        if (date.length > 0) {
            const d = date.split("-")
            x += d[2] + " " + bulan[parseInt(d[1]) - 1] + " " + d[0]
            return x
        }
        return date
    }
    const [isEdit, setEdit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [topic, setTopic] = useState(props.topic)
    const [problem, setProblem] = useState(props.problem)
    const onButtonClick = async () => {
        if (isEdit) {
            setLoading(true)
            const request = await updateRecords(props.id, topic, problem)
            console.log(request)

            const response = await getKonselings('konselor_id=' + getUserFromToken().info.id)
            props.setKonselingList(response.rows)

            setLoading(false)
        }
        setEdit(!isEdit)
    }
    const topicRef = useRef()
    const problemRef = useRef()
    const onTopicChange = (e) => {
        setTopic(e.target.value)
    }
    const onProblemChange = (e) => {
        setProblem(e.target.value)
    }



    return (
        <div className='record-card'>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <div className='date'>
                    {toStringDate(props.date)}
                </div>
                {isEdit ? <input className='topic-input' onChange={onTopicChange} value={topic} /> : <div className='topic'>{topic}</div>}
                {isEdit ? <textarea className='record-input' onChange={onProblemChange} value={problem} /> :
                    <div className='problem'>{problem.split('\n').map((item, index) => {
                        return (
                            <div className='problem'>{item}</div>
                        )
                    })}</div>
                }
            </div>
            {
                !props.disabled &&
                <div className='record-card-button' onClick={onButtonClick}>
                    {
                        isLoading ?
                            <ReactLoading width={16} height={16} color='#F1C40F' type='spinningBubbles' />
                            : isEdit ?
                            <FaSave color='#F1C40F' />
                            : <GoPencil color='#F1C40F' />}
                </div>
            }

        </div>
    )
}

RecordCard.defaultProps = {
    id: '',
    date: '',
    topic: '',
    problem: ''
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(RecordCard)