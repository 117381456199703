import React, { useState } from 'react'
import './session.css'
import { mapDispatchToProps } from '../../../../store/actions'
import { connect } from 'react-redux'
import List from '../../../../components/List'
import Button from '../../../../components/Buttons'
import ScheduleSelector from '../../../../components/Selector/ScheduleSelector'
import { useEffect } from 'react'
import {createKonseling, konselingCheck} from '../../../../methods/api/konseling'
import {changeJadwal} from "../../../../methods/api/jadwal";
import { getUserFromToken } from '../../../../methods/api/auth/auth'

function SessionEnrollment(props) {
    const [schedule, setSchedule] = useState(null)
    const [selectedConselor, setSelectedConselor] = useState(null)
    const [sessions, setSessions] = useState([])
    const onSessionCreated = () => {
        console.log(schedule)
        const user = getUserFromToken()
        const data = {
            status_konseling: "non-ref",
            status_selesai: "C",
            konseli_id: user.info.id,
            konselor_id: selectedConselor.id,
            jadwal_konselor_id: schedule.hour.id
        }
        enroll(data)
    }

    const enroll = async (data) => {
        console.log(data)
        const response = await createKonseling(data)
        console.log(response)
        if (response.success) {
            const newSession = new Object({});
            newSession.conselor = selectedConselor
            newSession.schedule = schedule
            props.setArrow(true)
            // props.setSession(newSession)
            props.setActiveMenu('Home')
        }
    }

    const onListSelected = (conselor) => {
        setSelectedConselor(null)
        setSelectedConselor(conselor)
    }

    const check = async () => {
        const user = getUserFromToken()
        console.log("/api/konseling?konseli_id=" + user.info.id);
        const response = await konselingCheck(user.info.id)
        console.log('konseling check')
        console.log(response)
        if (response.success === true) {
            console.log('konselingcheck', response)
            if(response.rows.length>0){
                setSelectedConselor(response.rows[response.rows.length-1].konselor)
            }
            setSessions(response.rows)
            console.log(response.rows)
        }
    }

    useEffect(()=>{
        check()
    }, [])

    const onTargetJadwalSelected = async () => {
        const session = sessions[sessions.length-1]
        const response = await changeJadwal(session.jadwal.id, schedule.hour.id, session.id)
        if(response.success == true){
            window.location = '/dashboard/'
        }
    }

    if (props.activeMenu != 'session enrollment') return null
    if(sessions.length > 0){
        const session = sessions[sessions.length-1]
        return(
            <div className='content'>
                <div className='session-enrollment'>
                    <List title='conselor' onListSelected={(conselor) => onListSelected(conselor)} only={session.konselor}/>
                    <div className='session-enrollment information'>
                        <h1 className='title'>Ganti Jadwal Sesi Konseling</h1>
                        <p> </p>
                        {selectedConselor != null ? <ScheduleSelector onScheduleSelected={(s) => setSchedule(s)} konselor={selectedConselor}/> : null}
                        <div style={{ width: 76, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Button title='Pilih' disabled={schedule == null ? true : false} onClick={onTargetJadwalSelected} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='content'>
            <div className='session-enrollment'>
                <List title='conselor' onListSelected={(conselor) => onListSelected(conselor)} />
                <div className='session-enrollment information'>
                    <h1 className='title'>Daftar Sesi Konseling</h1>
                    <p>Pilihlah Konselor yang jadwalnya masih tersedia</p>
                    {selectedConselor != null ? <ScheduleSelector onScheduleSelected={(s) => setSchedule(s)} konselor={selectedConselor} /> : null}

                    <div style={{ width: 76, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button title='Daftar Sesi' disabled={schedule == null ? true : false} onClick={onSessionCreated} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(SessionEnrollment)