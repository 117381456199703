import React, { useState, useEffect } from 'react'
import './dropdown.css'
import { getNotif, readNotif } from '../../methods/api/notification'
import Skeleton from 'react-loading-skeleton';
import { konselingCheck, showKonseling } from '../../methods/api/konseling';
import { getUserFromToken } from '../../methods/api/auth/auth';

function Dropdown(props) {
    return (
        <div>
            {props.childern}
        </div>
    )
}

function Settings(props) {
    const role = getUserFromToken().role
    const menu = [
        {
            id: 1,
            title: 'Ganti Password',
            onClick: () => {
                props.setActiveMenu('Ganti Password')
            }
        },
        {
            id: 1,
            title: 'Logout',
            onClick: () => {
                if(getUserFromToken().role === 'admin'){
                    window.location = "/admin"
                }else{
                    window.location = "/"
                }

                localStorage.clear()
            }
        }
    ]
    return (
        <div className='dropdown'>
            {menu.map((item, index) => {
                if(index == 0 && role == "konseli"){
                    return (
                        <div/>
                    )
                }
                return (
                    <div className='dropdown-item' onClick={() => {
                            item.onClick()
                            props.onClick()
                            }}>
                        <div>{item.title}</div>
                    </div>
                )
            })}
        </div>
    )
}


function ChatDrop(props) {
    const [notifItems, setNotifItems] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        async function getItems() {
            const response = await getNotif()
            const items = response.rows.filter((item, index) => {
                return item.type === 'chat'
            })
            setNotifItems(items)
            setLoading(false)
        }
        getItems()
    }, [])

    const redirectToChat = async (konseling_id) => {
        window.location.href = "/dashboard?redirect=Daftar Konseli&id="+konseling_id
    }

    const handleOnclick = async (item) => {
        const response = await readNotif(item.id)
        if(response.success){
            if (getUserFromToken().role === 'konseli') {
                props.setActiveMenu('Ruang Konseling')
            }else if(getUserFromToken().role === 'konselor'){
                redirectToChat(item.data)
            }
            props.onClick()
        }
    }
    

    return (
        <div className='dropdown'>
            {
                loading ?
                    <div>
                        <div className='dropdown-item'>
                            <Skeleton count={2} />
                        </div>
                        <div className='dropdown-item'>
                            <Skeleton count={2} />
                            <Skeleton count={2} />
                        </div>
                    </div>
                    :
                    notifItems.map((item, index) => {
                        return (
                            <div className='dropdown-item' onClick={() => {
                                handleOnclick(item)
                            }}>
                                <div
                                    style={{ fontWeight: 'bold' }}
                                >{item.title}</div>
                                <div>{atob(item.message).length>32?atob(item.message).substring(0, 32):atob(item.message)}</div>
                                <div
                                    style={{ textAlign: 'right', fontSize: 8 }}
                                >{item.created_at.split('T')[0]}</div>
                            </div>
                        )
                    })

            }
            {
                loading == false && notifItems.length == 0 &&
                <div className='dropdown-item' onClick={() => { }}>
                    <div>Tidak ada pesan</div>
                </div>
            }
        </div>
    )
}


function NotifDrop(props) {
    const [notifItems, setNotifItems] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        async function getItems() {
            const response = await getNotif()
            const items = response.rows.filter((item, index) => {
                return item.type !== 'chat'
            })
            setNotifItems(items)
            setLoading(false)
        }
        getItems()
    }, [])
    const handleNotifClick = async (item) => {
        const response = await readNotif(item.id)
        if(response.success){
            if(item.type == 'ask_referral'){
                window.location.href = '/dashboard?show=persetujuan referal'
            }else if(item.type == 'agreed_referral' || item.type == 'declined_referral' || item.type == 'new_referral'){
                window.location.href = '/dashboard?redirect=Daftar Konseli'
            }
            if(item.type == 'ask_conference'){
                window.location.href = '/dashboard?show=persetujuan conference'
            }else if(item.type == 'agreed_conference' || item.type == 'declined_conference'){
                window.location.href = '/dashboard?redirect=Daftar Konseli'
            }else if(item.type == 'invitation_conference'){
                window.location.href = '/dashboard?redirect=Case Conference'
            }
        }
    }
    return (
        <div className='dropdown'>
            {
                loading ?
                    <div className='dropdown-item'>
                        <Skeleton count={4} />
                    </div> :
                    notifItems.map((item, index) => {
                        return (
                            <div className='dropdown-item' onClick={()=>handleNotifClick(item)}>
                                <div style={{fontWeight: 'bold'}}>{item.title}</div>
                                <div>{item.message}</div>
                            </div>
                        )
                    })

            }
            {
                loading == false && notifItems.length == 0 &&
                <div className='dropdown-item' onClick={() => { }}>
                    <div>Tidak ada pesan</div>
                </div>
            }
        </div>
    )
}

export {
    Settings,
    ChatDrop,
    NotifDrop
}

