import React, {useState} from 'react'
import AppBar from "../../components/AppBar/AppBar";
import Navigation from "../../components/Navigation";
import AdminHome from "./AdminHome";
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/actions";
import KelolaKonselor from "./KelolaKonselor";
import AdminSetting from "./AdminSetting";
import InformasiPengunguman from "./InformasiPengumuman";
import EditKonselor from "../Dashboard/Conselor/EditKonselor";
import ReportPresensi from "./ReportPresensi"
import { getUserFromToken } from '../../methods/api/auth/auth';
import LoginAdmin from './LoginAdmin';
import GantiPassword from './../Dashboard/Conselor/GantiPassword'
import Button from '../../components/Buttons';

export default function Admin(props){
    const [activeKonselor, setActivekonselor] = useState(null)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const addNewKonselor = () => {
        setActivekonselor(null)
    }
    if(getUserFromToken()){
        if(getUserFromToken().role == 'admin'){
            return(
                <div className={'dashboard'} style={{backgroundColor: '#F8F8F8'}}>
                    <AppBar/>
                    <div style={{display: 'flex'}}>
                        <Navigation user={'admin'}/>
                        <AdminHome/>
                        <KelolaKonselor onKonselorSelected={(konselor) => setActivekonselor(konselor)} newKonselor={addNewKonselor}/>
                        <AdminSetting/>
                        <InformasiPengunguman/>
                        <EditKonselor activeKonselor={activeKonselor}/>
                        <ReportPresensi/>
                        <GantiPassword/>
                    </div>
                </div>
            )
        }else{
            window.location.href = "/"
            return(
                <div></div>
            )
        }
    }else{
        return(
            <LoginAdmin/>
        )
    } 
    
}
