import React from 'react'
import './conselorcards.css'

export default function KonselingCount(props) {
    return (
        <div className='counter-container'>
            <div className='counter-title'>
                {props.title}
            </div>
            <div className='counter-data' style={{ color: props.color, fontSize: 48 }}>
                {props.count}
            </div>
        </div>
    )
}

KonselingCount.defaultProps = {
    title: "tes",
    count: 0,
    color: "black",
    fontSize: 48
}