import React, { useState, useEffect } from 'react'
import Sessions from '../../../components/Sessions'
import './home.css'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../../store/actions'
import ConseliProfile from '../../../components/Conseli/ConseliProfile'
import arrowCreate, { DIRECTION } from 'arrows-svg'

import Button from '../../../components/Buttons'
import { konselingCheck } from '../../../methods/api/konseling'
import { resolveProdi } from '../../../methods/api/prodi'
class ConseliHome extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            session: false,
            sessionReady: false,
            profileReady: false,
        }
    }
    showArrow = () => {
        console.log('show arrow')
        const arrow = arrowCreate({
            className: 'arrow',
            from: {
                direction: DIRECTION.TOP,
                node: document.getElementById('from'),
                translation: [-1, -2],
            },
            to: {
                direction: DIRECTION.TOP,
                node: document.getElementById('to'),
                translation: [-2, -3],
            },
        })
        document.body.appendChild(arrow.node);
        return true
    }

    onSessionCreated = () => {
        this.setState({ session: true })
    }
    // konseliProfile = async () => {
    //     const response = await konselingCheck(JSON.parse(localStorage.getItem('user')).info.id)
    //     const prodi = await resolveProdi(JSON.parse(localStorage.getItem('user')).info.prodi_id)
    //     const faculty = await resolveFaculty(prodi.data.faculty_id)
    //     const konseli = JSON.parse(localStorage.getItem('user'))
    //     konseli.prodi = prodi.data
    //     konseli.faculty = faculty.data
    //     localStorage.setItem('user', JSON.stringify(konseli))
    //     props.setSessions(response.rows)
    //     setLoading(false)
    // }
    componentDidMount() {
        // konseliProfile()
    }
    componentDidUpdate() {
        console.log('component did update')
        if (this.props.showArrow == true) {
            // this.showArrow()
        }
    }
    render() {
        const { profileReady, sessionReady } = this.state
        if (this.props.activeMenu != 'Home') return null
        return (
            <div className='content' style={{ visibility: profileReady && sessionReady ? 'visible' : 'visible' }}>
                <ConseliProfile session={this.state.session} onFinishedLoading={() => this.setState({ profileReady: true })} />
                <Sessions onSessionCreated={() => this.onSessionCreated()} onFinishedLoading={() => this.setState({ sessionReady: true })} />
            </div>
        )
    }
}
const mapStateToProps = ({ activeMenu, showArrow }) => ({ activeMenu, showArrow })
export default connect(mapStateToProps, mapDispatchToProps)(ConseliHome)