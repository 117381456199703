import React, {useState, useEffect, useRef} from 'react'
import Step from '../Step'
import StepCard from '../Step/StepCard'
import StepContent from '../Step/StepContent'
import Button from '../Buttons'
import ConselorSelector from '../Selector/ConselorSelector'
import List from '../List'
import ScheduleSelector from '../Selector/ScheduleSelector'
import { connect } from 'react-redux'
import {createAgreement, storeReferral} from '../../methods/api/referral'
import ReferralAgreementPopup from "../DashboardPopups";
import StackNotification from "../StackNotification";
import { getUserFromToken } from '../../methods/api/auth/auth'

function ReferalSetup(props) {
    const [schedule, setSchedule] = useState(null)
    const [currentStep, setCurrentStep] = useState(1)
    const [agreementCheck, setAgreement] = useState(false)
    const [selectedConselor, setSelectedConselor] = useState(null)
    const id = getUserFromToken().info.id
    const [status, setStatus] = useState('tidak')
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setStatus(props.activeConseli.refered)
        if (props.activeConseli.refered === 'tidak' || props.activeConseli.refered === 'ask') {
            setCurrentStep(1)
        }
        if (props.activeConseli.refered === 'agreed'){
            setCurrentStep(2);
        }
    }, [])

    const onListSelected = (conselor) => {
        setSelectedConselor(null)
        setSelectedConselor(conselor)
    }
    const onAgreementChange = (e) => {
        setAgreement(e.target.checked)
        console.log('agreement', agreementCheck)
    }
    const nextStep = () => {
        setCurrentStep(currentStep + 1)
    }
    const onSessionCreated = () => {
        console.log(schedule)
        const user = getUserFromToken()
        const data = {
            status_konseling: "non-ref",
            status_selesai: "C",
            konseli_id: user.info.id,
            konselor_id: selectedConselor.id,
            jadwal_konselor_id: schedule.hour.id
        }
        // enroll(data)
        nextStep()
    }

    const createNewReferral = async () => {
        if(loading)return
        setLoading(true)
        setError([])
        try{
            const response = await storeReferral(topik.current.value,  pesan.current.value, schedule.hour.id, props.activeConseli.id, selectedConselor.id)
            console.log(response)
            if(response.success == true){
                window.location = '/dashboard'
            }else{
                const keys = Object.keys(response.message)
                const errors = keys.map((item, index) => (response.message[item]))
                setError(errors)

            }
        }catch(e){
            setError(['Terjadi kesalahan, silakan coba lagi'])
            console.log(e)
        }
        setLoading(false)
    }

    const sendAgreement = async () => {
        setError([])
        try{
            const response = await createAgreement(props.activeConseli.id)
            console.log(response)
            if (response.success == true) {
                console.log('set status  to ask')
                setStatus('ask')
            }
        }catch (e) {
            setError(['Terjadi kesalahan, silakan coba lagi'])
        }

    }
    const submit = () => {

    }

    const topik = useRef()
    const pesan = useRef()

    return (
        <div id='content' style={{flexDirection: 'column'}}>
            <div className={'conseli-header'}>
                {props.activeConseli.konseli.nama_konseli}
            </div>
            {error.length > 0 && <StackNotification data={error}/>}
            <div className={'step'} id={'content'} style={{margin: 0, padding: 0}}>
                <Step title='Refferal' description='Merujuk konseli ke konselor lain'>
                    <StepCard
                        id={1}
                        title='1. Persetujuan Referral'
                        description='Meminta persetujuan ke konseli untuk merujuk mereka ke konselor lain'
                        isActive={currentStep == 1}
                    />
                    <StepCard
                        id={2}
                        title='2. Memilih Konselor'
                        description='Memilih konselor yang akan menerima rujukan'
                        isActive={currentStep == 2}
                    />
                    <StepCard
                        id={2}
                        title='3. Memasukkan Pesan Referral'
                        description='Memasukkan pesan referral yang akan dibaca oleh konselor yang ditunjuk'
                        isActive={currentStep == 3}
                    />
                </Step>
                <StepContent
                    id={1}
                    title='1. Persetujuan Referral'
                    isActive={currentStep == 1}
                    description='Sebelum merujuk konseli ke konselor lain , mintalah persetujuan dari konseli.'>
                    {
                        status == 'ask' ?
                            <div style={{ marginTop: 24 }}>
                                {/* <input type='checkbox' onChange={onAgreementChange} /> */}
                                <label>Menunggu persetujuan dari konseli</label>
                                {/* <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '40%', marginLeft: 'auto' }}>
                                    <Button title='Kirim' onClick={sendAgreement} disabled={!agreementCheck} />
                                </div>
                            </div> */}
                            </div>
                            : <div style={{ marginTop: 24 }}>
                                <input type='checkbox' onChange={onAgreementChange} />
                                <label>Minta persetujuan Referral </label>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '40%', marginLeft: 'auto' }}>
                                        <Button title='Kirim' onClick={sendAgreement} disabled={!agreementCheck} />
                                    </div>
                                </div>
                            </div>
                    }
                </StepContent>
                <StepContent
                    id={2}
                    title='2. Memilih Konselor'
                    isActive={currentStep == 2}
                    description='Pilihlah Konselor yang jadwalnya masih tersedia.'>
                    <div style={{ marginTop: 24 }} className='session-enrollment'>
                        <List title='conselor' onListSelected={(conselor) => onListSelected(conselor)} except={id} ListStyle={{height: 64}}/>
                        <div className='session-enrollment information'>
                            {/* <h1 className='title'>Daftar Sesi Konseling</h1>
                        <p>Pilihlah Konselor yang jadwalnya masih tersedia</p> */}
                            {selectedConselor != null ? <ScheduleSelector onScheduleSelected={(s) => setSchedule(s)} konselor={selectedConselor} /> : null}

                            <div style={{ width: 76, marginLeft: 'auto', marginRight: 'auto' }}>
                                {selectedConselor && <Button title='Lanjut' disabled={schedule == null ? true : false} onClick={onSessionCreated} />}
                            </div>
                        </div>
                    </div>
                </StepContent>
                <StepContent
                    id={3}
                    title='3. Memasukkan Pesan Referral'
                    isActive={currentStep == 3}
                    description='Memasukkan pesan referral yang untuk konselor yang ditunjuk'>
                    <div style={{ marginTop: 24, flexDirection: 'column' }} className='setup-forms'>
                        <div>
                            Topik
                        </div>
                        <input ref={topik}></input>
                        <div>
                            Pesan
                        </div>
                        <textarea ref={pesan}></textarea>
                    </div>
                    <div style={{ width: 76, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button disabled={loading} title='Submit' onClick={createNewReferral}/>
                    </div>
                </StepContent>
            </div>


        </div>
    )
}


const mapStateToProps = ({ activeConseli, konselingList }) => ({
    activeConseli, konselingList
})

export default connect(mapStateToProps)(ReferalSetup)