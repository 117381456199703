import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getLatestChat } from '../../methods/api/chat'
import Avatar from "../Avatar/Avatar";
function ConseliCard(props) {
    const { conseli, activeConseli, name, problem } = props
    const [last, setLast] = useState(problem)
    useEffect(() => {
        async function fetchLastChat() {
            const response = await getLatestChat(conseli.id)
            try {
                setLast(atob(response.data.chat_konseling))
            } catch (e) {

            }
        }
        if (props.getLatestChat) {
            fetchLastChat()
        }
    })
    const getStatus = () => {
        let status = ""
        if(props.conseli.status_selesai == 'C'){
            if(props.conseli.status_konseling == 'ref'){
                status = 'Referal'
            }
        }else{
            status = 'Case Close'
        }
        if(props.conseli.status_selesai == 'expired'){
            status = 'Expired'
        }
        return status
    }
    return (
        // <div className={conseli == activeConseli || props.isActive ? 'conseli active-conseli' : 'conseli'}>
        <div className={props.isActive ? 'conseli active-conseli' : 'conseli'}>
            <div style={{width: 42}}>
                {conseli.konseli && <Avatar img={conseli.konseli.user.avatar}/>}
                {conseli.user && <Avatar img={conseli.user.avatar}/>}
                {conseli.konselor && <Avatar img={conseli.konselor.user.avatar}/>}
            </div>
            <div className='detail'>
                <span style={{ fontWeight: 600, fontSize: 17 }}>{name}</span><br></br>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span style={{ fontWeight: 300, fontSize: 12 }}>{last.slice(0, 33)}</span>

                    {
                    props.showStatus && 
                        <div className="conseli-status">
                            {getStatus()}
                        </div>     
                    }
                </div>
            </div>
            
            
        </div>
    )
}

ConseliCard.defaultProps = {
    name: '',
    problem: ''
}
const mapStateToProps = ({ activeConseli }) => ({
    activeConseli,
})
export default connect(mapStateToProps)(ConseliCard)