// const server = 'http://localhost'
let server = 'http://206.189.94.183:8000'
// server = 'https://konseling.uksw.edu/backend'
// server ='http://localhost'
const host = server

const headerTemplate = () => {
    const token = localStorage.getItem("token")
    let headers = {
        "content-type": "application/json",
    }
    if(token){
        headers = {
            "content-type": "application/json",
            "authorization": "Bearer "+token.replaceAll(`"`,"")
        }
    }
    
    return headers
}

export {
    host,
    headerTemplate
}