import React, { Fragment, useEffect } from 'react'
import './AppBar.css'
import { Settings, ChatDrop, NotifDrop } from '../Dropdown/Dropdown'
import { BsChevronDown } from 'react-icons/bs'
import { useState } from 'react'
import { getCount, getNotif } from '../../methods/api/notification'
import { mapDispatchToProps } from '../../store/actions'
import { connect } from 'react-redux'
import Avatar from "../Avatar/Avatar";
import { showKonseling } from '../../methods/api/konseling'
import { getUserFromToken } from '../../methods/api/auth/auth'
function AppBar(props) {
    const [showSettings, setShowSettings] = useState(false)
    const [showChat, setShowChat] = useState(false)
    const [showNotif, setShowNotif] = useState(false)
    const [countChat, setCountChat] = useState(0)
    const [countNotif, setCountNotif] = useState(0)
    const user = getUserFromToken()
    const onSettingsClick = () => {
        setShowSettings(!showSettings)
        setShowChat(false)
        setShowNotif(false)
    }

    useEffect(() => {
        async function getNotifChat() {
            let chat = 0;
            let notif = 0
            const response = await getNotif()
            response.rows.filter((item, index) => {
                // return item.type === 'chat'
                if(item.type === 'chat'){
                    chat++
                }else{
                    notif++
                }
            })
            console.log("notif", response)
            setCountChat(chat)
            setCountNotif(notif)
            // setCountNotif(notif.data)
        }
        getNotifChat()
        // setInterval(() => checkNotif(), 3000)
    }, [props.activeMenu])
    return (
        <div className='appbar row'>
            <img className='logo' src={require('./../../assets/images/logo.png')} onClick={() => window.location = '/'} />
            <div className='row icons'>
                {getUserFromToken().role !== 'admin' ?
                    <Fragment>
                        <div>
                        {countNotif > 0 && <span className='red-dot'></span>}
                        <img className='notification' src={require('./../../assets/images/icon_all/icon_notification.svg')} onClick={() => {
                            setShowNotif(!showNotif)
                            setShowChat(false)
                            setShowSettings(false)
                        }} />
                    </div>
                    <div>
                        {countChat > 0 && <span className='red-dot'></span>}
                        <img className='message-icon' src={require('./../../assets/images/icon_all/icon_notifmessage.svg')} onClick={() => {
                            setShowChat(!showChat)
                            setShowNotif(false)
                            setShowSettings(false)
                        }} />
                    </div>
                    <Avatar img={user.avatar}/>
                    {/*<Avatar/>*/}
                    <BsChevronDown onClick={onSettingsClick} />
                    </Fragment>:
                    <div className='admin-icons'>
                        <Avatar img={user.avatar}/>
                        <div style={{marginLeft: 8}}/>
                        {/*<Avatar/>*/}
                        <BsChevronDown onClick={onSettingsClick} />
                    </div>
                }
                
                
            </div>
            {showSettings && <Settings onClick={()=>setShowSettings(false)} {...props} />}
            {showChat && <ChatDrop onClick={()=>setShowChat(false)} {...props} />}
            {showNotif && <NotifDrop onClick={()=>setShowSettings(false)} {...props} />}
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(AppBar)