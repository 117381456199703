import React from 'react'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../../store/actions'
import Chat from '../../../components/Chat'

function RuangKonseling(props) {
    if (props.activeMenu != 'Ruang Konseling') return null
    return (
        <Chat />
    )
}

const mapStateToProps = ({ activeMenu }) => ({
    activeMenu
})

export default connect(mapStateToProps, mapDispatchToProps)(RuangKonseling)