import { headerTemplate, host } from "../config"


export const getSettings = async () => {
    const request = await fetch(host+"/api/settings", {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

export const setMaxSessions = async (max) => {
    const raw = {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({max})
    }
    const request = await fetch(host + "/api/settings", raw)
    const response = await request.json()
    return response
}
export const setExpired = async (days) => {
    const raw = {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({expired: days})
    }
    const request = await fetch(host + "/api/settings", raw)
    const response = await request.json()
    return response
}