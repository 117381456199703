import React, {useEffect, useState, Fragment} from 'react'
import Button from "../../components/Buttons";
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/actions";
import './style.css'
import {AiOutlineClose} from "react-icons/ai";
import {createPengumuman, destroyPengumumans, getPengumumans, updatePengumuman} from "../../methods/api/pengumuman";
import {createQuote, getQuote} from '../../methods/api/quote'
import {RiDeleteBinLine, RiPencilLine} from "react-icons/all";
import {destroyQuote, updateQuote} from "../../methods/api/quote";

function DeletePengumuman(props){
    const deletePengumuman = async () => {
        const response = await destroyPengumumans(props.id)
        if(response.success){
            props.onSuccess()
        }
    }
    return(
        <div>
            <div className={'histori-sesi-popup'}>
                <div className={'histori-sesi-popup-backdrop'}></div>
                <div className={'hapus-pengumuman-popup'}>
                    <div className={'hapus-pengumuman-title'}>Hapus Pengumuman</div>
                    <div className={'hapus-pengumuman-subtitle'}>Anda yakin ingin menghapus pengumuman?</div>
                    <div className={'hapus-pengumuman-actions'}>
                        <div className={'hapus-pengumuman-action'}>
                            <Button title={'Hapus'} onClick={deletePengumuman}/>
                        </div>
                        <div className={'hapus-pengumuman-action'}>
                            <Button title={'Batal'} buttonStyle={'outline'} onClick={()=>props.onDismiss()}/>
                        </div>
                    </div>
                </div>
                <AiOutlineClose onClick={()=>props.onDismiss()} style={{position: 'absolute', top: 24, right: 24, cursor: 'pointer'}}/>
            </div>
        </div>
    )
}
function PopupPengumuman(props){
    const [judul, setJudul] = useState(props.selectedP?props.selectedP.judul:"")
    const [isi, setIsi] = useState(props.selectedP?props.selectedP.isi:"")
    const submit = async () => {
        if(props.selectedP!==null){
            const response = await updatePengumuman(props.selectedP.id, judul, isi)
            if(response.success){
                props.onSuccess()
            }
            return
        }
        const response = await createPengumuman(judul, isi)
        if(response.success){
            props.onSuccess()
        }
    }

    return(
        <div className={'histori-sesi-popup'}>
            <div className={'histori-sesi-popup-backdrop'}></div>
            <div className={'edit-pengunguman-popup'}>
                <div>Kelola Pengumuman</div>
                <div style={{width: '100%'}}>
                    <div>Judul</div>
                    <input value={judul} style={{width: '90%'}} onChange={(e)=>setJudul(e.target.value)}></input>
                </div>
                <div style ={{width: '100%', height: '100%'}}>
                    <div>Isi</div>
                    <textarea value={isi} style={{width: '90%', height: '40%'}} onChange={(e)=>setIsi(e.target.value)}></textarea>
                </div>
                <div style={{alignSelf: 'flex-end'}}>
                    <Button title={"Publish"} onClick={submit}></Button>
                </div>
            </div>
            <AiOutlineClose onClick={()=>props.onDismiss()} style={{position: 'absolute', top: 24, right: 24, cursor: 'pointer'}}/>
        </div>
    )
}
function DeleteQuote(props){
    const deleteQuote = async () => {
        const response = await destroyQuote(props.id)
        if(response.success){
            props.onSuccess()
        }
    }
    return(
        <div>
            <div className={'histori-sesi-popup'}>
                <div className={'histori-sesi-popup-backdrop'}></div>
                <div className={'hapus-pengumuman-popup'}>
                    <div className={'hapus-pengumuman-title'}>Hapus Quote</div>
                    <div className={'hapus-pengumuman-subtitle'}>Anda yakin ingin menghapus Quote?</div>
                    <div className={'hapus-pengumuman-actions'}>
                        <div className={'hapus-pengumuman-action'}>
                            <Button title={'Hapus'} onClick={deleteQuote}/>
                        </div>
                        <div className={'hapus-pengumuman-action'}>
                            <Button title={'Batal'} buttonStyle={'outline'} onClick={()=>props.onDismiss()}/>
                        </div>
                    </div>
                </div>
                <AiOutlineClose onClick={()=>props.onDismiss()} style={{position: 'absolute', top: 24, right: 24, cursor: 'pointer'}}/>
            </div>
        </div>
    )
}


function PopupQuote(props){
    const [quote, setQuote] = useState(props.selectedQ?props.selectedQ.quote:"")
    const [oleh, setOleh] = useState(props.selectedQ?props.selectedQ.oleh:"")
    const submit = async () => {
        if(props.selectedQ!==null){
            const response = await updateQuote(props.selectedQ.id, quote, oleh)
            if(response.success){
                props.onSuccess()
            }
            return
        }
        const response = await createQuote(quote, oleh)
        if(response.success){
            props.onSuccess()
        }
    }

    return(
        <div className={'histori-sesi-popup'}>
            <div className={'histori-sesi-popup-backdrop'}></div>
            <div className={'edit-pengunguman-popup'}>
                <div>Kelola Quote</div>
                <div style={{width: '100%'}}>
                    <div>Oleh</div>
                    <input value={oleh} style={{width: '90%'}} onChange={(e)=>setOleh(e.target.value)}></input>
                </div>
                <div style ={{width: '100%', height: '100%'}}>
                    <div>Quote</div>
                    <textarea value={quote} style={{width: '90%', height: '40%'}} onChange={(e)=>setQuote(e.target.value)}></textarea>
                </div>
                <div style={{alignSelf: 'flex-end'}}>
                    <Button title={"Publish"} onClick={submit}></Button>
                </div>
            </div>
            <AiOutlineClose onClick={()=>props.onDismiss()} style={{position: 'absolute', top: 24, right: 24, cursor: 'pointer'}}/>
        </div>
    )
}

function InformasiPengumuman(props){
    const [activeOption, setOption] = useState(1)
    const [showPopupPengumuman, setPopupPengumuman] = useState(false)
    const [showDelete, setShowDelete] = useState(null)
    const [showDeleteQuote, setDeleteQuote] = useState(null)
    const [selectedP, setSelectedP] = useState(null)
    const [pengumumans, setPengumumans] = useState([])

    const [showPopupQuote, setShowPopupQuote] = useState(false)
    const [selectedQ, setSelectedQ] = useState(null)
    const [quotes, setQuotes] = useState([])

    const getData = async () => {
        const response = await getPengumumans()
        const response2 = await getQuote()
        console.log(response)
        if(response.success && response2.success){
            setPengumumans(response.rows)
            setPopupPengumuman(false)
            
            setQuotes(response2.rows)
        }
        setShowDelete(null)
        setSelectedP(null)
        setSelectedQ(null)
        setShowPopupQuote(false)
        setPopupPengumuman(false)
        setDeleteQuote(null)
    }
    useEffect(()=>{
        getData()
    }, [])
    if(props.activeMenu != "Informasi Pengumuman"){
        return <div/>
    }
    return(
        <div id={"content"}>
            <div style={{marginTop: 42, display: 'flex', width: '90%'}}>
                <div className={'option-wrapper'} style={{flex: 0}}>
                    <OptionCard onClick={()=>setOption(1)} active={activeOption==1} title={'Pengumuman'} description={'Kelola pengumuman yang dapat dilihat oleh semua pengunjung web'}/>
                    <OptionCard onClick={()=>setOption(2)} active={activeOption==2} title={'Quote'} description={'Kelola quote yang akan ditampilkan di halaman web utama'}/>
                </div>
                {
                    activeOption == 1?
                        <div className={'option'}>
                            <div className={'option-head'}>
                                <div className={'option-search'}>
                                    <input/>
                                </div>
                                <div >
                                    <Button 
                                        onClick={()=>setPopupPengumuman(true)}
                                        title={'Tambah Pengumuman'}
                                        fontSize={12}
                                        width={148}
                                        height={32}
                                        borderRadius={8}
                                        />
                                </div>
                            </div>
                            <div className='option-body'>
                                <table cellSpacing={0} cellPadding={0}>
                                    <tr>
                                        <td>No</td>
                                        <td>Tanggal</td>
                                        <td>Judul</td>
                                        <td> </td>
                                    </tr>
                                    {pengumumans.length>0 && pengumumans.map((item, index) => {
                                        return(
                                            <Fragment>
                                                <tr className={'option-item'}>
                                                    <td>{index+1}</td>
                                                    <td>{new Date(item.created_at).toLocaleDateString()}</td>
                                                    <td>{item.judul}</td>
                                                    <td>
                                                        <RiPencilLine style={{cursor: 'pointer'}} color={'#F1C40F'} size={24} onClick={()=>setSelectedP(item)}/>
                                                        <RiDeleteBinLine style={{cursor: 'pointer'}} color={'#F1C40F'} size={24} onClick={()=>setShowDelete(item.id)}/>
                                                    </td>
                                                </tr>
                                                <div className='option-item-separator'></div>
                                            </Fragment>

                                        )
                                    })}
                                </table>
                            </div>
                        </div>:
                        <div className={'option'}>
                            <div className={'option-head'}>
                                <div className={'option-search'}>
                                    <input/>
                                </div>
                                <div >
                                    <Button 
                                        onClick={()=>setShowPopupQuote(true)}
                                        title={'Tambah Quote'}
                                        fontSize={12}
                                        width={148}
                                        height={32}
                                        borderRadius={8}
                                        />
                                </div>
                            </div>
                            <div className='option-body' style={{width: '100%'}}>
                                <table cellSpacing={0} cellPadding={0}>
                                    <tr>    
                                        <td>No</td>
                                        <td>Tanggal</td>
                                        <td>Isi</td>
                                    </tr>
                                    {quotes.map((item, index) => {
                                        return(
                                            <Fragment>
                                                <tr className='option-item'>
                                                    <td>{index+1}</td>
                                                    <td>{new Date(item.created_at).toLocaleDateString()}</td>
                                                    <td>{item.quote}</td>
                                                    <td>
                                                        <RiPencilLine style={{cursor: 'pointer'}} color={'#F1C40F'} size={24} onClick={()=>setSelectedQ(item)}/>
                                                        <RiDeleteBinLine style={{cursor: 'pointer'}} color={'#F1C40F'} size={24} onClick={()=>setDeleteQuote(item.id)}/>
                                                    </td>
                                                </tr>        
                                                <div className='option-item-separator'></div>
                                            </Fragment>
                                        )
                                    })}                                                                
                                </table>
                            </div>
                        </div>
                }
                
            </div>
            {(showPopupPengumuman || selectedP) && <PopupPengumuman selectedP={selectedP} onDismiss={()=> {
                setPopupPengumuman(false)
                setSelectedP(null)
            }} onSuccess={getData}/>}
            {showDelete && <DeletePengumuman id={showDelete} onSuccess={getData} onDismiss={()=>setShowDelete(null)}/>}
            {(showPopupQuote || selectedQ) && <PopupQuote selectedQ={selectedQ} onDismiss={()=> {
                setShowPopupQuote(false)
                setSelectedP(null)
            }} onSuccess={getData}/>}
            {showDeleteQuote && <DeleteQuote id={showDeleteQuote} onSuccess={getData} onDismiss={()=>setDeleteQuote(null)}/>}
        </div>
    )
}

function OptionCard(props){
    return (
        <div className={props.active?'option-card':'option-card-disable'} onClick={props.onClick}>
            <div className="option-card-body">
                <div className={'option-card-title'}>
                    {props.title}
                </div>
                <div className={'option-card-description'}>
                    {props.description}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })

export default connect(mapStateToProps, mapDispatchToProps)(InformasiPengumuman)