import React, { useEffect, useState } from 'react'
import './chat.css'

export default function ChatDate(props) {
    const [date, setDate] = useState('');
    useEffect(() => {
        var today = new Date();
        var target = new Date(props.date);
        today = today.getDay() + '-' + today.getMonth() + '-' + today.getFullYear();
        target = target.getDay() + '-' + target.getMonth() + '-' + target.getFullYear();
        if (target == today) {
            setDate('Hari ini')
        } else {
            setDate(target)
        }
    }, [])
    return (
        <div className='chat-date'>
            {date}
        </div>
    )
}