import React, { useEffect } from 'react'
import List from '../../components/List'
import ConseliInformation from '../../components/ConseliInformation'
import './Dashboard.css'
import Chat from '../../components/Chat'
import { connect } from 'react-redux'
import CaseConference from './Conselor/CaseConference'
import { Referral } from './Conselor/Referral'
import { showKonseling } from '../../methods/api/konseling'
import { mapDispatchToProps } from '../../store/actions'

function PageDaftarKonseli({ setIsChat, isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli, location, setActiveConseli }) {
    useEffect(() => {
        console.log({
            page: 'daftarkonseli',
            isChat,
            activeMenu
        })
    })

    // useEffect(()=> {
    //     const query= new URLSearchParams(window.location.search)
    //     if(query.get("redirect")==='Daftar Konseli' && query.get("id") && activeConseli){
    //         console.log('activeconseli ')
    //         console.log(activeConseli)
    //         // setIsChat(true)
    //     }else{
    //         console.log('whoops 1')
    //         console.log(query.get("redirect"))
    //     }
    // }, [activeConseli])

    useEffect(()=> {
        const query= new URLSearchParams(window.location.search)
        if(query.get("redirect")==='Daftar Konseli' && query.get("id")){
            console.log(query.get('redirect')+' '+query.get("id"))
            openChatRedirect(query.get("id"))
        }else{
            console.log('whoops 2')
            console.log(query.get("redirect"))
        }
    }, [])
    if (!(activeMenu == 'Daftar Konseli')) {
        return null
    }


    const openChatRedirect = async (konseling_id) => {
        const response = await showKonseling(konseling_id)
        console.log('show konseling '+konseling_id)
        console.log(response)
        if(response.success){
            setActiveConseli(response.data[0])
            setIsChat(true)
        }
    }


    if (isChat === true) {
        return (
            <Chat />
        )
    }

    if (isCaseConferenceOpen) {
        return (
            <CaseConference title='tes' activeConseli={activeConseli}/>
        )
    }

    if (isReferralOpen) {
        return (
            <Referral />
        )
    }

    return (
        <div id="content">
            <List title={activeMenu == "arsip"?"arsip":"conseli"} />
            <ConseliInformation onChat={() => setIsChat(true)} />
        </div>
    )
}
const mapStateToProps = ({ isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli }) => ({ isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli })

export default connect(mapStateToProps, mapDispatchToProps)(PageDaftarKonseli)