import React from 'react'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../store/actions'
import StepCard from './StepCard'
import './step.css'
function Step(props) {
    return (
        <div className='step-container'>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            {props.children}
        </div>
    )
}

export default connect(mapDispatchToProps)(Step)