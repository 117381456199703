import { headerTemplate, host } from '../config'

const getJadwalOnline = async (konselor_id) => {
    // const konselor_id = JSON.parse(localStorage.getItem('user')).info.id
    const request = await fetch(host + "/api/jadwal?konselor_id=" + konselor_id + "&online=", {
        "method": "GET",
        "headers":headerTemplate()
    })
    const response = await request.json()
    return response
}

const changeJadwal = async (current, selected, konseling) => {
    console.log('change '+current, selected, konseling);
    const request = await fetch(host + "/api/jadwal/change", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            current: current,
            selected: selected,
            konseling: konseling
        })
    });
    const response = await request.json()
    return response
}

export {
    getJadwalOnline,
    changeJadwal
}