const covertTime = (date, useTime=false) => {
    const d = new Date(date)
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
    const months  = ['January', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    const tanggal = d.getDate()
    const _hari = d.getDay()
    const _bulan = d.getMonth()
    const tahun = d.getFullYear()

    const hari = days[_hari]
    const bulan = months[_bulan]

    let ret = hari+ ', '+tanggal+' '+bulan+' '+tahun
    if(useTime){
        ret+=' '+new Date(date).toLocaleTimeString().replace(".", ":")
    }
    return ret
}

export {
    covertTime
}