const conselies = [
    {
        "_id": "5ea98d727a4fae73c9b079d4",
        "age": 20,
        "dob": "1 Januari 2000",
        "name": "Fitzpatrick Miranda",
        "gender": "male",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "duis adipisicing laboris consectetur et eu id",
        "records": [
            {
                "id": "5ea98d72bc15213e20f2a1ad",
                "problem": "Lorem dolore pariatur Lorem cillum minim magna mollit aute eu",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d725d91aea993ca52db",
                "problem": "sit eu aliquip consequat officia consequat id irure est ipsum non qui nulla nostrud non",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d724444a207319df731",
                "problem": "anim nisi nisi do culpa ipsum duis elit culpa consectetur eu",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d722a04a9db746bedf7",
                "problem": "quis mollit nulla do sunt laborum adipisicing",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72e49235001b8ffee2",
                "problem": "et et ut magna consequat ex culpa eu deserunt consequat duis exercitation ea incididunt id",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7226f45088b267d8a7",
                "problem": "esse sint tempor ad do laborum qui proident deserunt",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7207825eb3c3b21511",
                "problem": "elit ipsum labore cupidatat cupidatat minim ex",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72e2925e47fd4a5dc5",
                "problem": "voluptate id labore sunt sint magna cupidatat excepteur est consequat occaecat enim aliqua aliquip velit",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d721096eac32793b70f",
                "problem": "aliqua fugiat labore officia veniam est dolore reprehenderit ea deserunt",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72b3db8781b61d3cb0",
                "problem": "consequat occaecat proident adipisicing minim fugiat laborum",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72af87cfb9e201c8dd",
        "age": 24,
        "dob": "1 Januari 2000",
        "name": "Johanna Elliott",
        "gender": "female",
        "prodi": "S1 Desain Komunikasi Visual",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "in incididunt amet ullamco elit excepteur ea sit tempor do",
        "records": [
            {
                "id": "5ea98d720b45e76decf9f72c",
                "problem": "culpa exercitation dolore culpa eu consequat",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72452d504fc5d77975",
                "problem": "magna labore laborum aute voluptate culpa ea nulla veniam",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d723d6b8997da43e408",
                "problem": "ad ex eiusmod incididunt occaecat nostrud aliqua non excepteur occaecat sint esse commodo excepteur eiusmod",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d729e8132ad4ca6c887",
                "problem": "id voluptate anim consequat do pariatur eiusmod voluptate aute",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d723f1744f5c2f7efaf",
                "problem": "dolor eiusmod tempor est aute exercitation officia occaecat incididunt esse nulla pariatur",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7212a4fef8e1695195",
                "problem": "exercitation commodo anim deserunt deserunt deserunt enim",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72d3d39775042a8d09",
                "problem": "sunt duis voluptate tempor sit tempor cupidatat adipisicing ad incididunt minim",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d725b6d61ec1306eb5e",
                "problem": "dolor culpa magna et reprehenderit laboris magna ut sint et elit sit aliquip",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72ac73375685129c70",
                "problem": "culpa culpa ex anim ipsum exercitation ea",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72069fdc1794c61d44",
        "age": 28,
        "dob": "1 Januari 2000",
        "name": "Daniels Moran",
        "gender": "male",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "non ex cillum duis occaecat cillum occaecat in incididunt minim ex mollit adipisicing",
        "records": [
            {
                "id": "5ea98d7272f19df994a2e48c",
                "problem": "deserunt pariatur excepteur eu esse pariatur exercitation velit nostrud",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72cb32bbade7b2d75c",
                "problem": "sint esse voluptate officia occaecat ut qui sint culpa",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d728e2baac3c8f992a4",
        "age": 30,
        "dob": "1 Januari 2000",
        "name": "Mai Morton",
        "gender": "female",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "veniam fugiat commodo et mollit",
        "records": [
            {
                "id": "5ea98d720f4cddf70079ae3a",
                "problem": "veniam culpa Lorem ut nisi ullamco",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72179af296af54993b",
                "problem": "consectetur excepteur tempor elit elit dolore laborum qui id sunt commodo do laborum",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72bb02c8a3187bf5ff",
        "age": 40,
        "dob": "1 Januari 2000",
        "name": "Lina Wong",
        "gender": "female",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "mollit id mollit culpa ex incididunt fugiat occaecat pariatur cillum",
        "records": [
            {
                "id": "5ea98d7212b55d166e8c3eee",
                "problem": "sunt et consequat magna ea voluptate officia do duis",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d727357691ce2502d22",
                "problem": "tempor quis cillum quis Lorem magna minim irure esse velit aliquip aliquip",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72120ff2492b458424",
        "age": 35,
        "dob": "1 Januari 2000",
        "name": "Moran Bullock",
        "gender": "male",
        "prodi": "S1 Sistem Informasi",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "irure do labore aute commodo consequat reprehenderit",
        "records": [
            {
                "id": "5ea98d72356898876fa71ccf",
                "problem": "aliqua cillum elit nulla Lorem quis sunt Lorem",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d722e725152416ffdf7",
                "problem": "fugiat aliqua non quis magna deserunt adipisicing est id culpa id excepteur",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7207dba2181da5eba4",
                "problem": "duis magna magna id mollit reprehenderit cupidatat minim tempor laboris",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d728acba1088eb43685",
                "problem": "excepteur proident fugiat reprehenderit dolor est proident deserunt pariatur mollit id qui",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7279fa0f6e6b9bc394",
                "problem": "mollit voluptate enim duis ut nulla culpa",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72d4ce01f331c737a2",
                "problem": "nostrud quis mollit aute commodo magna sit",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7233f40c728c7890b7",
                "problem": "et nulla laboris ea nisi id minim excepteur consequat",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d724d75ac186f11c4e5",
                "problem": "elit proident pariatur consectetur amet qui pariatur exercitation laboris",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7205116e7d418fba72",
                "problem": "minim aute exercitation mollit sunt excepteur laboris consequat",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72f049eb9e684399cd",
        "age": 33,
        "dob": "1 Januari 2000",
        "name": "Sweeney Hall",
        "gender": "male",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "aliqua veniam tempor et consequat anim consectetur ex id exercitation laboris est",
        "records": [
            {
                "id": "5ea98d72b60d5c93081a734e",
                "problem": "minim in aute deserunt ullamco ullamco exercitation sunt reprehenderit velit",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d723c8dd9ae9a204582",
                "problem": "non est veniam est deserunt do anim anim irure do consectetur ipsum occaecat sunt minim",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7202a21c4d02d485a4",
                "problem": "officia est eu velit do Lorem adipisicing occaecat",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72f4bfa80e179bfd3b",
        "age": 23,
        "dob": "1 Januari 2000",
        "name": "Bradford Hyde",
        "gender": "male",
        "prodi": "S1 Sistem Informasi",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "mollit nostrud exercitation velit enim non laborum",
        "records": [
            {
                "id": "5ea98d72c8f0424093a309fe",
                "problem": "consectetur qui id laborum mollit irure Lorem qui qui",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72067711a8113bf71b",
                "problem": "amet id magna quis cupidatat Lorem labore occaecat cupidatat dolor est laboris commodo",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7230faad6bda660830",
                "problem": "in nostrud sunt labore excepteur consectetur adipisicing enim sunt Lorem proident occaecat sint ut esse",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d7288d2c2d4f9c0c1ec",
        "age": 36,
        "dob": "1 Januari 2000",
        "name": "Graves Barton",
        "gender": "male",
        "prodi": "S1 Sistem Informasi",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "irure labore amet do tempor laborum consectetur minim nostrud duis eu aliqua",
        "records": [
            {
                "id": "5ea98d7227dc37950c103a5f",
                "problem": "in pariatur reprehenderit nostrud sit commodo incididunt",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72fa96b3b761b3c844",
                "problem": "ea fugiat laborum cupidatat id voluptate mollit",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7223a8e07d0935b9ef",
                "problem": "laboris fugiat tempor adipisicing laborum commodo adipisicing sit commodo ut ex sunt",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72ee5b9407a74eecc4",
                "problem": "anim voluptate non deserunt et tempor",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d729826227fc6a26f4b",
                "problem": "sint aliqua dolor enim labore eiusmod voluptate dolor ullamco et",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72b436c9a0bf9a9482",
                "problem": "dolor magna non sint cupidatat elit id excepteur",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72f9f3b1b9b2289f37",
        "age": 31,
        "dob": "1 Januari 2000",
        "name": "Sondra Merritt",
        "gender": "female",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "cupidatat ipsum exercitation do aliqua mollit sit eu cupidatat deserunt fugiat magna ad",
        "records": [
            {
                "id": "5ea98d7295a2ac5d38e2e5f3",
                "problem": "eiusmod laborum Lorem amet aliqua officia tempor officia",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72db7cdc1459159582",
        "age": 20,
        "dob": "1 Januari 2000",
        "name": "Claudia Nicholson",
        "gender": "female",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "eiusmod excepteur proident esse dolor laboris pariatur deserunt fugiat",
        "records": [
            {
                "id": "5ea98d72f479754f92602096",
                "problem": "irure culpa quis adipisicing voluptate esse consectetur duis do officia anim",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d720e9868628b23c0b5",
                "problem": "aute veniam et laboris consequat cupidatat velit nisi nulla laboris incididunt amet",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7229df8b6fdc65cfef",
                "problem": "pariatur cupidatat duis dolore occaecat nulla nostrud nostrud in",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d729d963788437dbaca",
                "problem": "ea occaecat enim dolore ex cupidatat nulla non id sunt cillum",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d723998f803369d2c0a",
                "problem": "fugiat ad ad amet magna pariatur ex sunt do velit",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72f0be20ee17a773d1",
        "age": 24,
        "dob": "1 Januari 2000",
        "name": "Pope Santana",
        "gender": "male",
        "prodi": "S1 Sistem Informasi",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "proident irure laborum in aliquip consequat enim incididunt incididunt",
        "records": [
            {
                "id": "5ea98d72dab6b0725953a55f",
                "problem": "deserunt enim cupidatat dolor aute occaecat aliqua consectetur occaecat sunt qui cupidatat duis sint",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72a5e2321d245fe22a",
                "problem": "consequat veniam officia cillum ea in pariatur amet aliquip aliqua",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72d66ddf4066a1f340",
                "problem": "dolore eiusmod pariatur anim ad incididunt eiusmod sunt et ullamco aliquip aliqua enim ullamco",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d7201bda9effe182a28",
                "problem": "incididunt mollit nulla occaecat nostrud",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72d7c99fdeeb3f2166",
        "age": 37,
        "dob": "1 Januari 2000",
        "name": "Beverley Christian",
        "gender": "female",
        "prodi": "S1 Desain Komunikasi Visual",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "nulla nostrud amet labore amet veniam aliquip",
        "records": []
    },
    {
        "_id": "5ea98d72d09fc949609d0a20",
        "age": 34,
        "dob": "1 Januari 2000",
        "name": "Wilson Joseph",
        "gender": "male",
        "prodi": "S1 Teknik Informatika",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "qui eiusmod dolor voluptate commodo",
        "records": [
            {
                "id": "5ea98d72cca9c71d3e0dbee1",
                "problem": "et eiusmod cupidatat dolor nostrud aliquip officia velit est in proident aliqua",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72a15e623f2c3f17b7",
                "problem": "aliqua amet proident consequat non velit laboris enim dolor amet",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d727eb6855732d76ad4",
                "problem": "anim dolore exercitation ad fugiat dolore excepteur voluptate reprehenderit do ex sit",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72bc75ae3ea9da3429",
                "problem": "incididunt amet esse labore qui",
                "date": "12/30/2000"
            },
            {
                "id": "5ea98d72bb2c9db6b9a0d613",
                "problem": "elit enim ad velit esse in",
                "date": "12/30/2000"
            }
        ]
    },
    {
        "_id": "5ea98d72c794844acc20fe98",
        "age": 28,
        "dob": "1 Januari 2000",
        "name": "Nunez Mitchell",
        "gender": "male",
        "prodi": "S1 Sistem Informasi",
        "faculity": "Fakultas Teknologi Informasi",
        "problem": "officia et quis esse voluptate sint sint voluptate exercitation Lorem duis esse",
        "records": [
            {
                "id": "5ea98d726587c30c1c7df752",
                "problem": "minim consequat voluptate enim incididunt duis eiusmod duis excepteur voluptate et dolor",
                "date": "12/30/2000"
            }
        ]
    }
]

const conferences = [
    {
        id: 1,
        title: 'Anxiety',
        quote: 'officia et quis esse voluptate',
        members: [
            {
                name: 'Nunez Mitchell',
                host: true
            },
            {
                name: 'Beverley Christian',
                host: false
            },
        ]
    }
]

// payload
// [
//     '{{repeat(5, 7)}}',
//     {
//         _id: '{{objectId()}}',
//         age: '{{integer(20, 40)}}',
//         dob: '{{new Date(1970, 0, 1), new Date(2000, 11, 30)}}',
//         name: '{{firstName()}} {{surname()}}',
//         gender: '{{gender()}}',
//         faculity: 'Fakultas Teknologi Informasi',
//         problem: '{{lorem(integer(5,15), "words")}}',
//         records: [
//             '{{repeat(0, 7)}}',
//             {
//                 id: '{{objectId()}}',
//                 problem: '{{lorem(integer(5, 15), "words")}}'
//             }
//         ]
//     }
// ]

export {
    conselies,
    conferences
}