import React from 'react'
import './style.css'
export default function Button(props) {
    const outline = props.buttonStyle === 'outline' ? {backgroundColor: 'white', borderWidth: 1, borderColor: '#868181', color: '#868181', borderStyle: 'solid'} : {}
    return (
        <input type={props.type} value={props.title} className={props.disabled == true ? 'button disabled '+props.className : 'button '+props.className} style={{ ...props, ...outline }} onClick={() => props.disabled ? {} : props.onClick()}>
            {/* <input type={props.type} value={props.title}></input> */}
        </input>
    )
}

Button.defaultProps = {
    title: 'title',
    color: 'white',
    width: 170,
    height: 46,
    disabled: false,
    type: 'button',
    // backgroundColor: '#F1C40F'
}