import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { mapDispatchToProps, setActiveMenu } from '../../../store/actions'
import './CaseConference.css'
import Step from '../../../components/Step'
import StepCard from '../../../components/Step/StepCard'
import StepContent from '../../../components/Step/StepContent'
import Button from '../../../components/Buttons'
import ConferenceSetup from '../../../components/Setups/ConferenceSetup'
import {showConferenceByKonseling} from "../../../methods/api/conference";

function CaseConference(props) {
    const [currentStep, setCurrentStep] = useState(1)
    const [agreementCheck, setAgreement] = useState(false)
    const [loading, setLoading] = useState(true)
    const nextStep = () => {
        setCurrentStep(currentStep + 1)
    }
    const onAgreementChange = (e) => {
        setAgreement(e.target.checked)
        console.log('agreement', agreementCheck)
    }
    const check = async () => {
        const response = await showConferenceByKonseling(props.activeConseli.id)
        if(response.success){
            if(response.rows){
                window.location.href = "/dashboard?redirect=Case Conference&case="+response.rows.id
            }else{
                setLoading(false)
            }
        }

    }
    useEffect(() => {
        check()
    }, [])
    return (
        <div className='content'>
            <div className='case-conference'>
                {!loading && <ConferenceSetup activeConseli={props.activeConseli}/>}
            </div>
        </div>
    )
}

export default connect(mapDispatchToProps)(CaseConference)