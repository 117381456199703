import { headerTemplate, host } from "../config"

const resolveProdi = async (prodiID) => {
    const request = await fetch(host + "/api/prodi/" + prodiID, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const resolveFaculty = async (facultyID) => {
    const request = await fetch(host + "/api/faculty/" + facultyID, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

export {
    resolveProdi,
    resolveFaculty
}