import React, {useEffect, useState} from 'react'
import './ConferenceInformation.css'
import Chat from "../Chat";
import Avatar from "../Avatar/Avatar";
import {BsPlusCircleFill} from 'react-icons/bs'
import {AddConferenceMembers} from "../DashboardPopups";
import {createDetail} from "../../methods/api/conference";
import Button from "../Buttons";
import { getUserFromToken } from '../../methods/api/auth/auth';

export default function ConferenceInformation({conference, isChat, setChat, onConferenceSelected}) {
    const [showAddMember, setShowAddMember] = useState(false)
    const [members, setMembers] = useState([])
    const [host, setHost] = useState(null)
    const user = getUserFromToken()
    const onAddMemberSubmit = async (konselors) => {
        const response = await createDetail(konselors, conference.id)
        console.log(response)
        if(response.success){
            window.location.href = "/dashboard?redirect=Case Conference"
        }
    }
    useEffect(()=>{
        if(conference){
            console.log('checking')
            const memberIDs = conference.detail_conferences.map((detail, index) => {
                if(detail.role == 'host'){
                    setHost(detail.konselor_id)
                }
                return detail.konselor.id
            })
            console.log('memberids',memberIDs)
            setMembers(memberIDs)
        }else{
            console.log('not checking')
        }
    }, [conference])
    if(isChat){
        return(
            <Chat conference={conference} setChat={setChat}/>
        )
    }
    return (
        <div className='information'>
            <div className='conseli-header'>
                Informasi Conference
            </div>
            {conference &&
                <div className='content'>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <table style={{ width: 300 }}>
                            <tr>
                                <td>Judul Conference</td>
                                <td>:{conference?.judul_case_conference}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Anggota Conference</div>
                                </td>
                                <td>
                                    {/*<div style={{width: 52, height: 52, cursor: 'pointer'}}>*/}
                                    {host == user.info.id && <BsPlusCircleFill color={'#F1C40F'} style={{cursor: 'pointer'}} onClick={()=>setShowAddMember(true)}/>}
                                    {/*</div>*/}
                                </td>
                            </tr>
                        </table>
                        <div style={{width: 200}}>
                            <Button title={'Masuk Conference'} onClick={()=>onConferenceSelected(conference)}/>
                        </div>
                    </div>

                    {/* <div className='title' style={{ height: 24 }}>Judul Conference    :Anxiety</div> */}
                    <ul>
                        {conference.detail_conferences.map((detail, index) => {
                            return(
                                <li>
                                    <div className='conselor-conference'>
                                        {/*<div className='avatar'>*/}
                                        {/*</div>*/}
                                        <Avatar img={detail.konselor.user.avatar}/>
                                        <div className='conference-name'>
                                            {detail.konselor.nama_konselor}
                                        </div>
                                        <div className='conference-role'>
                                            {detail.role}
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>

                </div>
            }
            {showAddMember && <AddConferenceMembers onPopupDismiss={()=>setShowAddMember(false)} onSubmit={onAddMemberSubmit} exclude={members}/>}
        </div>
    )
}