import { headerTemplate, host } from "../config"
import { getUserFromToken } from "./auth/auth"

const getCount = async (params) => {
    const request = await fetch(host + "/api/notification/count/" + getUserFromToken().id + "?" + params,{
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const getNotif = async () => {
    const request = await fetch(host + "/api/notification?user_id=" + getUserFromToken().id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const readNotif = async (id) => {
    const request = await fetch(host + "/api/notification/read/"+id, {
        "method": "POST",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

export {
    getCount,
    getNotif,
    readNotif
}