import React, {useEffect, useState} from 'react'
import '../Dashboard.css'
import { connect } from 'react-redux'
import List from "../../../components/List";
import {ArsipInformation} from "../../../components/ArsipInformation";
import { getUserFromToken } from '../../../methods/api/auth/auth';

function KonseliArsip({ setIsChat, isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli }) {
    const konseli_id = getUserFromToken().info.id
    const [activeArsip,setActiveArsip] = useState(null)
    const [arsipAvailable, setArsipAvailable] = useState(null)
    useEffect(() => {
        console.log({
            page: 'daftarkonseli',
            isChat,
            activeMenu
        })
    })
    if (!(activeMenu == 'Arsip')) {
        return null
    }

    return (
        <div id="content">
            <List title={"arsip"} konseli_id={konseli_id} setActiveArsip={setActiveArsip} setArsipAvailable={setArsipAvailable}/>
            {/*<ConseliInformation onChat={() => setIsChat(true)} arsip={true}/>*/}

            {activeArsip  && <ArsipInformation arsipAvailable={arsipAvailable} activeArsip={activeArsip}/>}
        </div>
    )
}
const mapStateToProps = ({ isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli }) => ({ isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli })

export default connect(mapStateToProps)(KonseliArsip)