import React, { useEffect, useState } from 'react'
import './style.css'
import Button from '../../components/Buttons'
import { getLatestPengumuman } from '../../methods/api/pengumuman'
export default function (props) {
    const [pengumuman, setPengumuman] = useState(null)
    const getData = async () => {
        const response = await getLatestPengumuman()
        if(response.success){
            setPengumuman(response.data)
        }
    }
    useEffect(()=> {
        getData()
    },[])
    if(!pengumuman){
        return(
            <div className='pengunguman-flash'>
                <div className="icon-pengumuman" style={{}}>
                    <img src={require('../../assets/images/landing/landingpage_icon_pengumuman.svg')} />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 24
                }} className='pengunguman-wraper'>
                    <div className='pengunguman-flash-title'>
                         <br/><br/>
                    </div>
                    <div className='pengunguman-flash-description'>
                        Belum ada pengumuman
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%'
                }}>
                    <div style={{
                        width: 300
                    }}>

                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='pengunguman-flash'>
            <div className="icon-pengumuman" style={{}}>
                <img src={require('../../assets/images/landing/landingpage_icon_pengumuman.svg')} />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 24
            }} className='pengunguman-wraper'>
                <div className='pengunguman-flash-title'>
                    {pengumuman.judul}
                </div>
                <div className='pengunguman-flash-description'>
                    {pengumuman.isi.substring(0, 200)+" "+(pengumuman.isi.length>200?"...":"")}
                </div>
                <a href={'/pengumuman?id='+pengumuman.id}>
                    Lihat Selengkapnya
                </a>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
            }}>
                <div style={{
                    width: 300
                }}>

                    <a href="/pengumuman" style={{ textDecoration: 'none' }}>
                        <Button title={'Lihat semua pengumuman'} onClick={() => { }} background='#4E73DF' />
                    </a>
                </div>
            </div>
        </div>
    )
}