import React, { useState } from 'react'
import './tes.css'
import Chat from '../../components/Chat'
import Popup from '../../components/Landing/Popup'
export default function (props) {
    const [showPopup, setPopup] = useState(true)
    return (
        <div className='chat-test'>
            <Chat />
            {showPopup && <Popup onClose={() => setPopup(false)} />}
        </div>
    )
}