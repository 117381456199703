import React, {useEffect, useRef, useState} from 'react'
import { connect } from 'react-redux'
import './setups.css'
import Step from '../Step'
import StepCard from '../Step/StepCard'
import StepContent from '../Step/StepContent'
import Button from '../Buttons'
import { mapDispatchToProps } from '../../store/actions'
import List from '../List'
import ScheduleSelector from '../Selector/ScheduleSelector'
import ConselorSelector from '../Selector/ConselorSelector'
import {createAgreement, createConference, showConferenceByKonseling} from "../../methods/api/conference";
import { getUserFromToken } from '../../methods/api/auth/auth'


function ConferenceSetup(props) {
    const [currentStep, setCurrentStep] = useState(1)
    const [agreementCheck, setAgreement] = useState(false)
    const [status, setStatus] = useState('tidak')
    const [selectedKonselors, setKonselors] = useState([])
    const [caseConference, setCaseConference] = useState(null)
    const [loading, setLoading] = useState(false)
    const nextStep = () => {
        setCurrentStep(currentStep + 1)
        // console.log(selectedKonselors)
    }
    const onAgreementChange = (e) => {
        setAgreement(e.target.checked)
        console.log('agreement', agreementCheck)
    }
    const sendAgreement = async () => {
        const response = await createAgreement(props.activeConseli.id )
        console.log(response)
        if(response.success){
            setStatus('ask')
        }
    }
    const createNewConference = async () => {
        if(loading)return
        setLoading(true)
        const response = await createConference(judul.current.value, getUserFromToken().info.id, props.activeConseli.id, selectedKonselors)
        console.log(response)
        if(response.success){
            // props.setActiveMenu('conference')
            const conf = await showConferenceByKonseling(props.activeConseli.id)
            if(conf.success){
                window.location.href = '/dashboard?redirect=Case Conference&case='+conf.rows.id
            }
        }
        setLoading(false)
    }
    const judul = useRef()
    const check = async () => {
        const response = await showConferenceByKonseling(props.activeConseli.id)
        if(response.success){
            if(response.rows.id){
                setCaseConference(response.rows)
                setCurrentStep(3)
                return true
            }
        }
        return false
    }
    useEffect(()=>{
        setStatus(props.activeConseli.conferenced)
        if(props.activeConseli.conferenced == 'tidak' || props.activeConseli.conferenced == 'ask' || props.activeConseli.conferenced == 'declined'){
            setCurrentStep(1)
        }else if (props.activeConseli.conferenced == 'agreed'){
            setCurrentStep(2)
        }
        // check()
    }, [])
    return (
        <div id='content' style={{flexDirection: 'column'}}>
            <div className={'conseli-header'}>
                {props.activeConseli.konseli.nama_konseli}
            </div>
            <div className={'step'} id={'content'} style={{margin: 0, padding: 0}}>
                <Step title='Case Conference' description='Melakukan konferensi dengan konselor lain untuk mendapatkan solusi terbaik'>
                    <StepCard
                        title='1. Persetujuan Case Conference'
                        id={1}
                        nextStep={nextStep}
                        isActive={currentStep == 1}
                        description='Meminta persetujuan ke konseli untuk mendiskusikan permasalahan mereka.'
                    />
                    <StepCard
                        title='2. Memilih Konselor'
                        id={2}
                        nextStep={nextStep}
                        isActive={currentStep == 2}
                        description='Memilih konselor untuk melakukan Case Conference'
                    />
                    <StepCard
                        title='3. Melakukan Case Conference'
                        id={3}
                        nextStep={nextStep}
                        isActive={currentStep == 3}
                        description='Melakukan Case Conference dengan konselor yang telah dipilih.'
                    />
                </Step>
                <StepContent
                    title='1. Persetujuan Case Conference'
                    id={1}
                    nextStep={nextStep}
                    isActive={currentStep == 1}
                    description='Sebelum melakukan Case Conference , mintalah persetujuan dari konseli.'
                >
                    {status == 'tidak' || status == 'declined' ?
                        <div style={{ marginTop: 24 }}>
                            <input type='checkbox' onChange={onAgreementChange} />
                            <label>Minta persetujuan Case Conference </label>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '40%', marginLeft: 'auto' }}>
                                    <Button title='Kirim' onClick={sendAgreement} disabled={!agreementCheck} />
                                </div>
                            </div>
                        </div>:
                        <div style={{ marginTop: 24 }}>
                            {/*<input type='checkbox' onChange={onAgreementChange} />*/}
                            <label>Menunggu persetujuan Case Conference </label>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '40%', marginLeft: 'auto' }}>
                                    {/*<Button title='Kirim' onClick={sendAgreement} disabled={!agreementCheck} />*/}
                                </div>
                            </div>
                        </div>
                    }

                </StepContent>
                <StepContent
                    title='2. Memilih Conselor'
                    id={2}
                    nextStep={nextStep}
                    isActive={currentStep == 2}
                    description='Pilih konselor (bisa lebih dari 1) untuk melakukan Case Conference'
                >
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <ConselorSelector onItemChecked={setKonselors}/>
                        </div>
                        <div style={{ width: '40%', marginLeft: 'auto' }}>
                            <Button title='Submit' onClick={() => nextStep()} />
                        </div>
                    </div>
                </StepContent>
                <StepContent
                    title='3. Melakukan Case Conference'
                    id={3}
                    nextStep={nextStep}
                    isActive={currentStep == 3}
                    description='Case Conference telah bisa dilakukan dengan konselor yang dipilih.'
                >
                    <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 24 }}>
                            Judul Case:
                            <input ref={judul} className='input-flat'></input>
                            <div style={{ fontSize: 14 }}>
                                *tidak boleh lebih dari 50 karakter
                            </div>
                        </div>
                        <div style={{ width: '40%', marginLeft: 'auto' }}>
                            <Button disabled={loading} title='Masuk ke conference' onClick={createNewConference} />
                        </div>
                    </div>
                </StepContent>
            </div>
        </div>
    )
}
const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps,mapDispatchToProps)(ConferenceSetup)