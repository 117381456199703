import React, {useState, useEffect} from 'react'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/actions";
import {getSettings, setExpired, setMaxSessions} from "../../methods/api/settings"
import './style.css'
import Button from "../../components/Buttons";
function AdminSetting(props){
    const [activeOption, setOption] = useState(1)
    const [settings, setSettings] = useState({expired: 0, session_limit: 0})
    const getData = async () => {
        const response = await getSettings()
        console.log(response)
        if(response.success){
            setSettings(response.data)
        }
    }
    useEffect(()=> {
        getData()
    },[])
    if(props.activeMenu != "Setting"){
        return <div/>
    }
    return(
        <div id={"content"}>
            <div style={{marginTop: 42, display: 'flex'}}>
                <div className={'option-wrapper'}>
                    <OptionCard 
                        icon={require('../../assets/images/admin/admin_icon_konselor_setting_expDate.svg')}
                        iconActive={require('../../assets/images/admin/admin_icon_konselor_setting_expDateFilled.svg')}
                        onClick={()=>setOption(1)} 
                        active={activeOption==1} 
                        title={'Expired Date'} 
                        description={'Pengaturan masa kadaluwarsa konseling'}/>
                    <OptionCard 
                        icon={require('../../assets/images/admin/admin_icon_konselor_setting_maksimumKonseli.svg')} 
                        iconActive={require('../../assets/images/admin/admin_icon_konselor_setting_maksimumKonseliFilled.svg')} 
                        onClick={()=>setOption(2)} 
                        active={activeOption==2} 
                        title={'Maksimum Konseli'} 
                        description={'Pengaturan jumlah maksimal konseli yang ditangani konselor per periode'}/>
                </div>
                {
                    activeOption == 1?
                        <div className={'option'}>
                            <h1>Expired Date</h1>
                            <p>Pengaturan masa kadaluwarsa konseling ketika konseli tidak merespon atau melanjutkan konsultasi</p>
                            <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                                <div>
                                    Batas konseling expired
                                    <div>
                                        <input type='number' value={settings.expired} onChange={(e)=>setSettings({...settings, expired: e.target.value})} type={'text'} style={{marginRight: 4}}/><label>hari</label>
                                    </div>
                                    <div style={{width: 128, height: 42}}>
                                        <Button title={'Simpan'} onClick={()=>setExpired(settings.expired)}/>
                                    </div>
                                </div>
                            </div>
                        </div>:
                        <div className={'option'}>
                            <h1>Maksimum Konseli</h1>
                            <p>Pengaturan batas maksimum konseli yang bisa ditangani oleh Konselor per periode waktu</p>
                            <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                                <div>
                                    Batas jumlah konseli
                                    <div>
                                        <input type='number' value={settings.session_limit} onChange={(e)=>setSettings({...settings, session_limit:e.target.value})} type={'text'} style={{marginRight: 4}}/><label>orang</label>
                                    </div>
                                    <div style={{width: 128, height: 42}}>
                                        <Button title={'Simpan'} onClick={()=>setMaxSessions(settings.session_limit)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>
    )
}

function OptionCard(props){
    return (
        <div className={props.active?'option-card':'option-card-disable'} onClick={props.onClick}>
    
            <div className='option-card-icon'>
                {
                    props.icon&&
                    <img src={!props.active?props.iconActive:props.icon}/>
                }
            </div>
            <div className='option-card-body'>
                <div className={'option-card-title'}>
                    {props.title}
                </div>
                <div className={'option-card-description'}>
                    {props.description}
                </div>    
            </div>
            
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })

export default connect(mapStateToProps, mapDispatchToProps)(AdminSetting)