import { headerTemplate, host } from "../config"


const createRangkuman = async (rangkuman, treatment, konseling_id) => {
    const request = await fetch(host+"/api/rangkuman", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            rangkuman: rangkuman, treatment, konseling_id
        })
    })
    const response = await request.json()
    return response
}

const cccc = () => {

}

export {
    createRangkuman,
    cccc
}