import React, {useState, useEffect, Fragment} from 'react'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/actions";
import './style.css'
import Button from "../../components/Buttons";
import { getKonselings } from '../../methods/api/konseling';
import { AiOutlineClose } from "react-icons/ai";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { getRekamKonselings } from '../../methods/api/rekamkonseling';
import Avatar from "../../components/Avatar/Avatar";
function ReportPresensi(props){
    const [activeOption, setOption] = useState(1)
    const [masterKonselings, setMasterKonselings] = useState([])
    const [konselings, setKonselings] = useState([])
    const [masterRekamKonselings, setMasterRekamKonselings] = useState([])
    const [selectedDetail, setSelectedDetail] = useState(null)
    const [rekamKonseling, setRekamKonseling] = useState([])
    const [progdis, setProgdis] = useState(['Progdi'])
    const [faculties, setFaculties] = useState(['Fakultas'])

    const [query, setQuery] = useState("")
    const [progdi, setProgdi]  = useState("")
    const [status, setStatus]  = useState("")
    const [fakultas, setFakultas] = useState("")
    const [keterangan, setKeterangan] = useState("")
    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")

    const [isKNotfound, setKNotFound] = useState(false)
    const [isRKNotfound, setRKNotFound] = useState(false)

    const onFilterChanged = async () => {
        setRKNotFound(false)
        setKNotFound(false)
        console.log("keterangan", keterangan)
        const q = query.toLowerCase() 
        let queryRekamKonselingtFilter = masterRekamKonselings.filter((item, index) => {
            return item.konseling.konselor.nama_konselor.toLowerCase().indexOf(q)>-1
        })
        let queryKonselingFilter = masterKonselings.filter((item, index) => {
            return item.konselor.nama_konselor.toLowerCase().indexOf(q)>-1
        })

        if(dateEnd!="" && dateStart!=""){
            const s = new Date()
            const e = new Date()
            s.setDate(dateStart.getDate()-1)
            e.setDate(dateEnd.getDate())

            queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                const konselingDate = new Date(item.tgl_konseling)
                return (konselingDate>=s && konselingDate<=e)
            })
            queryKonselingFilter = queryKonselingFilter.filter((item, index) => {
                const konselingDate = new Date(item.tgl_daftar_konseling)
                return (konselingDate>=s && konselingDate<=e)
            })
        }


        if(status!==""){
            if(status === "aktif"){
                queryKonselingFilter = queryKonselingFilter.filter((item, index) => {
                    return getStatsKonseling((item)) == "Aktif"
                })
                queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                    return getStatusRk(item).status == "Aktif"
                })
                if(keterangan === "referal"){
                    queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                        return getStatusRk(item).keterangan == "Referal"
                    })
                }else if(keterangan === "baru"){
                    queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                        return getStatusRk(item).keterangan == "Baru"
                    })
                }

            }else{
                queryKonselingFilter = queryKonselingFilter.filter((item, index) => {
                    return item.status_selesai !== "C"
                })
                queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                    return getStatusRk(item).status == "Selesai"
                })

                if(keterangan === "refered"){
                    queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                        return getStatusRk(item).keterangan == "Refered"
                    })
                }
                if(keterangan === "close case"){
                    queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                        return getStatusRk(item).keterangan == "Close Case"
                    })
                }
                if(keterangan === "expired"){
                    queryRekamKonselingtFilter = queryRekamKonselingtFilter.filter((item, index) => {
                        return getStatusRk(item).keterangan == "Expired"
                    })
                }
            }
        }
        if(fakultas!=="Fakultas"){
            queryKonselingFilter = queryKonselingFilter.filter((item, index) => {
                return item.konseli.fakultas == fakultas
            })
        }

        setKonselings(queryKonselingFilter)
        setRekamKonseling(queryRekamKonselingtFilter)

    }

    useEffect(()=> {
        if(props.activeMenu === "Report Presensi"){
            console.log("report presensi")
            getAllKonselings()  
            getAllRekamanKonselings()
        }   
    }, [props])
    useEffect(()=> {
        onFilterChanged()
    }, [query, status, fakultas, keterangan, dateStart, dateEnd])
    useEffect(()=> {
        setKeterangan("")
    },[status])
    useEffect(()=>{
        setQuery("")
        setStatus("")
        setFakultas("")
        setFakultas(faculties[0])
        setDateStart("")
        setDateEnd("")
        onFilterChanged()
    },[activeOption])
    if(props.activeMenu != "Report Presensi"){
        return <div/>
    }
    const getAllKonselings = async () => {
        console.log("get all konselings")
        const response = await getKonselings()
        if(response.success){
            setKonselings(response.rows)
            setMasterKonselings(response.rows)
            let f = response.rows.map((item, index) => {
                return(
                    item.konseli.fakultas
                )
            })

            f = f.filter((item, index) => {
                return f.indexOf(item) == index
            })
            console.log([...faculties, f])
            setFaculties([...faculties, ...f])
        }
    }

    const getAllRekamanKonselings = async () => {
        const response = await getRekamKonselings()
        if(response.success){
            setRekamKonseling(response.rows)
            setMasterRekamKonselings(response.rows)
        }
    }

    

    const getStatusRk = (rekamKonseling) =>{
        let status =  {keterangan: "-", status: "-"}
        if(rekamKonseling.konseling.status_selesai == "C"){
                status.status = "Aktif"
            if(rekamKonseling.konseling.status_konseling == "ref"){
                status.keterangan = "Referal"
            }else{
                status.keterangan = "Baru"
            }
            if(rekamKonseling.konseling.refered == "ya"){
                status.keterangan = "Refered"
                status.status = "Selesai"
            }
        }
        else{
            status.keterangan = "Close Case"
            status.status = "Selesai"
        }
        if(rekamKonseling.konseling.status_selesai == "expired"){
            status.status = "Selesai"
            status.keterangan = "Expired"
        }
        return status
    }

    const getStatsKonseling = (konseling) => {
        let status = "Aktif"
        if(konseling.status_selesai == "E" || konseling.status_selesai == "expired"){
            status = "Selesai"
        }else{
            status = "Aktif"
            if(konseling.refered == "ya"){
                status = "Selesai"
            }
        }
        return status
    }

    
    return(
        <div id={"content"}>
            <div style={{marginTop: 42, display: 'flex', width: '90%'}}>
                <div className={'option-wrapper'}>
                    <OptionCard 
                        iconActive={require('../../assets/images/admin/absensi_default.png')}
                        icon={require('../../assets/images/admin/absensi_filled.png')}
                        onClick={()=>setOption(1)} 
                        active={activeOption==1} 
                        title={'Presensi'} 
                        description={'Lorem ipsum'}/>
                    <OptionCard 
                        iconActive={require('../../assets/images/admin/detail_default.png')}
                        icon={require('../../assets/images/admin/detail_filled.png')}
                        onClick={()=>setOption(2)} 
                        active={activeOption==2} 
                        title={'Detail Konseling'} 
                        description={'Lorem ipsum'}/>
                </div>
                {
                    activeOption == 1?
                    
                    <div className={'option'}>
                            <div className={'option-head'}>
                                <div style={{display: 'flex'}}>
                                    <div className={'option-search'}>
                                        <input placeholder={"Nama Konselor"} value={query} onChange={(event)=>{
                                            setQuery(event.target.value)
                                        }} />
                                        <img src={require('../../assets/images/icon/search.png')}/>
                                    </div>
                                    <select
                                        value={status}
                                        style={{marginLeft: 18}}
                                        onChange={(event)=>{setStatus(event.target.value)}}>
                                        <option value="status">Status</option>
                                        <option value="selesai">Selesai</option>
                                        <option value="aktif">Aktif</option>
                                    </select>
                                    <select style={{marginLeft: 4}} disabled={status===""} value={keterangan} onChange={(e)=>setKeterangan(e.target.value)}>
                                        <option value="">Keterangan</option>
                                        {
                                            status=='selesai' ?
                                                <Fragment>
                                                    <option value="refered">Refered</option>
                                                    <option value="close case">Close Case</option>
                                                    <option value="expired">Expired</option>        
                                                </Fragment>:
                                                <Fragment>
                                                    <option value="baru">Baru</option>
                                                    <option value="referal">Referal</option>
                                                </Fragment>
                                            }
                                        
                                    </select>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', height: 24, paddingTop: 24}}>
                                    <div className='date-picker'>
                                        <DatePicker
                                            selected={dateStart}
                                            onChange={date=>setDateStart(date)}
                                            className="customDatePickerWidth"
                                            placeholderText={"Dari"} />
                                        <img src={require('../../assets/images/icon/kalender.png')}/>
                                    </div>
                                    <div className='date-picker'>
                                        <DatePicker className="customDatePickerWidth"
                                            selected={dateEnd}
                                            onChange={date=>setDateEnd(date)}
                                            style={{marginLeft: 4, width:100}}
                                            placeholderText={"Sampai"}/>
                                        <img src={require('../../assets/images/icon/kalender.png')}/>
                                    </div>
                                    
                                </div>
                                    
                                <div >
                                    {/* <Button 
                                        onClick={()=>{}}
                                        title={'Tambah Pengumuman'}
                                        fontSize={12}
                                        width={148}
                                        height={32}
                                        borderRadius={8}
                                        /> */}
                                </div>
                            </div>
                            <div className='option-body'>
                                <table cellSpacing={0} cellPadding={0}>
                                    <tr>
                                        <td style={{width: 4}}>No</td>
                                        <td style={{padding: 0, margin: 0}}>Tanggal</td>
                                        <td style={{paddingRight: 0, width: 50}}> </td>
                                        <td style={{paddingLeft: 4, width:120}}>Nama Konselor</td>
                                        <td style={{margin: 0, padding: 0, width: 50}}>Status</td>
                                        <td style={{marginRight: 0, paddingRight: 0, width: 80}}>Keterangan</td>
                                        <td>Topik</td>
                                    </tr>
                                    
                                    <Fragment >
                                        {rekamKonseling.length > 0 && rekamKonseling.map((item, index) => {
                                            return(
                                                <Fragment>
                                                    <tr className='option-item'>
                                                        <td style={{width: 4}}>{index+1}</td>
                                                        <td style={{padding: 0, margin: 0}}>{new Date(item.created_at).toLocaleDateString()}</td>
                                                        <td style={{paddingRight: 0, width: 50}}><Avatar img={item.konseling.konselor.user.avatar}/></td>                                                        
                                                        <td style={{paddingLeft: 4, width:120}}>{item.konseling?.konselor?.nama_konselor}</td>
                                                        <td style={{margin: 0, padding: 0, width: 50}}>{getStatusRk(item).status}</td>
                                                        <td style={{marginRight: 0, paddingRight: 0, width:80}}>{getStatusRk(item).keterangan}</td>
                                                        <td style={{width:175}}>{item.judul_konseling}</td>
                                                    </tr>
                                                    <div className='option-item-separator'/>
                                                </Fragment>
                                            )
                                        })}
                                    </Fragment>
                                </table>
                            </div>
                        </div>
                        :<div className={'option'}>
                            <div className={'option-head'}>
                                <div style={{display: 'flex'}}>
                                    <div className={'option-search'}>
                                        <input placeholder={"Nama Konselor"} value={query} onChange={(event)=>{
                                            setQuery(event.target.value)
                                        }} />
                                        <img src={require('../../assets/images/icon/search.png')}/>
                                    </div>
                                    
                                    <select value={fakultas} style={{marginLeft: 18}} onChange={(event)=>setFakultas(event.target.value)}>
                                        {faculties.map((item, index) => {
                                            return(
                                                <option value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                    <select
                                        value={status}
                                        style={{marginLeft: 18}}
                                        onChange={(event)=>{setStatus(event.target.value)}}>
                                        <option value="">Status</option>
                                        <option value="selesai">Selesai</option>
                                        <option value="aktif">Aktif</option>
                                    </select>
                                    
                                </div>
                                
                                <div style={{display: 'flex', alignItems: 'center', height: 24, paddingTop: 24}}>
                                    <div className='date-picker'>
                                        <DatePicker
                                            selected={dateStart}
                                            onChange={date=>setDateStart(date)}
                                            className="customDatePickerWidth"
                                            placeholderText={"Dari"} />
                                        <img src={require('../../assets/images/icon/kalender.png')}/>
                                    </div>
                                    <div className='date-picker'>
                                        <DatePicker className="customDatePickerWidth"
                                            selected={dateEnd}
                                            onChange={date=>setDateEnd(date)}
                                            style={{marginLeft: 4, width:100}}
                                            placeholderText={"Sampai"}/>
                                        <img src={require('../../assets/images/icon/kalender.png')}/>
                                    </div>
                                    
                                </div>
                                <div >
                                    {/* <Button 
                                        onClick={()=>{}}
                                        title={'Tambah Pengumuman'}
                                        fontSize={12}
                                        width={148}
                                        height={32}
                                        borderRadius={8}
                                        /> */}
                                </div>
                            </div>
                            <div className='option-body'>
                                <table cellSpacing={0} cellPadding={0}>
                                    <tr>
                                        <td>Nama Konselor</td>
                                        <td>Id Konseling</td>
                                        <td>Fakultas</td>
                                        <td>Program Studi</td>
                                        <td>Suku</td>
                                        <td>Agama</td>
                                        <td>Status</td>
                                        <td>Jumlah Sesi</td>
                                    </tr>
                                    
                                    <Fragment >
                                        {konselings.map((item, index) => {
                                            return(
                                                <Fragment>
                                                    <tr className='option-item'>
                                                        <td>{item.konselor.nama_konselor}</td>
                                                        <td>{item.id}</td>
                                                        <td>{item.konseli.fakultas}</td>
                                                        <td>{item.konseli.progdi}</td>
                                                        <td>{item.konseli.suku}</td>
                                                        <td>{item.konseli.agama}</td>
                                                        <td>{getStatsKonseling(item)}</td>
                                                        <td onClick={()=>setSelectedDetail(index)} style={{color: '#F1C40F', cursor: 'pointer'}}>{item.rekam_konselings.length}</td>
                                                    </tr>
                                                    <div className='option-item-separator'/>
                                                </Fragment>
                                                
                                            )
                                        })}
                                    </Fragment>
                                </table>
                            </div>
                        </div>
                }
            </div>
            {
                selectedDetail !== null
                &&
                <div className='histori-sesi-popup'>
                <div className='histori-sesi-popup-backdrop'></div>
                <div className='histori-sesi-popup-card'>
                    <div className='table'>
                        <div className='table-row table-header'>
                            <div className='table-data' style={{flex: 0.1}}>No</div>
                            <div className='table-data' style={{flex: 0.2}}>Tanggal</div>
                            <div className='table-data' style={{flex: 0.4}}>Jam berakhir sesi</div>
                            <div className='table-data'>Topik</div>
                            <div className='table-data'>Rekam Konseling</div>
                        </div>
                            {konselings.length > 0 && konselings[selectedDetail].rekam_konselings.map((item,index) => {
                                return(
                                    <Fragment>
                                        <div className='table-row table-body'>
                                            <div className='table-data' style={{flex: 0.1}}>{index+1}</div>
                                            <div className='table-data' style={{flex: 0.2}}>{new Date(item.created_at).toLocaleDateString()}</div>
                                            <div className='table-data' style={{flex: 0.4}}>{konselings[selectedDetail].jadwal.jam_akhir.toString()+":00"}</div>
                                            <div className='table-data'>{item.judul_konseling}</div>
                                            <div className='table-data'>{item.isi_rekam_konseling}</div>
                                        </div>
                                        {index < konselings[selectedDetail].rekam_konselings.length-1 && <hr/>}
                                    </Fragment>

                                )
                            })}
                            
                    </div>
                </div>
                <AiOutlineClose onClick={()=>setSelectedDetail(null)} style={{position: 'absolute', top: 24, right: 24, cursor: 'pointer'}}/>
            </div>
                }
            
        </div>
    )
}

function OptionCard(props){
    return (
        <div className={props.active?'option-card':'option-card-disable'} onClick={props.onClick}>
    
            <div className='option-card-icon'>
                {
                    props.icon&&
                    <img src={!props.active?props.iconActive:props.icon}/>
                }
            </div>
            <div className='option-card-body'>
                <div className={'option-card-title'}>
                    {props.title}
                </div>
                <div className={'option-card-description'}>
                    {props.description}
                </div>    
            </div>
            
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })

export default connect(mapStateToProps, mapDispatchToProps)(ReportPresensi)