import React, { useState, useRef, useEffect } from 'react'
import './popup.css'
import Button from '../../Buttons'
import {register, login, loginSiasat} from '../../../methods/api/auth/register'
import { MdClose } from 'react-icons/md'
import StackNotification from "../../StackNotification";
import { TextField } from '@material-ui/core'
import { useSnackbar } from 'notistack'

export default function Popup(props) {
    const [isLoginScreen, setLoginScreen] = useState(props.register == true)
    const [isProfileScreen, setIsProfile] = useState(false)

    const [isConfirm, setIsConfirm] = useState(false)
    const [isRegistered, setRegister] = useState(false)

    const [registerSuccess, setRegisterStatus] = useState(false)
    const [email, setEmail] = useState('')

    const [loading, setLoading] = useState(false)

    const [mahasiswa, setMahasiswa] = useState(null)


    const [error, setError] = useState([])
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    useEffect(()=>{
        if(error.length>0){
            error.map((item, index) => {
                console.log(item)
                enqueueSnackbar(item, {variant: 'warning'})
            })
        }
    }, [error])
    const onSubmit = async (data) => {
        try{
            setLoading(true)
            console.log(isLoginScreen)
            if (isLoginScreen) {
                console.log(data)
                const key = enqueueSnackbar("Sedang memproses data", {
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                    },
                    persist: true
                })
                if(data.role == 'konselor'){
                    const response = await login(data)
                    console.log(response)
                    if (response.success) {
                    } else {
                        setLoading(false)
                    }
                    if (response.error){
                        setError(['Username atau password salah!'])
                    }
                }else if(data.role == 'konseli'){
                    const response = await loginSiasat(data)
                    console.log(response)
                    if(response.success == true && response.action == 'register'){
                        setMahasiswa(response.data)
                        setLoginScreen(!isLoginScreen)
                    }
                    if(response.error){
                        // setError([response.message])
                        console.log("show err")
                        setError([response.message])
                    }
                    setLoading(false)
                }
                closeSnackbar(key)
            } else {
                console.log(data)
                setRegister(true)
                setLoading(false)
                setEmail(data.email)
            }
        }catch(error){
            setError(['Terjadi Kesalahan, silahkan coba lagi'])
        }

    }
    if (isRegistered)
        return (
            <div className='popup-container'>
                <div className='popup-backdrop' onClick={() => {
                }}>

                </div>

                <Profile
                    {...props}
                    email={email}
                    mahasiswa={mahasiswa}
                    onSucces={() => {
                        setLoginScreen(true)
                        setIsProfile(false)
                        setIsConfirm(false)
                        setRegister(false)
                        setRegisterStatus(true)
                    }}
                    setError={setError}
                />
                {/* {error.length>0 && <StackNotification data={error}/>} */}
                <div onClick={props.onDismiss} style={{
                    position: 'absolute',
                    top: 12,
                    right: 32,
                    zIndex: 9,
                    cursor: 'pointer'
                }}>
                    <MdClose size={32} />
                </div>

            </div>
        )
    return (
        <div className='popup-container'>
            <div className='popup-backdrop' onClick={() => {
            }}>
            </div>
            {
                isLoginScreen ?
                    <Login {...props} loading={loading} registerSuccess={registerSuccess} onLabelClick={() => setLoginScreen(!isLoginScreen)} onSubmit={onSubmit} />
                    : <Register onLabelClick={() => setLoginScreen(!isLoginScreen)} onSubmit={onSubmit} email={email} {...props} mahasiswa={mahasiswa}/>
            }
            {/* {error.length>0 && <StackNotification data={error}/>} */}
            <div onClick={props.onDismiss} style={{
                position: 'absolute',
                top: 12,
                right: 32,
                zIndex: 9,
                cursor: 'pointer'
            }}>
                <MdClose size={32} />
            </div>
            {/* {error.length>0 && <StackNotification data={error}/>} */}

        </div>
    )
}

function Login(props) {

    const onSubmit = (event) => {
        event.preventDefault()
        
        const data = new Object()
        data.email = username.current.value
        data.password = password.current.value
        data.role = isRoleKonseli ? 'konseli' : 'konselor'
        if(!props.loading){
            props.onSubmit(data)
        }
    }

    const [isRoleKonseli, setRoleKonseli] = useState(true)

    const username = useRef()
    const password = useRef()
    const toggleRef = useRef()
    const [option, setOption] = useState('konseli')

    const handleToggle = (s) => {
        setRoleKonseli(s)
        const wraper = toggleRef.current
        wraper.classList.toggle('is-konselor-selected')
        username.current.value = ''
        password.current.value = ''
    }

    return (
        <form className='popup-content' onSubmit={onSubmit}>

            {
                props.registerSuccess ?
                    <p className='popup-title'>Informasi Anda telah tersimpan</p>
                    :
                    <div>
                        <p className='popup-title'>Login Sebagai: </p>
                        <div className='role-selector'>
                            <div className='toggle' ref={toggleRef}>
                                {isRoleKonseli ? 'Konseli' : 'Konselor'}
                            </div>
                            <div className={isRoleKonseli ? 'active-role' : 'role'} onClick={() => handleToggle(true)}>
                                Konseli
                                </div>
                            <div className={!isRoleKonseli ? 'active-role' : 'role'} onClick={() => handleToggle(false)}>
                                Konselor
                            </div>
                        </div>
                    </div>
            }

            <div className='popup-forms form-login'>
                <input name="Username" ref={username} placeholder={isRoleKonseli?'NIM':'Email'}></input>
                <input name="Password" ref={password} placeholder='Password' type='password'></input>
            </div>
            <div className='popup-forms'>
                <div className='button-submit'>
                    <Button type='Submit' disable={props.loading} title='Login' height={38} background={props.loading?'gray':'#769FCD'} onClick={() => { }} />
                </div>
            </div>
        </form>
    )
}
function Register(props) {
    const [isEmailValid, setEmailValid] = useState(false)
    const onSubmit = () => {
        const data = new Object()
        data.email = email.current.value
        props.onSubmit(data)
    }

    useEffect(() => {
        onSubmit()
    }, [])

    const validateEmail = () => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.current.value)) {
            setEmailValid(true)
            return true
        }
        setEmailValid(false)
        return false
    }

    const email = useRef()

    return (
        <div className='popup-content' style={{display: 'hidden'}}>
            <p className='popup-title'>Register</p>
            <div className='popup-forms'>
                <input disabled={true} value={props.mahasiswa.email} autoComplete={false} ref={email} placeholder='Email' onChange={validateEmail}></input>
            </div>
            <div className='popup-forms'>
                <div className='button-submit'>
                    <Button disabled={isEmailValid === false} title='Register' height={38} background='#769FCD' onClick={onSubmit} />
                </div>
            </div>
            <div className='ask-account'>
                <p>Sudah mempunyai akun?</p>
                <label onClick={() => props.onLabelClick()}>Login</label>
            </div>
        </div>
    )
}

function Profile(props) {
    const [data, setData] = useState({})
    const [facultyData, setFaculty] = useState(faculties[0])
    const [prodi, setProdi] = useState(faculties[0].prodis[0].id)
    const [base64Image, setBase64Image] = useState(null)

    const [isRegistering, setRegistering] = useState(false)

    const [pickingImage, setPickingImage] = useState(require('../../../assets/images/default-avatar.png'))
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const m = props.mahasiswa
    const [snackKey, setSnackKey] = useState(null)

    useEffect(()=>{
        if(isRegistering){
            const key = enqueueSnackbar("Sedang memproses data", {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            })
            setSnackKey(key)
        }else{
            if(snackKey)
            closeSnackbar(snackKey)
        }
    }, [isRegistering])

    const onChange = (d) => {
        data.name = m.nama
        data.email = m.email
        data.nim = m.nim
        data.nama_konseli = m.nama
        data.progdi = m.progdi
        data.fakultas = m.fakultas
        data.no_hp_konseli = m.nohp
        setData(Object.assign(data, d))
        console.log(data)
    }

    const onListChange = (d) => {
    }

    const onFacultyChange = (event) => {
        setFaculty(event.target.value)
        setProdi(faculties[event.target.value][0])
    }

    const onProdiChange = (event) => {
        setProdi(event.target.value)
    }

    const onSubmit = async () => {
        if(isRegistering){
            return 
        }
        props.setError([])
        closeSnackbar()
        try{
            setRegistering(true)
            data.avatar = base64Image
            if (data.jenis_kelamin == null) data.jenis_kelamin = null
            if (data.agama == null) data.agama = null
            const response = await register(Object.assign(data, { prodi_id: prodi, email: props.email }))
            console.log(response)
            if (response.success == true) {
                props.onSucces()
            }else if(response.success == false){
                const keys = Object.keys(response.message)
                const values = keys.map((item, index) => {
                    
                    return response.message[item]
                })
                props.setError(values)

            }
            setRegistering(false)
        }catch(error){
            props.setError(['Terjadi kesalahan, silahkan coba lagi'])
            setRegistering(false)
        }

    }
    const emailRef = useRef()
    useEffect(() => {
        // emailRef.current.value = props.email
    }, [])

    const getBase64 = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            setBase64Image(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleImagePick = () => {
        document.getElementById('image-button').click();
        document.getElementById('image-button').onchange = (event) => {
            setPickingImage(URL.createObjectURL(event.target.files[0]))
            getBase64(event.target.files[0])
        }
    }

    return (
        <div className='popup-content' style={{
            width: '80%',
            height: '92%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                width: 300,
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <img src={pickingImage} className='data-form-avatar'>

                </img>
                <div type='file' style={{
                    width: 140,
                    height: 24,
                    padding: 0,
                    border: 'none'
                }}>
                    <input id='image-button' type='file' hidden></input>
                    <Button title='Unggah Foto' onClick={handleImagePick} width={140} height={24} fontSize={12} />
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 24,
                width: '78%',
                textAlign: 'left',
            }}>
                
                <form className='data-form'>
                    <div className='data-form-title'>
                        {/* Data */}
                    </div>
                    <div className='data-form-inputs'>
                    <div className='data-form-input'>
                            <div>
                                Nama
                            </div>
                            <input disabled={true} value={m.nama} onChange={(event) => onChange({ name: event.target.value, nama_konseli: event.target.value })}></input>
                        </div>
                        <div className='data-form-input'>
                            <div>
                                No. Hp
                            </div>
                            <input disabled={true} value={m.nohp} onChange={(event) => onChange({ no_hp_konseli: event.target.value })}></input>
                        </div>
                        <div className='data-form-input'>
                            <div>
                                NIM
                            </div>
                            <input disabled={true} value={m.nim} onChange={(event) => onChange({ nim: event.target.value })}></input>
                        </div>
                        <div className='data-form-input'>
                            <div>
                                Fakultas
                            </div>
                            <input disabled={true} value={m.fakultas} onChange={(event) => onChange({ nim: event.target.value })}></input>
                        </div>
                        <div className='data-form-input'>
                            <div>
                                Prodi
                            </div>
                            <input disabled={true} value={m.progdi} onChange={(event) => onChange({ nim: event.target.value })}></input>
                        </div>
                    </div>
                </form>
                <div className='data-form' style={{ border: 'none' }}>
                    <div className='data-form-title'>
                        {/* Informasi Personal */}
                    </div>
                    <form className='data-form-inputs'>
                        
                        <div className='data-form-input'>
                            <div>
                                Jenis Kelamin
                            </div>
                            <select onChange={(event) => onChange({ jenis_kelamin: event.target.value })}>
                                <option value={null}>Pilih Jenis Kelamin</option>
                                <option value="Laki-laki">Laki-laki</option>
                                <option value="Perempuan">Perempuan</option>
                            </select>
                        </div>
                        <div className='data-form-input'>
                            <div>
                                Agama
                            </div>
                            <select onChange={(event) => onChange({ agama: event.target.value })}>
                                <option value={null}>Pilih Agama</option>
                                <option value="Islam">Islam</option>
                                <option value="Kristen">Kristen</option>
                                <option value="Katolik">Katolik</option>
                                <option value="Hindu">Hindu</option>
                                <option value="Buddha">Buddha</option>
                                <option value="Kong Hu Cu">Kong Hu Cu</option>
                            </select>
                        </div>

                        <div className='data-form-input'>
                            <div>
                                Tanggal Lahir
                            </div>
                            {/* <input onChange={(event) => onChange({ suku: event.target.value })}></input> */}
                            <TextField 
                                id='date'
                                type='date'
                                defaultValue="yyyy/mm/dd"
                                className={'material-input'}
                                InputLabelProps={{shrink: true}}
                                onChange={(e)=>{onChange({tgl_lahir_konseli: e.target.value})}}
                            />
                        </div>

                        <div className='data-form-input'>
                            <div>
                                Suku
                            </div>
                            <input onChange={(event) => onChange({ suku: event.target.value })}></input>
                        </div>
                        <div className='data-form-input' style={{
                        }}>
                            <div>
                                Alamat asal
                            </div>
                            <textarea onChange={(event) => onChange({ alamat_konseli: event.target.value })}></textarea>
                        </div>
                    </form>
                </div>
                {/* <div className='data-form'>
                    <div className='data-form-title'>
                    </div>
                    <div className='data-form-inputs'>
                        <div className='data-form-input'>
                            <div>
                                Email
                            </div>
                            <input disabled={true} value={m.email} name="Username" disabled={true} ref={emailRef} onChange={(event) => onChange({ email: event.target.value })}></input>
                        </div>
                        <div className='data-form-input'>
                        </div>
                    </div>
                </div> */}
            
            </div>
            <div style={{ width: 120, display:'flex', alignItems: 'center', justifyContent:'center' }}>
                <Button width={120} title='Simpan' onClick={() => onSubmit()} disabled={isRegistering} />
            </div>
        </div>
    )
}

function Confirm(props) {
    return (
        <div className='popup-content' style={{
            paddingLeft: 48,
            paddingRight: 48
        }}>
            <div style={{
                fontWeight: 600,
                fontSize: 24,
                textAlign: "left"
            }}>
                <div>
                    Link verifikasi telah dikirimkan ke email Anda, silahkan Anda periksa dan ikuti petunjuknya.
                </div>
                <div style={{
                    fontSize: 8,
                    color: 'blue',
                    textAlign: 'right',
                    cursor: 'pointer'
                }}
                    onClick={() => props.showDataUser()}
                >
                    Lengkapi data
                </div>
            </div>
        </div>
    )
}



const faculties = [
    {
        "id": 1,
        "nama_fakultas": "FTI",
        "created_at": null,
        "updated_at": null,
        "prodis": [
            {
                "id": 1,
                "nama_prodi": "Teknik Informatika",
                "faculty_id": 1,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": 2,
                "nama_prodi": "Sistem Informasi",
                "faculty_id": 2,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": 3,
                "nama_prodi": "Desain Komunikasi Visual",
                "faculty_id": 3,
                "created_at": null,
                "updated_at": null
            }
        ]
    },
    // {
    //     "id": 2,
    //     "nama_fakultas": "FTEK",
    //     "created_at": null,
    //     "updated_at": null,
    //     "prodis": [

    //     ]
    // },
    // {
    //     "id": 3,
    //     "nama_fakultas": "FPB",
    //     "created_at": null,
    //     "updated_at": null,
    //     "prodis": []
    // }
]