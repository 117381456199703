const getUserFromToken = (redirect=true) => {
    try{
        const user = JSON.parse(atob(window.localStorage.getItem('token').split('.')[1])).user
        return user
    }catch(e){
        return null

        if(redirect){
            // window.location.href="/"
        }else{
        }
    }
}

export {
    getUserFromToken
}