import { headerTemplate, host } from "../config"


export const getRekamKonselings = async (data) => {
    const request = await fetch(host + "/api/rekamkonseling?" + data, {
        "method": "GET",
        "headers": headerTemplate(),
    })

    const response = await request.json()
    return response
}

