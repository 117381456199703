import { headerTemplate, host } from "../config"


export const createQuote = async (quote, oleh) => {
    const request = await fetch(host+"/api/quote", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            quote, oleh
        })
    })
    const response = await request.json()
    return response
}
export const updateQuote = async (id, quote, oleh) => {
    const request = await fetch(host+"/api/quote/"+id, {
        "method": "PUT",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            quote, oleh
        })
    })
    const response = await request.json()
    return response
}
export const getQuote = async (id="") => {
    console.log(host+(id==""?+"/api/quote":("/api/quote/"+id)))
    let endpoint = host+"/api/quote/"
    if(id!==""){
        endpoint+=id
    }
    const request = await fetch(endpoint, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}
export const destroyQuote = async (id) => {
    const request = await fetch(host+"/api/quote/"+id, {
        "method": "DELETE",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}