import React, {useEffect, useRef} from 'react'
import './selector.css'
import { useState } from 'react'
import {getKonselors} from "../../methods/api/auth/konselor";
import {forEach} from "react-bootstrap/cjs/ElementChildren";
import { getUserFromToken } from '../../methods/api/auth/auth';

export default function ConselorSelector(props) {
    const [isAll, setAll] = useState(false)
    const myid = getUserFromToken().info.id
    const [conselors, setConselors] = useState([])
    const [enabledCount, setEnabledCount] = useState(0)
    const onAllChange = (e) => {
        let newConselors = conselors.map((conselor, index) => {
            // onCheckChange(conselor)
            let conselorTemp = { ...conselor, checked: e.target.checked }
            if(conselor.disabled){
                conselorTemp.checked = false
            }
            return (conselorTemp)
        })
        let checkedKonselor = []
        for(let i=0; i<newConselors.length; i++){
            console.log(newConselors[i])
            if(newConselors[i].checked == true){
                checkedKonselor[checkedKonselor.length] = newConselors[i].id
            }
        }
        setConselors(newConselors)
        props.onItemChecked(checkedKonselor)
    }
    const onCheckChange = (changedConselor) => {
        allChecbox.current.checked = false
        console.log('check')
        let newConselors = conselors.map((conselor, index) => {
            if (conselor == changedConselor) {
                console.log('ketemu')
                return { ...conselor, checked: !conselor.checked }
            }
            return conselor
        })
        setConselors(newConselors)

        let checkedKonselor = []
        for(let i=0; i<newConselors.length; i++){
            console.log(newConselors[i])
            if(newConselors[i].checked == true){
                checkedKonselor[checkedKonselor.length] = newConselors[i].id
            }
        }
        console.log(checkedKonselor)
        console.log(newConselors)
        props.onItemChecked(checkedKonselor)
    }
    const getKonselorsData = async () => {
        const response = await getKonselors("?all")
        let enabled = 0;
        if(response.success){
            let kons = response.rows.filter((item, index) =>(item.id != myid))
            console.log(props.exclude)
            if(props.exclude.length>0){
                console.log('exluded: ', props.exclude)
                // kons = kons.filter((item, index) =>(props.exclude.indexOf(item.id) === -1))
                kons = kons.map((item, index) => {
                    if(props.exclude.indexOf(item.id) == -1){
                        enabled += 1;
                    }
                    return(
                        {...item, disabled: props.exclude.indexOf(item.id) > -1}
                    )
                })
            }
            setConselors(kons)
        }
        setEnabledCount(enabled)
        if(enabled == 0){
            if(props.onAllDisabled){
                props.onAllDisabled()
            }
            console.log('on all disabled!')
        }else{
            console.log('on not all disabled!')
        }
    }

    useEffect(()=>{
        getKonselorsData()
    }, [])

    const allChecbox = useRef()
    return (
        <div style={{ marginTop: 32, marginBottom: 32 }}>
            <input disabled={enabledCount == 0} ref={allChecbox} type='checkbox' onChange={onAllChange}></input>
            <label className={enabledCount == 0?"conselor-check-disabled":"conselor-check"}>Pilih semua</label>
            <div className='selector-checkboxs'>
                <table>
                    {conselors.map((conselor, index) => {
                        return (
                            <tr className={conselor.disabled?"conselor-check-disabled":"conselor-check"}>
                                <td>
                                    <input disabled={conselor.disabled} type='checkbox' checked={conselor.checked} onChange={() => onCheckChange(conselor)}></input>
                                </td>
                                <td>{conselor.nama_konselor}</td>
                                <td>{conselor.profesi_konselor}</td>
                                {conselor.disabled&&<td>Sudah menjadi anggota</td>}
                            </tr>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}

ConselorSelector.defaultProps = {
    onItemChecked : () => {},
    exclude: []
}

const datas = [
    {
        id: 1,
        name: 'Tanya Alexander',
        quote: 'Dosen S1 Psikologi',
        checked: false
    },
    {
        id: 2,
        name: 'Ted Mckinney',
        quote: 'Dosen S1 Psikologi',
        checked: false
    }
]