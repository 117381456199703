import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Button from '../../../components/Buttons'
import { getUserFromToken } from '../../../methods/api/auth/auth'
import { changePassword } from '../../../methods/api/auth/register'
import { mapDispatchToProps } from '../../../store/actions'
import './gantipassword.css'

const GantiPassword = (props) => {
    const[loading, setLoading] = useState(false)
    const[lama, setLama] = useState("")
    const[baru, setBaru] = useState("")
    const[ulang, setUlang] = useState("")
    const[key, setKey] = useState(null)
    const[error, setError] = useState([])
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    useEffect(()=> {
        if(error.length>0){
            console.log('show errors')
            console.log(error)
            error.map((item, index) => {
                enqueueSnackbar(item, {
                    variant: 'warning'
                })
            })
        }
    }, [error])
    
    const submit = async () => {
        closeSnackbar()
        setLoading(true)
        const k = enqueueSnackbar("Sedang memproses data", {variant: 'info', anchorOrigin: {horizontal: 'center', vertical: 'bottom'}})
        const response = await changePassword(lama, baru, ulang, getUserFromToken().role)
        closeSnackbar(k)
        if(response.success){
            if(getUserFromToken().role == 'admin'){
                window.location.href = '/admin'
                return
            }
            window.location.href = '/dashboard'
        }else{
            const keys = Object.keys(response.message)
            let errors = keys.map((item, index) => {
                console.log(item)
                return response.message[item]
            })
            console.log(errors)
            setError(errors)
        }
        setLoading(false)
    }

    if(props.activeMenu != "Ganti Password"){
        return(
            <div></div>
        )
    }
    return (
        <div className='content'>
            <div className='ganti-password-container'>
                <div className='ganti-password-card'>
                    <input type='password' value={lama} onChange={(e)=>setLama(e.target.value)} placeholder='Password lama'></input>
                    <input type='password' value={baru} onChange={(e)=>setBaru(e.target.value)} placeholder='Password baru'></input>
                    <input type='password' value={ulang} onChange={(e)=>setUlang(e.target.value)} placeholder='Ulang password baru'></input>
                    <div style={{width: 100}}>
                        <Button width={100} disabled={loading} title="Simpan" onClick={submit}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(GantiPassword)