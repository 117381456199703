import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/actions";
import List from "../../components/List";
import Button from "../../components/Buttons";
import MainProfileCard from "../../components/Conselor/MainProfileCard";

function KelolaKonselor(props) {
    const [selectedKonselor, setKonselor] = useState(null)

    useEffect(()=>{
        console.log("konselor change")
        props.onKonselorSelected(selectedKonselor)
    }, [selectedKonselor])

    if(props.activeMenu != 'Kelola Konselor'){
        return(
            <div/>
        )
    }else{
        // setKonselor(null)
    }

    return(
        <div id={"content"} style={{}}>
            <List title={'conselor'} allKonselor onListSelected={setKonselor}/>
            <div style={{marginLeft: 24, marginTop: 24}}>
                <div style={{width: 140}}>
                    <Button title={'Tambah Konselor'} onClick={()=>{
                        setKonselor(null)
                        props.onKonselorSelected(null)
                        props.newKonselor()
                        props.setActiveMenu("edit-konselor")}
                    }/>
                </div>
                {
                    selectedKonselor &&
                    <div style={{marginTop: 24}}>
                        <MainProfileCard konselor={selectedKonselor}/>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(KelolaKonselor)
