export function setActiveConseli(conseli) {
    return {
        type: 'SET_ACTIVE_CONSELI',
        conseli: conseli
    }
}

export function setActiveMenu(menu) {
    return {
        type: 'SET_ACTIVE_MENU',
        menu
    }
}

export function setChat(status) {
    return {
        type: 'SET_IS_CHAT',
        status: status
    }
}

export function setCaseConference(status) {
    return {
        type: 'OPEN_CASE_CONFERENCE',
        status: status
    }
}

export function setReferral(status) {
    return {
        type: 'OPEN_REFERRAL',
        status
    }
}

export function setSession(session) {
    return {
        type: 'SET_SESSION',
        conselingSession: session
    }
}


export function setArrow(state) {
    return {
        type: 'SET_ARROW',
        state: state
    }
}

export function setSessions(sessions) {
    return {
        type: 'SET_SESSIONS',
        sessions: sessions
    }
}
export function setJadwal(rows) {
    return {
        type: 'SET_JADWAL',
        jadwals: rows
    }
}
export function setKonselingAktif(count) {
    return {
        type: 'SET_KONSELING_AKTIF',
        konselingAktif: count
    }
}
export function setKonselingSelesai(count) {
    return {
        type: 'SET_KONSELING_SELESAI',
        konselingSelesai: count
    }
}

export function setJadwalOnline(jadwal) {
    return {
        type: 'SET_JADWAL_ONLINE',
        jadwal: jadwal
    }
}

export function setKonselingList(konselingList) {
    return {
        type: 'SET_KONSELING_LIST',
        konselingList
    }
}

export function setConference(conference){
    return{
        type: 'SET_CONFERENCE',
        conference: conference
    }
}





export const mapDispatchToProps = dispatch => ({
    setSessions: sessions => {
        dispatch(setSessions(sessions))
    },
    setKonselingList: konselingList => {
        dispatch(setKonselingList(konselingList))
    },
    setJadwalOnline: jadwal => {
        dispatch(setJadwalOnline(jadwal))
    },
    setJadwal: rows => {
        dispatch(setJadwal(rows))
    },
    setKonselingAktif: count => {
        dispatch(setKonselingAktif(count))
    },
    setKonselingSelesai: count => {
        dispatch(setKonselingSelesai(count))
    },
    setActiveConseli: conseli => {
        dispatch(setActiveConseli(conseli))
    },
    setActiveMenu: menu => {
        dispatch(setActiveMenu(menu))
    },
    setChat: state => {
        dispatch(setChat(state))
    },
    openCaseConference: () => {
        dispatch(setCaseConference(true))
    },
    closeCaseConference: () => {
        dispatch(setCaseConference(false))
    },
    setArrow: (state) => {
        dispatch(setArrow(state))
    },
    openReferral: () => {
        dispatch(setReferral(true))
    },
    closeReferral: () => {
        dispatch(setReferral(false))
    },
    setConference: conference => {
        dispatch(setConference(conference))
    }
})