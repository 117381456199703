import React, { useState, useEffect, useReducer } from 'react'
import AppBar from '../../components/AppBar/AppBar'
import './Dashboard.css'
import Navigation from '../../components/Navigation'
import { connect } from 'react-redux'
import PageDaftarKonseli from './PageDaftarKonseli'
import {mapDispatchToProps, setActiveMenu} from '../../store/actions'
import PageHome from './PageHome'
import PageCaseConference from './PageCaseConference'
import ConseliHome from './Conseli/ConseliHome'
import SessionEnrollment from './Conseli/SessionEnrollment'
import Chat from '../../components/Chat'
import RuangKonseling from './Conseli/RuangKonseling'
import { getCountKonseling, getJadwalKonselingAktif, getKonselings, konselingCheck } from '../../methods/api/konseling'
import { getJadwalOnline } from '../../methods/api/jadwal'
import { resolveProdi, resolveFaculty } from '../../methods/api/prodi'
import PageArsip from "./PageArsip";
import KonseliArsip from "./Conseli/KonseliArsip";
import EditKonselor from './Conselor/EditKonselor'
import { FaCcVisa } from 'react-icons/fa'
import { getUserFromToken } from '../../methods/api/auth/auth'
import GantiPassword from './Conselor/GantiPassword'
const queryString = require('query-string')
function Dashboard(props) {
    // function Dashboard({ activeMenu, isChat, setChat, role, setJadwal }) {
    const [isConselor, setConselor] = useState('true')
    const [user, setUser] = useState(props.role)
    const [loading, setLoading] = useState(false)
    const [activeKonselor, setActiveKonselor] = useState(null)
    useEffect(() => {
        console.log(getUserFromToken())
        const parsed = queryString.parse(window.location.search)
        console.log(parsed)
        if(parsed.redirect){
            console.log('redirect to',parsed.redirect)
            props.setActiveMenu(parsed.redirect)
        }
        // if(urlData.redirect){
        //     if(urlData.redirect == 'conference'){
        //
        //     }
        // }
        async function loadKonselings() {
            const response = await getKonselings('konselor_id=' + getUserFromToken().info.id)
            props.setKonselingList(response.rows)
            setLoading(false)
        }
        async function loadKonselor() {
            try {
                const konselor_id = getUserFromToken().info.id
                const response = await getCountKonseling("aktif", konselor_id)
                const response2 = await getCountKonseling("selesai", konselor_id)
                const j = await getJadwalKonselingAktif(konselor_id)
                const jO = await getJadwalOnline()
                props.setJadwalOnline(jO.data)
                props.setJadwal(j.rows)
                props.setKonselingAktif(response.data)
                props.setKonselingSelesai(response2.data)
                // props.setKonselingAktif(0)
                loadKonselings()
                console.log('j', j.rows)
            } catch (e) {
                console.log('retrying')
                loadKonselor()
            }
        }

        async function checkSessions() {

        }

        async function konseliProfile() {
            const response = await konselingCheck(getUserFromToken().info.id)
            const prodi = await resolveProdi(getUserFromToken().info.prodi_id)
            const faculty = await resolveFaculty(prodi.data.faculty_id)
            const konseli = getUserFromToken()
            konseli.prodi = prodi.data
            konseli.faculty = faculty.data
            localStorage.setItem('user', JSON.stringify(konseli))
            props.setSessions(response.rows)
            setLoading(false)
        }

        if (getUserFromToken()) {
            const user = getUserFromToken()
            setUser(user.role)
            if (user.role == "konselor") {
                // loadKonselor()
            } else {
                // konseliProfile()
                setLoading(false)
            }
        } else {
            window.location = "/"
        }
        const aK = getUserFromToken().info
        aK.user = getUserFromToken()
        setActiveKonselor(aK)
    }, [])
    if (loading) {
        return (
            <div className='loading-container'>
                <img src={require('../../assets/images/logo.png')} />
            </div>
        )
    }
    return (
        <div className='dashboard'>
            <AppBar />
            {
                user == 'konselor' ?
                    <div style={{ display: 'flex' }}>
                        <Navigation user='conselor' />
                        <PageDaftarKonseli setIsChat={props.setChat} />
                        <PageHome />
                        <PageCaseConference />
                        <PageArsip/>
                        <EditKonselor activeKonselor={activeKonselor}/>
                        <GantiPassword />
                    </div> :
                    user == 'konseli' ?
                        <div style={{ display: 'flex' }}>
                            <Navigation user='conseli' />
                            <ConseliHome />
                            <SessionEnrollment />
                            <RuangKonseling />
                            <KonseliArsip />
                        </div> :
                        null

            }
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)