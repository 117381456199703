import React from 'react'
import Button from '../../components/Buttons'
import './style.css'
import Appbar from './Appbar'
import { getUserFromToken } from '../../methods/api/auth/auth'

export default function Home(props) {
    const user = getUserFromToken(false)
    const toDashboard = () => {
        window.location.href = '/dashboard'
    }
    return (
        <div className='landing-section' id="home">
            {/* <img className='wave' src={require('../../assets/images/wave.png')} /> */}
            {/* <img className='people' src={require('./../../assets/images/PEOPLE.png')} /> */}
            <img className='people' src={require('./../../assets/images/landing/ilustrasi_landingpage.svg')} />
            <div className='hero'>
                <div style={{}}>
                    <h1>UKSW Peduli.</h1>
                    <div className='hero-quote'>
                        Konseling gratis oleh Campus Ministry. <br />
                        Apapun yang menjadi masalahmu, <br />
                        dapatkan dukungan yang kamu butuhkan. <br />
                        Saat ini juga, di Satya Wacana Counseling.
                    </div>
                    <div style={{ maxWidth: 170, marginLeft: 'auto', marginRight: 'auto', maginTop: 32 }}>
                        {
                            user == null ? 
                            <Button title='Mulai Konseling' width={170} onClick={() => { props.showPopup() }} background='#4E73DF' /> :
                                user.role == 'admin' ? <Button title='Mulai Konseling' width={170} onClick={() => { props.showPopup() }} background='#4E73DF' /> :
                                    user.role == 'konseli' ? <Button title={user.info.nim} width={120} height={32} onClick={toDashboard} background='#4E73DF' />
                                        : <Button title={user.info.nama_konselor.split(" ")[0]} width={120} height={32} onClick={toDashboard} background='#4E73DF' />
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}