import React, {useState} from 'react'
export default function ConferenceCard(props) {
    const [c, setC] = useState(false)
    const { conference, onClick } = props
    const [time, setTime] = useState(null)
    const [isLoading, setLoading] = useState(true)
    useState(() => {
        try{
            if(props.conference.last_chat){
                if(new Date().getDate() === new Date(props.conference.last_chat.tgl_chat).getDate()){
                    setTime(props.conference.last_chat.created_at.split("T")[1].split(".")[0])
                }else{
                    setTime(props.conference.last_chat.tgl_chat)
                }
                setTime(props.conference.last_chat.created_at.split("T")[1].split(".")[0])
            }else{
                console.log('no last chat')
                setC(false)
            }
            setC(conference)
            setLoading(false)
        }catch(e){
            console.log('aha')
        }
        console.log('c: ',c)

    }, [])
    if(isLoading){
        return <div/>
    }
    return (
        <div className={!props.isActive ? 'conseli active-conseli' : 'conseli'} >
            <div className='detail' >
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 0, padding: 0, width: '100%'}}>
                    <span onClick={()=>{onClick()}} style={{ fontWeight: 600, fontSize: 17, cursor: 'pointer' }}>{conference?.judul_case_conference}</span>

                    <div className={'time'}>
                        {time}
                    </div>
                </div>
                {/*<div>{lastChat}</div>*/}
                {/*{JSON.stringify(c)}*/}
                {c ? <span style={{ fontWeight: 300, fontSize: 12 }}>{c.last_chat?c.last_chat?.nama_konselor+": "+(atob(c.last_chat?.chat_konseling).slice(0, 40-c.last_chat?.nama_konselor?.length)):''}</span>:<div/>}
            </div>
        </div>
    )
}

ConferenceCard.defaultProps = {
    conference : {
        judul_case_conference: '',
        last_chat: '',
    },
    onClick: () => {}
}

