import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import './chat.css'
import Bubble from './Bubble'
import { setActiveMenu } from '../../store/actions'
import ChatDate from './ChatDate'
import { konselingCheck, createMessage, showMessages, showKonseling } from '../../methods/api/konseling'
import { showKonselor } from '../../methods/api/konselor'
import { showKonseli } from '../../methods/api/konseli'
import ReactLoading from 'react-loading';
import {createChat, showConferencechat} from "../../methods/api/conference";
import {changeJadwal} from "../../methods/api/jadwal";
import { getUserFromToken } from '../../methods/api/auth/auth'
function Chat(props) {
    const { activeConseli: conseli } = props
    const [name, setName] = useState('?')
    const [messages, setMessages] = useState('')
    const [user, setUser] = useState(null)
    const [konselor, setKonselor] = useState(null)
    // const [chats, setChats] = useState([
    //     { sender: 'conselor', message: 'Halo', date: new Date() },
    // ])
    const [isLoading, setLoading] = useState(true);
    const [chats, setChats] = useState([])

    const [sessions, setSession] = useState([])

    const [isSending, setSending] = useState(false)
    const [messageLength, setMessageLength] = useState(-1)

    const [tempUsername, setTempUsername] = useState("")

    const [lastMessage, setLastMessage] = useState([])
    const conference = props.conference?props.conference : null
    useEffect(() => {
        setUser(getUserFromToken().info)
        async function check() {
            const response = await konselingCheck(props.activeConseli.id ? props.activeConseli.konseli_id : getUserFromToken().info.id)
            if (response.success) {
                setSession(response.rows)
                if(response.rows[response.rows.length-1].jadwal.available == 'candidate'){
                    const ses = response.rows[response.rows.length-1]
                    changeJadwal(ses.jadwal.id, ses.jadwal.id, ses.id)
                }
            } else {
                console.log('konseling check failed')
            }
        }
        if(props.conference){
            setInterval(()=>{
                mc()
            }, 2000)
        }else{
            check()
        }
    }, [])

    const forceUpdateActiveConseli = async (konseling_id) => {
        const response = await showKonseling(konseling_id)
        if(response.success){
            setTempUsername(response.data[0].konseli.nama_konseli)
        }
    }

    useEffect(()=>{
        const query= new URLSearchParams(window.location.search)
        if(query.get("id")){
            forceUpdateActiveConseli(query.get("id"))
        }
    }, [])

    async function m() {
        const response = await showMessages(props.activeConseli.id ? props.activeConseli.id : sessions[sessions.length-1].id)
        // console.log('show', props.activeConseli ? props.activeConseli.id : sessions[0].id)
        if (response.success) {
            setChats(response.rows)
            if (isLoading == true && endChat.current) {

            }
            // console.log(chats)
            setLoading(false)
            setSending(false)
        }
    }

    async function mc(){
        const response = await showConferencechat(conference.id)
        if(response.success){
            setChats(response.rows)
            if (isLoading == true && endChat.current) {

            }
      //      console.log(response)
            setLoading(false)
            setSending(false)
        }else{
            console.log('mc fail')
        }
    }

    useEffect(() => {
        const keys = Object.keys(chats)
        const lastChatDay = chats[keys[keys.length - 1]]
    }, [chats])

    useEffect(() => {
        async function k() {
            if (getUserFromToken().role == 'konseli') {
                console.log('showKonselor k')
                const response = await showKonselor(sessions[sessions.length-1].konselor_id)
                console.log(response)
                setKonselor(response.data.nama_konselor)
            }
        }

        if (sessions.length > 0) {
            k()
            setInterval(() => {
                if(conference != null){
                    mc()
                }else{
                    m()
                }
                if (chats.length != messageLength) {
                }
            }, 2000)
            // m()
        }
    }, [sessions])

    useEffect(() => {
        if (isLoading == false) {
            endChat.current.scrollIntoView()
        }
    }, [isLoading])


    const messageRef = useRef(null)

    const postMessage = async () => {
        console.log('sending message')
        if(conference != null){
            const body = {
                UserID: getUserFromToken().id,
                chat_konseling: btoa(messageRef.current.value),
                case_conference_id: conference.id
            }
            console.log('body', body)
            const response = await createChat(body);
            console.log(response)

        }else{
            const body = {
                userID: getUserFromToken().id,
                chat_konseling: btoa(messageRef.current.value),
                konseling_id: sessions[sessions.length-1].id
            }
            const response = await createMessage(body)
            console.log('body', body)
            console.log(response)
        }

        // m()
    }

    const handleOnSubmit = (e) => {
        setSending(true)
        e.preventDefault()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        postMessage()
        setMessages([...messages, { 'sender': 'me', message: messageRef.current.value, date: new Date() }])
        // setMessage('')
        messageRef.current.value = ''
        endChat.current.scrollIntoView({ behavior: 'smooth' })
        endChat.current.scrollIntoView({ behavior: 'smooth' })
    }

    const onKeyPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey) {
            e.preventDefault();
            let start = e.target.selectionStart,
                end = e.target.selectionEnd;
            const m = messageRef.current.value.substring(0, start) + '\n' + messageRef.current.value.substring(end)
            messageRef.current.selectionStart = messageRef.current.selectionEnd = start + 1
            messageRef.current.value = m
        } else if (e.keyCode === 13) { // block enter
            e.preventDefault();
            if (messageRef.current.value.length > 0) {
                handleOnSubmit(e)
            }
        }

    };

    const onKeyRelease = (e) => {
    }

    const endChat = useRef()
    return (
        <div className='content chat'>
            <div className='conseli-header' >
                {konselor}
                <div style={{cursor: 'pointer'}} onClick={()=>{
                    if(props.setChat){
                        props.setChat(false)
                    }
                }}>{conference?.judul_case_conference?conference.judul_case_conference:props.activeConseli.id ? props.activeConseli?.konseli?.nama_konseli : tempUsername}</div>
            </div>
            <div className='content'>
                <div className='chat-container'>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center'
                    }}>
                        {isLoading && <ReactLoading color='#F1C40F' type='spinningBubbles' />}
                    </div>
                    {Object.keys(chats).map((c, index) => {
                        return (
                            <div>
                                <ChatDate date={c} />
                                {chats[c].map((item, index) => {
                                    return (
                                        <Bubble conference={conference} {...item} />
                                    )
                                })}

                            </div>
                        )
                    })}
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        {isSending && <ReactLoading color='#F1C40F' type='spinningBubbles' width={32} height={32} />}
                    </div>
                    <div ref={endChat} style={{ paddingBottom: 100 }} />
                </div>
                <form onSubmit={handleOnSubmit}>
                    <div className='chat-controller'>
                        {/* <input placeholder='Ketik pesan..' ref={messageRef} /> */}
                        <textarea placeholder='Ketik pesan..' ref={messageRef} style={{ width: '100%' }} onKeyDown={onKeyPress} onKeyUp={onKeyRelease}></textarea>
                        <button style={{ border: 'none', background: 'none' }} >
                            <img src={require('./../../assets/images/send.png')} />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = ({ activeConseli }) => ({
    activeConseli
})

const mapDispatchToProps = dispatch => ({
    setActiveMenu: menu => {
        dispatch(setActiveMenu(menu))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)