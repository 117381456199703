import React from 'react'
import './landing.css'
export default function Service({ img, quote, description }) {
    return (
        <div className='service-container'>
            <div className='service-quote'>
                <img src={img} />
                {quote}
            </div>
            <div className='service-description'>
                {description}
            </div>
        </div>
    )
}