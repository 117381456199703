import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Landing from "./pages/Landing";
import ChatTest from "./pages/Testing/ChatTest";
import Pengunguman from "./pages/Pengunguman";

import { Container, Link as fabLink, Button, lightColors } from 'react-floating-action-button'
import { BsArrowBarUp } from "react-icons/bs";

import './App.css'
import Appbar from "./pages/Landing/Appbar";
import Admin from "./pages/Admin/Admin";
export default function App(props) {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrolling, setScrolling] = useState(false)
  const [showUp, setShowUp] = useState(false);
  const [fabColor, setFabColor] = useState('#4E73DF')
  const onHover = () => {
    setFabColor('#F1C40F')
  }
  const onHoverOut = () => {
    setFabColor('#f3d769')
  }

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
      if (e.target.documentElement.scrollTop > window.innerHeight) {
        setShowUp(true)
      } else {
        setShowUp(false)
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  return (
    <Router>
      <div style={{ backgroundColor: 'white' }}>
        <Switch>
          <Route path={'/admin'}>
            <Admin/>
          </Route>
          <Route path="/dashboard">
            <Dashboard role='conselor' />
          </Route>
          <Route path="/topics">
            <Topics />
          </Route>
          <Route path="/testing/chat">
            <ChatTest />
          </Route>
          <Route path="/testing/konseli">
            <Dashboard role='conseli' />
          </Route>
          <Route path="/pengumuman">
            <Pengunguman />
          </Route>
          <Route path="/pengumuman/{:id}">
            <Pengunguman />
          </Route>
          <Route path="/">
            <div>
              <Landing />
              {
                showUp &&
                <Container styles={{ marginLeft: 80, right: 12, bottom: 12 }}>
                  <div style={{}} >
                    <Button className='fab' styles={{ cursor: 'pointer', backgroundColor: fabColor }} onClick={() => {
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    }}>
                      <a href="">
                        <BsArrowBarUp color='white' size={32} />
                      </a>
                    </Button>
                  </div>
                </Container>
              }

            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function About() {
  return <h2>About</h2>;
}

function Topics() {
  let match = useRouteMatch();

  return (
    <div>
      <h2>Topics</h2>

      <ul>
        <li>
          <Link to={`${match.url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${match.url}/props-v-state`}>
            Props v. State
          </Link>
        </li>
      </ul>

      {/* The Topics page has its own <Switch> with more routes
          that build on the /topics URL path. You can think of the
          2nd <Route> here as an "index" page for all topics, or
          the page that is shown when no topic is selected */}
      <Switch>
        <Route path={`${match.path}/:topicId`}>
          <Topic />
        </Route>
        <Route path={match.path}>
          <h3>Please select a topic.</h3>
        </Route>
      </Switch>
    </div>
  );
}

function Topic() {
  let { topicId } = useParams();
  return <h3>Requested topic ID: {topicId}</h3>;
}