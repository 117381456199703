import React, { useState, useEffect } from 'react'
import './style.css'
import Home from './Home'
import Layanan from './Layanan'
import Konselor from './Konselor'
import Popup from './../../components/Landing/Popup'
import Quote from './Quote'
import PengungumanFlash from './PengungumanFlash'
import Footer from './Footer'
import FloatingButtonUp from '../../components/FloatingButtonUp'
import Appbar from './Appbar'

export default function Landing(props) {
    const [showPopup, setPopup] = useState(false)
    const [showPopupToRegister, setRegisterPopup] = useState(false)
    // useEffect(() => {
    //     if (showPopup === true || showPopupToRegister === true) {
    //         if (props.lockScroll) {
    //             props.lockScroll()
    //             console.log('lock')
    //         }
    //     }
    //     console.log('')
    // }, [showPopupToRegister, setRegisterPopup])
    return (
        <div className={showPopup || showPopupToRegister ? 'scroll-lock' : ''} >
            {/* <div style={{
                position: 'absolute',
                top: 120
            }}>
                <FloatingButtonUp />
            </div> */}
            {/* <div id="home">
            </div> */}
            <Appbar showPopup={() => setPopup(true)} />
            <Home showPopup={() => setPopup(true)} showRegisterPopup={() => setRegisterPopup(true)} />
            <div id="pengumuman" style={showPopup || showPopupToRegister ? { display: 'none' } : {}} >
                <PengungumanFlash />
            </div>
            <div id="layanan">
                <Layanan />
            </div>
            <div id="konselor">
                <Konselor />
            </div>
            <div id="quote">
                <Quote />
            </div>
            <Footer />
            {showPopup && <Popup {...props} register={true} onDismiss={() => setPopup(false)} />}
            {showPopupToRegister && <Popup {...props} register={!showPopupToRegister} onDismiss={() => {
                setPopup(false)
                setRegisterPopup(false)
            }} />}
        </div>
    )
}