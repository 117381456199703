import React, { useState } from 'react'
import './conselorcards.css'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export default function ScheduleCard(props) {
    const [index, setIndex] = useState(0)
    const [loading, setLoading] = useState(true);
    const days = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
    const toRight = () => {
        setIndex((index + 1) % 7)
    }
    const toLeft = () => {
        setIndex((7 + (index - 1)) % 7)
    }
    const toTimeFormat = (jam_mulai, jam_akhir) => {
        let t = parseInt(jam_mulai)
        let t2 = parseInt(jam_akhir)
        if (t < 10)
            t = "0" + t
        if (t2 < 10)
            t2 = "0" + t2
        t += ".00"
        t2 += ".00"
        return t + "-" + t2
    }
    const today = props.jadwal ? props.jadwal[days[index]] : null
    return (
        <SkeletonTheme>
            <div className='schedule-card' style={!props.jadwal ? { boxShadow: 'none' } : {}}>
                <div className='title'>
                    {!props.jadwal ? <Skeleton height={24} width={280} /> : 'Daftar Konseling'}
                </div>
                {!props.jadwal ? <Skeleton height={24} width={280} /> :
                    <div className='day-selector' style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <div className='schedule-day-selector' onClick={toLeft}>
                            <BsChevronLeft />
                        </div>
                        {days[index]}
                        <div className='schedule-day-selector' onClick={toRight}>
                            <BsChevronRight />
                        </div>
                    </div>
                }
                {
                    !props.jadwal ?
                        <div>
                            <p>
                                <Skeleton height={24} width={280} />
                            </p>
                            <p>
                                <Skeleton height={24} width={280} />
                            </p>
                        </div>
                        :
                        today ?
                            <table>
                                {
                                    today.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {toTimeFormat(item.jadwal.jam_mulai, item.jadwal.jam_akhir)}
                                                </td>
                                                <td>{item.konseli.nama_konseli}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                            : <div style={{ textAlign: 'center' }}>Tidak ada jadwal</div>
                }

            </div>
        </SkeletonTheme>
    )
}

// const mapStateToProps = ({ jadwalOnline }) => ({ activeMenu })
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)