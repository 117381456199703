import React from 'react'
import './navigation.css'
import { setActiveMenu, mapDispatchToProps } from '../../store/actions'

import { connect } from 'react-redux'

function Navigation(props) {
    const onMenuClick = (title) => {
        props.setActiveMenu(title)
        props.closeCaseConference()
        props.closeReferral()
        props.setChat(false)
        props.setConference(null)
        // props.setActiveConseli(false)
    }
    if (props.user == 'admin'){
        return(
            <div className={"navigation"}>
                <IconMenu src={require("./../../assets/images/home.png")} title="Home" onMenuClick={onMenuClick} tooltip='Home' />
                <IconMenu src={require("./../../assets/images/admin/icon_menu_admin_kelolaKonselorFilled.svg")} title="Kelola Konselor" onMenuClick={onMenuClick} tooltip='Kelola Konselor' />
                <IconMenu src={require("./../../assets/images/admin/icon_menu_admin_report.svg")} title="Report Presensi" onMenuClick={onMenuClick} tooltip='Report Presensi' />
                <IconMenu src={require("./../../assets/images/admin/icon_menu_admin_setting.svg")} title="Setting" onMenuClick={onMenuClick} tooltip='Setting' />
                <IconMenu src={require("./../../assets/images/admin/icon_menu_admin_informasipengumuman.svg")} title="Informasi Pengumuman" onMenuClick={onMenuClick} tooltip='Informasi Pengumuman' />
            </div>
        )
    }
    if (props.user == 'conselor')
        return (
            <div className="navigation">
                <IconMenu src={require("./../../assets/images/home.png")} title="Home" onMenuClick={onMenuClick} tooltip='Home' />
                <IconMenu src={require("./../../assets/images/konselor/icon_menu_konselor_daftarkonseli.svg")} title="Daftar Konseli" onMenuClick={onMenuClick} tooltip='Daftar Konseli' />
                {/* <IconMenu src={require("./../../assets/images/konselor/konselor_icon_menu_conference.svg")} title="Daftar Konseli" onMenuClick={onMenuClick} /> */}
                <IconMenu src={require("./../../assets/images/konselor/konselor_icon_menu_conference.svg")} title="Case Conference" onMenuClick={onMenuClick} tooltip='Case Conference' />
                <IconMenu src={require("./../../assets/images/book.png")} title="Arsip" onMenuClick={onMenuClick} tooltip='Arsip' />
            </div>
        )
    if (props.user == 'conseli')
        return (
            <div className="navigation">
                <IconMenu src={require("./../../assets/images/home.png")} title="Home" onMenuClick={onMenuClick} tooltip='Home' />
                <IconMenu src={require("./../../assets/images/konselor/icon_menu_konselor_daftarkonseli.svg")} title="Arsip" onMenuClick={onMenuClick} tooltip='Arsip' />
                <IconMenu src={require("./../../assets/images/group.png")} title="Case Conference" onMenuClick={() => { }} tooltip='Dokumen' />
                <IconMenu src={require("./../../assets/images/book.png")} title="menu4" onMenuClick={() => { }} tooltip='Form Kepuasan' />
            </div>
        )
}

const IconMenu = ({ src, title, onMenuClick, tooltip }) => {
    return (
        <div className="icon-menu" onClick={() => onMenuClick(title)}>
            <img src={src} />
            <div className='icon-menu-tooltip'>
                {tooltip}
            </div>
        </div>
    )
}

export default connect(null, mapDispatchToProps)(Navigation)

export {
    IconMenu
}