import { headerTemplate, host } from "../../config"

const getKonselors = async (params="") => {

    const raw = {
        "method": "GET",
        "headers": headerTemplate()
    }
    const request = await fetch(host + "/api/konselor"+params, raw)
    const response = await request.json()

    return response
}

const getJadwals = async (konselor_id) => {
    const raw = {
        "method": "GET",
        "headers": headerTemplate()
    }
    const request = await fetch(host + "/api/jadwal?konselor_id=" + konselor_id, raw)
    const response = await request.json()
    return response
}


export {
    getKonselors,
    getJadwals
}