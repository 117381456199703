import React, { useEffect, useState } from 'react'
import './profile.css'
import Button from '../Buttons'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../store/actions'
import { konselingCheck, resolveJadwalKonseling } from '../../methods/api/konseling'
import { resolveProdi, resolveFaculty } from '../../methods/api/prodi'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReferralAgreementPopup, {
    ConferenceAgreementPopup,
    SessionEndAgreementPopup
} from "../DashboardPopups";
import Avatar from '../Avatar/Avatar'
import { host } from '../../methods/config'
import { getUserFromToken } from '../../methods/api/auth/auth'
function ConseliProfile(props) {
    const [userInfo, setUser] = useState(null)
    const [sessions, setSession] = useState([])
    const [jadwal, setJadwal] = useState(null)
    const [prodi, setProdi] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [showReferralPopup, setShowReferralPopup] = useState(false)
    const [showConferencePopup, setShowConferencePopup] = useState(false)
    const [showAgreementPopup, setShowAgreementPopup] = useState(false)
    useEffect(() => {
        setUser(getUserFromToken().info)
        async function check() {
            const response = await konselingCheck(getUserFromToken().info.id)
            const prodi = await resolveProdi(getUserFromToken().info.prodi_id)
            const faculty = await resolveFaculty(prodi.data.faculty_id)
            setProdi({
                prodi: prodi.data,
                faculty: faculty.data
            })
            console.log({
                prodi: prodi.data,
                faculty: faculty.data
            })
            if (response.success && prodi.success && faculty.success) {
                console.log('konselingcheck', response)
                setSession(response.rows)
                // setTimeout(() => props.onFinishedLoading(), 5000)
                setLoading(false)
            } else {
                console.log('failed')
            }
            // props.onFinishedLoading()
        }
        // props.onFinishedLoading()
        check()
        
    }, [])

    useEffect(()=>{
        const query = new URLSearchParams(window.location.search)
        if(sessions.length>0){
            if(query.get("show") === "persetujuan referal"){
                setShowReferralPopup(true)
            }
            if(query.get("show") === "persetujuan conference"){
                setShowConferencePopup(true)
            }
            
        }
        
    }, [sessions])
    const user = getUserFromToken()

    // if (isLoading) {
    //     return (
    //         <SkeletonTheme >
    //             <div className='conseli-profile-card' >

    //                 <div style={{ display: 'flex' }}>
    //                     <div className='conseli-profile-avatar'>

    //                     </div>

    //                     <div style={{ marginLeft: 24, flex: 1 }}>
    //                         <div style={{
    //                             fontStyle: 'normal',
    //                             fontWeight: 600,
    //                             fontSize: 24,
    //                         }}>
    //                             <p>
    //                                 <Skeleton count={4} />
    //                             </p>
    //                         </div>

    //                     </div>
    //                 </div>
    //             </div>
    //         </SkeletonTheme>
    //     )
    // }
    return (
        <SkeletonTheme>
            <div className='conseli-profile-card' style={isLoading ? { border: 'none', boxShadow: 'none' } : {}}>
                <div style={{ display: 'flex' }}>
                    {
                        isLoading ?
                            <Skeleton width={100} height={100} circle={true} />
                            :
                            // <div className='conseli-profile-avatar'>
                                
                            // </div>
                            <Avatar img={ getUserFromToken().avatar} style={{width: 72, height: 72}}/>
                    }

                    <div style={{ marginLeft: 24 }}>
                        <div style={{
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: 24,
                        }}>

                            {isLoading ? <Skeleton width={300} /> : userInfo.nama_konseli}
                        </div>
                        <table>
                            <tr>
                                <td>{isLoading ? <Skeleton width={300} /> : userInfo?.nim}</td>
                                <td>{isLoading ? <Skeleton width={100} /> : 'Jenis Kelamin: '+userInfo?.jenis_kelamin}</td>
                                <td>{isLoading ? <Skeleton width={100} /> : 'Agama: ' + userInfo?.agama}</td>
                                <td>{isLoading ? <Skeleton width={100} /> : 'Alamat: '}</td>
                            </tr>
                            <tr>
                                <td>{isLoading ? <Skeleton width={100} /> : userInfo?.progdi}</td>
                                <td>{isLoading ? <Skeleton width={100} /> : 'Tgl. Lahir: '+new Date(userInfo.tgl_lahir_konseli).toLocaleDateString()}</td>
                                <td>{isLoading ? <Skeleton width={100} /> : 'Suku: ' + userInfo?.suku}</td>
                                <td>{isLoading ? <Skeleton width={100} /> : userInfo?.alamat_konseli}</td>
                            </tr>
                            <tr>
                                <td>{isLoading ? <Skeleton width={200} /> : userInfo?.fakultas}</td>
                                <td>{isLoading ? <Skeleton width={200} /> : 'Umur: '+Math.abs(new Date(Date.now() - new Date(userInfo.tgl_lahir_konseli).getTime()).getUTCFullYear() - 1970)+' Tahun'}</td>
                                <td>{isLoading ? <Skeleton width={200} /> : 'No. HP: ' + userInfo?.no_hp_konseli}</td>

                            </tr>
                        </table>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className='conseli-profile-buttons'>
                            <Skeleton width={140} count={4} /> :
                        </div> :
                        <div className='conseli-profile-buttons'>
                            <div style={{ width: 140 }}>
                                <Button title='Ruang Konseling' fontSize={14} width={140} height={26} disabled={sessions.length == 0} width={30} onClick={() => props.setActiveMenu('Ruang Konseling')} />
                                <div id='to' style={{ marginRight: 52 }}></div>
                            </div>
                            <div style={{ width: 140 }}>
                                <Button title='Case Conference' fontSize={14} width={140} height={26} disabled={!(sessions.length > 0 && sessions[sessions.length-1].conferenced=='ask')} width={30} onClick={()=>setShowConferencePopup(true)}/>
                            </div>
                            <div style={{ width: 100 }}>
                                <Button title='Referal' fontSize={14} width={100} height={26} disabled={!(sessions.length > 0 && sessions[sessions.length-1].refered=='ask')} width={30} onClick={()=>setShowReferralPopup(true)} />
                            </div>
                            <div style={{ width: 100 }}>
                                <Button title='Close Case' fontSize={14} width={100} height={26} disabled={sessions.length == 0} width={30} onClick={() => {setShowAgreementPopup(true)}} />
                            </div>
                        </div>
                }

            </div>
            {showReferralPopup && <ReferralAgreementPopup
                konseling={sessions[sessions.length-1]}
                onPopupDismiss={() => {
                    setShowReferralPopup(false)
                }}
            />}
            {showConferencePopup && <ConferenceAgreementPopup
                konseling={sessions[sessions.length-1]}
                onPopupDismiss={() => {
                    setShowConferencePopup(false)
                }}
            />}
            {showAgreementPopup && <SessionEndAgreementPopup
                title={'Akhiri Sesi Konseling'}
                konseling={sessions[sessions.length-1]}
                onPopupDismiss={() => {
                    setShowAgreementPopup(false)
                }}
            />}
        </SkeletonTheme>
    )
}
ConseliProfile.defaultProps = {
    onFinishedLoading: () => { }
}

const mapStateToProps = ({ conselingSession, sessions }) => ({ conselingSession, sessions })
export default connect(mapStateToProps, mapDispatchToProps)(ConseliProfile)