import React from 'react'
import './style.css'
import Service from '../../components/Landing/Service'
import YellowBar from '../../components/Landing/YellowBar'
export default function Layanan() {
    return (
        <div className='landing-section container-section'>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <img
                    src={require('../../../src/assets/images/landing/ilustrasi_landingpage_layanan.svg')}
                    style={{ height: 320, }}
                />
                <div className='section-title' style={{ textAlign: 'left', width: 600, marginRight: 120 }}>
                    <div style={{
                        fontWeight: 'normal',
                        marginBottom: 8
                    }}>
                        Layanan
                    </div>
                    Satya Wacana Counseling selalu siap membantumu..
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <YellowBar />
                    </div>
                </div>
            </div>
            <div className='services'>
                <Service img={require('../../assets/images/landing/icon_landingpage_diskusi.svg')} quote={'Mendiskusikan masalahmu'} description='Konselor di Satya Wacana Counseling akan selalu siap mendengarkan masalahmu dan berdiskusi untuk mendapatkan solusi terbaik.' />
                <Service img={require('../../assets/images/landing/icon_landingpage_versiterbaik.svg')} quote={`Mencapai versi terbaikmy`} description='Satya Wacana Counseling akan selalu mendukung setiap langkahmu dalam mencapai versi terbaik dirimu.' />
                <Service img={require('../../assets/images/landing/icon_landingpage_percayadiri.svg')} quote={'Menjadi pribadi yang percaya diri'} description='Setiap orang adalah spesial. Satya Wacana Counseling akan selalu siap mengingatkanmu bahwa kamu mampu, hingga kamu tidak ada alasan untuk meragukan dirimu sendiri.' />
            </div>
        </div>
    )
}