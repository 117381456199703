import { headerTemplate, host } from "../config"

const getJadwalKonselor = async (id) => {
    const request = await fetch(host + "/api/jadwal?online&konselor_id=" + id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const createKonselor = async (nama_konselor, email_konselor, profesi_konselor, no_hp_konselor, jadwals, avatar) => {
    const request = await fetch(host+"/api/konselor", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            nama_konselor, email_konselor, profesi_konselor,  no_hp_konselor, jadwals, avatar
        })
    })
    const response = await request.json()
    return response
}

const showKonselor = async (id) => {
    const request = await fetch(host + "/api/konselor/" + id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const getKonselors = async () => {
    const request = await fetch(host+"/api/konselor", {
        "method": "GET"
    })
    const response = await request.json()
    return response
}

const editKonselor = async (id, nama_konselor, profesi_konselor, email_konselor, no_hp_konselor, status, jadwals, avatar) => {
    const request = await fetch(host+"/api/konselor/"+id, {
        "method": "PUT",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            nama_konselor,
            profesi_konselor,
            email_konselor,
            no_hp_konselor,
            status,
            jadwals,
            avatar
        })
    })
    const response = await request.json()
    return response

}

export {
    showKonselor,
    editKonselor,
    createKonselor,
    getJadwalKonselor
}

