import React, { useState } from 'react'
import './style.css'
import Header from './Header'
import ConseliCard from './ConseliCard'
import { conselies } from '../../utils/dummies/data'
import { setActiveConseli, setActiveMenu, mapDispatchToProps } from '../../store/actions'
import { connect } from 'react-redux'
import ConferenceCard from './ConferenceCard'
import { getKonselors } from '../../methods/api/auth/konselor'
import { useEffect } from 'react'
import { getKonselings } from '../../methods/api/konseling'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {showConferences} from "../../methods/api/conference";
import { getUserFromToken } from '../../methods/api/auth/auth'

function ListHeader(props) {
    return (
        <div className='conseli-header' style={props.style}>
            <input placeholder='cari..' onChange={props.onSearchChange} />
        </div>
    )
}

function List(props) {
    const [conselorList, setConselors] = useState([])
    const [masterList, setMasterList] = useState([])
    const [isActive, setActive] = useState(conselies[0]);
    const [loading, setLoading] = useState(true)
    const [conferences, setConferences] = useState([])
    const onSearchChange = (e) => {
        console.log(e.target.value)
        if (props.title === 'conseli') {
            const newList = masterList.filter((item, index) => {
                return item.konseli.nama_konseli.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
            })
            setConselors(newList)
        }
        if (props.title === 'conselor') {
            const newList = masterList.filter((item, index) => {
                return item.nama_konselor.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
            })
            setConselors(newList)
        }
    }
    useEffect(() => {
        const user = getUserFromToken()
        async function getListConference(){
            const response = await showConferences(getUserFromToken().info.id)
            if(response.success){
                // let conferenceWithLastChat = response.rows.filter((item, index) => (a.last_chat))
                // let sortedConferences = response.rows.sort((a, b) =>(new Date(a.last_chat?.created_at), new Date(b.last_chat?.created_at))?1:-1)
                let sortedConferences = response.rows.filter((r, i) => (r))
                sortedConferences = sortedConferences.filter((r, i) => (r))
                console.log('no null:')
                console.log(sortedConferences)
                sortedConferences = response.rows.sort((a, b) => {
                    var dateA = new Date('1980-01-01')
                    var dateB = new Date('1980-01-01')
                    try{
                        if(a.last_chat !== null){
                            dateA = new Date(a.last_chat.created_at)
                        }
                        if(b.last_chat !== null){
                            dateB = new Date(b.last_chat.created_at)
                        }    
                    }catch (e) {

                    }
                    
                    // var dateA = a.last_chat?new Date(a.last_chat.created_at):new Date("1980-01-01")
                    // var dateB = b.last_chat?new Date(b.last_chat.created_at):new Date("1980-01-01")
                    if(dateA > dateB){
                        return -1
                    }else{
                        return 1
                    }
                    return 0
                })
                setConferences(sortedConferences)
                setMasterList(sortedConferences)
            }
        }
        async function getlistkonselors() {
            if(props.only){
                setMasterList([props.only])
                setConselors([props.only])
                return
            }else{
                if(props.allKonselor){
                    const response = await getKonselors("?all")
                    console.log(response)
                    const data = response.rows.filter((item, index) => {
                        return item.id != props.except
                    })
                    setMasterList(data)
                    setConselors(data)
                }else{
                    const response = await getKonselors("")
                    console.log(response)
                    const data = response.rows.filter((item, index) => {
                        return item.id != props.except
                    })
                    setMasterList(data)
                    setConselors(data)
                }
                
            }
        }
        async function getlistkonseli() {
            const response = await getKonselings('konselor_id=' + user.info.id)
            // const list = props.konselingList
            const list = response.rows
            const query= new URLSearchParams(window.location.search)
            if(query.get("id")){
                // openChatRedirect(query.get('id'))
                list.map((item, index) => {
                    if(item.id === query.get('id')){
                        props.setActiveConseli(item)
                    }
                })            
            }
            console.log(list)
            setMasterList(list)
            setConselors(list)
            props.setActiveConseli(false)
            // const query = new URLSearchParams(props.location.onSearchChange)
            // if(query.get('id')){
            //     response.rows.map((item, index) => {
            //         if(item.id == query.get('id')){
            //             props.setActiveConseli(item)
            //         }
            //     })
            // }
            setLoading(false)
        }
        async function getListKonseliArsip(){
            const body = props.konseli_id ? 'arsip&konseli_id='+props.konseli_id:'arsip&konselor_id=' + user.info.id
            const response = await getKonselings(body)
            // const list = props.konselingList
            const list = response.rows
            console.log('konseliarsip')
            if(props.setArsipAvailable){
                console.log("tes tes")
                console.log(list.length>0)
                props.setArsipAvailable(list.length>0)
            }
            console.log(list)
            setMasterList(list)
            setConselors(list)
            props.setActiveConseli(false)
            setLoading(false)
        }
        if (props.title == 'conselor') {
            getlistkonselors()
        }
        if (props.title == 'conseli') {
            getlistkonseli()
        }
        if (props.title == 'conference'){
            getListConference()
        }
        if (props.title == 'arsip'){
            getListKonseliArsip()
        }
    }, [])
    if (props.title == 'conference') {

        return (
            <div className='left-menu'>
                <ListHeader />
                <div className='conselis'>
                    <ul>
                        {conferences.map((conference, index) => {
                            return (
                                <li onClick={() => {
                                    setActive(conference)
                                    if (props.onListSelected) {
                                        props.onListSelected(conference)
                                    }
                                }}
                                >
                                    <ConferenceCard isActive={isActive == conference} conference={conference} onClick={()=>props.onConferenceSelected(conference)}/>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
    if (props.title == 'conselor')
        return (
            <div className='left-menu'>
                <ListHeader onSearchChange={onSearchChange} style={props.ListStyle}/>
                <div className='conselis'>
                    <ul>
                        {conselorList.map((conseli, index) => {
                            return (
                                <li onClick={() => {
                                    setActive(conseli)
                                    if (props.onListSelected) {
                                        props.onListSelected(conseli)
                                    }
                                }}
                                >
                                    <ConseliCard conseli={conseli} isActive={conseli != isActive} name={conseli.nama_konselor} problem={conseli.profesi_konselor} />
                                </li>
                            )
                        })}
                        <li style={{marginBottom: conselorList.length*24}}>

                        </li>
                    </ul>
                </div>
            </div>
        )
    if(props.title == 'arsip' && props.konseli_id){
        return(
            <div className='left-menu'>
                <ListHeader onSearchChange={onSearchChange} />
                <div className='conselis'>
                    <ul>
                        {
                            loading ?
                                <SkeletonTheme >
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                </SkeletonTheme> :
                                conselorList.map((conseli, index) => {
                                    return (
                                        <li onClick={() => {
                                            setActive(conseli.konselor)
                                            // props.setActiveConseli(conseli)
                                            props.setActiveArsip(conseli)
                                        }}
                                        >
                                            <ConseliCard conseli={conseli} isActive={conseli != isActive} name={conseli.konselor.nama_konselor} problem={" "} getLatestChat />
                                        </li>
                                    )
                                })
                        }
                    </ul>
                </div>
            </div>
        )
    }
    if (props.title == 'conseli' || props.title == 'arsip')
        return (
            <div className='left-menu'>
                <ListHeader onSearchChange={onSearchChange} />
                <div className='conselis'>
                    <ul>
                        {
                            loading ?
                                <SkeletonTheme >
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                    <p>
                                        <Skeleton count={1} width={280} height={50} />
                                    </p>
                                </SkeletonTheme> :
                                conselorList.map((conseli, index) => {
                                    return (
                                        <li onClick={() => {
                                            setActive(conseli)
                                            props.setActiveConseli(conseli)
                                        }}
                                        >
                                            <ConseliCard showStatus={true} conseli={conseli} isActive={conseli != isActive} name={conseli.konseli.nama_konseli} problem={conseli.chats.length > 0 ? atob(conseli.chats[0].chat_konseling) : '   '} getLatestChat />
                                        </li>
                                    )
                                })
                        }


                    </ul>
                </div>
            </div>
        )
}
List.defaultProps = {
    except: null
}
const mapStateToProps = ({ activeConseli, konselingList, conference }) => ({
    activeConseli, konselingList, conference
})

export default connect(mapStateToProps, mapDispatchToProps)(List)