import React, { useState, useEffect } from 'react'
import './Dashboard.css'
import { mapDispatchToProps } from '../../store/actions'
import { connect } from 'react-redux'
import MainProfileCard from '../../components/Conselor/MainProfileCard'
import StatsCard from '../../components/Conselor/StatsCard'
import ScheduleCard from '../../components/Conselor/ScheduleCard'
import KonselingCount from '../../components/Conselor/KonselingCount'
import { getCountKonseling, getJadwalKonselingAktif, getStatistic } from '../../methods/api/konseling'
import { getUserFromToken } from '../../methods/api/auth/auth'
function PageHome(props) {
    const [konselingAktif, setKonselingAktif] = useState(props.konselingAktif);
    const [konselingSelesai, setKonselingSelesai] = useState(props.konselingSelesai);
    const [jadwal, setJadwal] = useState(props.jadwals)
    const [isLoading, setLoading] = useState(false)
    const [statistic, setStatistic] = useState(null)
    useEffect(() => {
        async function getKonselingCount() {
            const konselor_id = getUserFromToken().info.id
            const response = await getStatistic("?konselor_id="+konselor_id)
            setStatistic(response.data)
            // const response = await getCountKonseling("aktif", konselor_id)
            // const response2 = await getCountKonseling("selesai", konselor_id)
            const j = await getJadwalKonselingAktif(konselor_id)
            setJadwal(j.rows)
            // setKonselingAktif(response.data)
            // setKonselingSelesai(response2.data)
            setLoading(false)
            console.log('j', j.rows)
        }
        getKonselingCount()
    }, [])

    if (props.activeMenu != 'Home') {
        return (
            <div style={{ display: 'none' }} />
        )
    }
    return (
        <div className='content' style={{ overflowY: 'scroll' }}>
            <div className='section-wraper home' style={{ paddingBottom: 400 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <MainProfileCard />
                    <div className='conselor-stats'>
                        <div style={{ width: 160 }}>
                            <KonselingCount title='Konseling aktif' fontSize={72} count={statistic?statistic.aktif.total:0} color={'#F1C40F'} />
                        </div>
                        <div className='conselor-stats-child'>
                            <KonselingCount title='Baru' count={statistic?statistic.aktif.baru:0} small color='#0EBBEE' />
                            <KonselingCount title='Referral' count={statistic?statistic.aktif.referral:0} small color='#BF0CB8' />
                        </div>
                    </div>
                    <div className='conselor-stats'>
                        <div style={{ width: 160 }}>
                            <KonselingCount title='Konseling Selesai' fontSize={72} count={statistic?statistic.selesai.total:0} color={'#F1C40F'} />
                        </div>
                        <div className='conselor-stats-child'>
                            <KonselingCount title='Case Closed' count={statistic?statistic.selesai.cc:0} small color='#82DE3A' />
                            <KonselingCount title='Referred' count={statistic?statistic.selesai.r:0} small color='#BF0CB8' />
                            <KonselingCount title='Expired' count={statistic?statistic.selesai.e:0} small color='#EF3A79' />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ScheduleCard jadwal={jadwal} />
                    <div className='case-conference-count hover'>
                        Case conference
                        <div style={{ color: '#F1C40F', fontSize: 54, fontWeight: 'bold' }}>
                            {statistic?statistic.count.total_conference:0}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ activeMenu, jadwals, konselingAktif, konselingSelesai }) => ({ activeMenu, jadwals, konselingAktif, konselingSelesai })
export default connect(mapStateToProps, mapDispatchToProps)(PageHome)