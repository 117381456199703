import React, {useEffect, useState} from 'react'
import './Dashboard.css'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../store/actions'
import List from '../../components/List'
import ConseliInformation from '../../components/ConseliInformation'
import ConferenceInformation from '../../components/ConferenceInformation'
import {showConferenceById} from "../../methods/api/conference";
const queryString = require('query-string')
function PageCaseConference(props) {
    // const [conference, setConference] = useState(null)
    const [chatData, setChatData] = useState(null)
    const [isChat, setChat] = useState(null)
    const onConferenceSelected = (conference) => {
        props.setConference(conference)
        setChat(true)
    };
    const check = async () => {
        const parsed = queryString.parse(window.location.search)
        const response = await showConferenceById(parsed.case)
        if(response.success){
            props.setConference(response.rows)
            setChat(true)
        }
    }
    useEffect(()=> {
        const parsed = queryString.parse(window.location.search)
        if(parsed.redirect && parsed.case){
            check()
        }
    }, [])
    useEffect(()=>{
        if(props.conference == null){
            setChat(false)
        }
    }, [props.conference])
    if (props.activeMenu !== 'Case Conference') {
        return <div></div>
    }

    return (
        <div id='content'>
            {!isChat && <List title='conference' onListSelected={props.setConference} onConferenceSelected={onConferenceSelected}/>}
            {
                props.conference == null ?
                <ConferenceInformation isChat={isChat}/>:<ConferenceInformation conference={props.conference} isChat={isChat} setChat={setChat} onConferenceSelected={onConferenceSelected}/>
            }

        </div>
    )
}


const mapStateToProps = ({ activeMenu, conference }) => ({ activeMenu, conference })
export default connect(mapStateToProps, mapDispatchToProps)(PageCaseConference)