import { headerTemplate, host } from "../config"

const createAgreement = async (konseling_id, konseli_id) => {
    console.log('create agreement', konseling_id, konseli_id)
    const request = await fetch(host + "/api/conference/createagreement", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            konseling_id: konseling_id,
            konseli_id: konseli_id
        })
    })
    const response = await request.json()
    return response
}

const declineConferenceAgreement = async (konseling_id) => {
    const request = await fetch(host + "/api/conference/declineagreement", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            konseling_id: konseling_id,
        })
    })
    const response = await request.json()
    return response
}

const createConference = async (judul_case_conference, konselor_id, konseling_id, konselors) => {
    console.log('create conference', judul_case_conference, konselor_id, konseling_id, konselors)
    const request = await fetch(host + "/api/conference", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            judul_case_conference: judul_case_conference,
            konseling_id: konseling_id,
            konselor_id: konselor_id,
            konselors: konselors
        })
    })
    const response = await request.json()
    return response
}

const showConferences =  async (konselor_id) => {
    const request = await  fetch(host+'/api/conference?konselor_id='+konselor_id, {
        "method": "GET",
        "headers": headerTemplate()
    })

    const response = await request.json()
    return response
}

const showConferenceById = async (caseConferenceId) => {
    const request = await fetch(host+'/api/conference?case_conference_id='+caseConferenceId, {
        "method": "GET",
        "headers": headerTemplate()
    })
    const response = await request.json()
    return response
}

const createChat = async (body) => {
    const request = await fetch(host+'/api/chatconference', {
        "method": "POST",
        "headers": headerTemplate(),
        body: JSON.stringify(body)
    })
    const response = await request.json()
    return response
}

const showConferencechat = async (case_conference_id) => {
    const request = await fetch(host+'/api/chatconference?case_conference_id='+case_conference_id, {
        "method": "GET",
        "headers": headerTemplate()
    })
    const response = await request.json()
    return response
}

const showConferenceByKonseling = async (konseling_id) => {
    const request = await fetch(host +"/api/conference?konseling_id="+konseling_id, {
        "method": "GET",
        "headers": headerTemplate()
    })
    const response = await request.json()
    return response
}

const createDetail = async (membersids, case_conference_id) =>{
    const request = await fetch(host +"/api/detailconference", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            memberids: membersids,
            case_conference_id: case_conference_id
        })
    })
    const response = await request.json()
    return response
}

export {
    createAgreement,
    createConference,
    showConferences,
    showConferenceById,
    createChat,
    showConferencechat,
    showConferenceByKonseling,
    createDetail,
    declineConferenceAgreement
}