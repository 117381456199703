import React, {useEffect, useState} from 'react'
import './style.css'
import YellowBar from '../../components/Landing/YellowBar'

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import {getKonselors} from "../../methods/api/auth/konselor";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {host} from "../../methods/config";

export default function Konselor() {
    const [konselors, setKonselors] = useState([])
    const check = async () => {
        const response = await getKonselors()
        if(response.success){
            setKonselors(response.rows)
        }
    }
    const settings = {
        // dots: true,
        infinite: konselors.length > 3,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(()=>{
        check()
    }, [])
    return (
        <div className='landing-section container-section' style={{ backgroundColor: '#192B45' }}>
        {/*<div>*/}

            <div className='section-title' style={{ color: 'white' }}>
                Daftar Konselor
            </div>
            <YellowBar />
            <div className='conselors-section'>
                <div>
                    <Slider className={''} {...settings}>
                        {konselors.map((konselor, index) => {
                            return (
                                // <div>{index}</div>
                                <div style={{padding: 0}}>
                                    <div className='conselors-list-card'>
                                        <div className='conselors-list-avatar'>
                                            <img src={host+"/avatars/"+konselor.user.avatar}/>
                                        </div>
                                        <div className='conselors-list-name'>
                                            {konselor.nama_konselor}
                                        </div>
                                        <div className='conselors-list-profesion'>
                                            {konselor.profesi_konselor}
                                        </div>
                                    </div>
                                </div>

                            )
                        })}
                    </Slider>
                </div>
                {/*<div className='slide-button'>*/}
                {/*    <BsChevronLeft />*/}
                {/*</div>*/}

                {/*<div className='slide-button'>*/}
                {/*    <BsChevronRight />*/}
                {/*</div>*/}
            </div>
        </div>

    )
}

const datas = [
    {
        nama_konselor: 'JT. Lobby Loekmono',
        profesi_konselor: 'Dosen FKIP BK'
    },
    {
        nama_konselor: 'Onesimus Dani',
        profesi_konselor: 'Pdt. UKSW'
    },
    {
        nama_konselor: 'Maria Nugraheni',
        profesi_konselor: 'Dosen Psikologi'
    },
    // {
    //     nama_konselor: 'Brooklyn Nguyen',
    //     profesi_konselor: 'Dosen S1 Psikologi dan konselor di RS. Waras'
    // },

]