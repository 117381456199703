import { headerTemplate, host } from "../config"

const getRecords = async (konseling_id) => {
    const request = await fetch(host + `/api/rekamkonseling?konseling_id=` + konseling_id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const updateRecords = async (record_id, judul_konseling, isi_rekam_konseling) => {
    const body = JSON.stringify({
        id: record_id,
        judul_konseling: judul_konseling,
        isi_rekam_konseling: isi_rekam_konseling
    })
    console.log(body)
    const request = await fetch(host + "/api/rekamkonseling", {
        "method": "POST",
        "headers": headerTemplate(),
        body
    })
    // const te = await request.text()
    // console.log(te)
    const response = await request.json()

    return response
}

const getJadwalKonselingAktif = async (konselor_id) => {
    const request = await fetch(host + "/api/konseling?konselor_id=" + konselor_id + "&active", {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const createKonseling = async (data) => {
    const request = await fetch(host + "/api/konseling", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify(data)
    })
    const response = await request.json()

    return response
}

const getCountKonseling = async (status, konselor_id) => {
    console.log(host + "/api/konseling/count?status=" + status + "&konselor_id=" + konselor_id)
    const request = await fetch(host + "/api/konseling/count?status_aktif=" + status + "&konselor_id=" + konselor_id, {
        "method": "GET",
        "headers": headerTemplate(),
    });

    const response = await request.json()

    return response
}

const konselingCheck = async (id) => {
    console.log('check konseling', "/api/konseling?konseli_id=" + id)
    const request = await fetch(host + "/api/konseling?konseli_id=" + id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const showChats = async (konseling_id) => {
    const request = await fetch(host + "/api/chatkonseling?konseling_id" + konseling_id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const createMessage = async (body) => {
    const request = await fetch(host + "/api/chatkonseling", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify(body)
    })
    const response = await request.json()
    console.log('response')
    console.log(response)
    return response
}

const showMessages = async (konseling_id) => {
    const request = await fetch(host + "/api/chatkonseling?konseling_id=" + konseling_id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const getKonselings = async (data) => {
    const request = await fetch(host + "/api/konseling?" + data, {
        "method": "GET",
        "headers": headerTemplate(),
    })

    const response = await request.json()
    return response
}


const resolveJadwalKonseling = async (id) => {
    const request = await fetch(host + "/api/jadwal/" + id, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const showKonseling = async (konseling_id) => {
    const request = await fetch(host + "/api/konseling/" + konseling_id)
    const response = await request.json()
    return response
}

const endKonseling = async (konseling_id) => {
    const request = await fetch(host + "/api/konseling/end", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({id: konseling_id})
    })
    const response = await request.json()
    return response
}

const getStatistic = async (params) => {
    let endpoint = "/api/konseling/statistic?"+params
    const request = await fetch(host + endpoint, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response    
}
export {
    getStatistic,
    createKonseling,
    konselingCheck,
    showChats,
    createMessage,
    showMessages,
    getKonselings,
    getCountKonseling,
    resolveJadwalKonseling,
    getJadwalKonselingAktif,
    getRecords,
    updateRecords,
    showKonseling,
    endKonseling
}