import React, { useState, useEffect } from 'react'
import './sessions.css'
import Button from '../Buttons'
import { mapDispatchToProps } from '../../store/actions'
import { connect } from 'react-redux'
import ScheduleSelector from '../Selector/ScheduleSelector'
import { konselingCheck, resolveJadwalKonseling } from '../../methods/api/konseling'
import { showKonselor } from '../../methods/api/konselor'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getUserFromToken } from '../../methods/api/auth/auth'
function Sessions(props) {
    const [sessions, setSessions] = useState(null)
    const [conselor, setConselor] = useState({})
    const [isLoading, setLoading] = useState(true)
    const [jadwal, setJadwal] = useState(null)
    useEffect(() => {
        async function check() {
            const user = getUserFromToken()
            console.log("/api/konseling?konseli_id=" + user.info.id);
            const response = await konselingCheck(user.info.id)
            console.log(response)
            if (response.success === true) {
                console.log('konselingcheck', response)
                setSessions(response.rows)
            }
        }
        check()
    }, [])
    useEffect(() => {
        async function checkKonselor() {
            console.log(sessions[sessions.length-1].konselor_id)
            const response = await showKonselor(sessions[sessions.length-1].konselor_id)
            const j = await resolveJadwalKonseling(sessions[sessions.length-1].jadwal_konselor_id)
            setJadwal(sessions[sessions.length-1].jadwal)
            console.log(response, j)
            setConselor(sessions[sessions.length-1].konselor)
            setLoading(false)
            // props.onFinishedLoading()
        }
        if (sessions && sessions.length > 0) {
            checkKonselor()
        } else {
            setLoading(false)
            // props.onFinishedLoading()
        }
        setLoading(false)
        props.onFinishedLoading()
    }, [sessions])

    if (sessions && sessions.length > 0 && jadwal)
        return (
            <div className='sessions-container'>
                <div className='session-ready'>
                    <h3>Kamu sedang terdaftar konseling dengan</h3>
                    <h3 style={{ padding: 0, margin: 0 }}>{sessions[sessions.length-1]?.konselor?.nama_konselor}</h3>
                    <h3 style={{ marginTop: 4, padding: 0 }}>{jadwal?.hari + ", " + jadwal?.jam_mulai + ".00-" + jadwal?.jam_akhir + ".00"}</h3>

                    <h4>Kamu bisa konseling sesuai jadwal yang telah dipilih dan mengirim pesan kapanpun melalui Ruang Konseling</h4>
                    <div style={{width: 180, marginLeft: 'auto', marginRight: 'auto'}}>
                        {jadwal.available == 'candidate' ? <Button title={'Ganti Jadwal'} onClick={()=>{props.setActiveMenu('session enrollment')}}/>:<div/>}
                    </div>
                    <span id='from' style={{ marginTop: 24, marginLeft: 600 }}></span>
                </div>
            </div>
        )
    if (sessions && sessions.length == 0) {
        return (
            <div className='sessions-container'>
                <div className='no-session'>
                    <h1>KAMU SEDANG TIDAK ADA SESI KONSELING</h1>
                    <h2>Bagikan kecemasan dan masalahmu dengan konselor yang kamu percaya</h2>
                    <div style={{ width: 120, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button title='Daftar Sesi' onClick={() => {
                            props.setActiveMenu('session enrollment')
                            props.onSessionCreated()
                        }} />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='sessions-container'>
            <div className='no-session'>
                <Skeleton height={25} width={250} count={2} />

                <div style={{ width: 120, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Skeleton height={50} width={120} />
                </div>
                <Skeleton height={25} width={250} count={2} />
            </div>
        </div>
    )
}

Sessions.defaultProps = {
    onFinishedLoading: () => { }
}

const mapStateToProps = ({ activeMenu, conselingSession, sessions }) => ({ activeMenu, conselingSession, sessions })
export default connect(mapStateToProps, mapDispatchToProps)(Sessions)