import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../../store/actions";
import '../Dashboard.css'
import Avatar from "../../../components/Avatar/Avatar";
import Button from "../../../components/Buttons";
import {createKonselor, editKonselor, getJadwalKonselor} from "../../../methods/api/konselor";
import {AiOutlinePlus} from 'react-icons/ai'
import DatePicker from 'react-datepicker'
import StackNotification from "../../../components/StackNotification";
import { host } from '../../../methods/config';
function EditKonselor(props){
    const [base64Image, setBase64Image] = useState(null)
    const [pickingImage, setPickingImage] = useState(null)
    const [nama, setNama] = useState("")
    const [profesi, setProfesi] = useState("")
    const [noHp, setNoHp] = useState("")
    const [email, setEmail] = useState("")
    const [jadwals,setJadwals] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [errors, setErrors] = useState([])
    const getData = async () => {
        const response = await getJadwalKonselor(props.activeKonselor.id)
        console.log(response)
        if(response.success){
            setJadwals(response.data)
        }
    }
    useEffect(()=> {
        if(props.activeKonselor){
            setNama(props.activeKonselor.nama_konselor)
            setProfesi(props.activeKonselor.profesi_konselor)
            setEmail(props.activeKonselor.email_konselor)
            setNoHp(props.activeKonselor.no_hp_konselor)
            setPickingImage(host+'/avatars/'+props.activeKonselor.user.avatar)
            // setJadwals(props.activeKonselor.jadwal_konselor)
            getData()
        }else{
            setNama("")
            setProfesi("")
            setEmail("")
            setNoHp("")
            setJadwals([])
            setBase64Image(null)
            setPickingImage(host+"/avatars/default.jpg")
        }
    },[props])
    const submit = async () => {
        if(props.activeKonselor == null){
            await registerKonselor()
            return
        }
        const konselor = props.activeKonselor
        const response = await editKonselor(konselor.id, nama, profesi, email, noHp, "aktif",jadwals, base64Image)
        if(response.success){
            getData()
            setBase64Image(null)
            setPickingImage(null)
            props.setActiveMenu("Kelola Konselor")
        }
    }
    const jadwalChangeHandler = (data) => {
        for(let i=0; i<jadwals.length; i++){
            let jad = jadwals[i];
            if(jad.hari===data.hari && jad.jam_mulai === data.jam_mulai && jad.jam_akhir === data.jam_akhir){
                return false;
            }
        }
        console.log(data)
        if(data.id !== "new"){
            const j = jadwals
            const targetIndex = jadwals.findIndex((j)=>j.id ==data.id)
            console.log("target ",targetIndex)
            let jadwal = jadwals[targetIndex]
            j[targetIndex] = {...data}
            setJadwals(j)
        }else{
            const j = jadwals
            data.id = data.id+jadwals.length
            j.push(data)
            setJadwals([...j])
        }
        setShowForm(false)
        console.log(jadwals)
        return true
    }

    const registerKonselor = async () => {
        setErrors([])
        const response = await createKonselor(nama, email, profesi, noHp, jadwals,base64Image)
        if(response.success){
            props.setActiveMenu("Kelola Konselor")
        }else{
            const keys = Object.keys(response.message)
            const values = keys.map((item, index) => {
                let error = response.message[item].toString()
                return error.replace("name", "nama")
            })
            setErrors(values)
        }
    }

    const handleImagePick = () => {
        document.getElementById('image-button').click();
        document.getElementById('image-button').onchange = (event) => {
            // setPickingImage(document.getElementById('image-button').value)
            setPickingImage(URL.createObjectURL(event.target.files[0]))
            getBase64(event.target.files[0])
        }
    }

    const getBase64 = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            setBase64Image(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    if(props.activeMenu != "edit-konselor" ){
        return(
            <div/>
        )
    }else{

    }
    return(
        <div id={"content"} style={{flexDirection: 'row', overflowY: 'scroll'}}>
            <div className={'conselor-edit-container'}>
                    <div style={{width: 54, height: 54}}>
                    <img src={pickingImage} placeholder={host+"/avatars/default.jpg"} className='data-form-avatar' style={{width: '100%', height: '100%'}}>
                    </img>
                    </div>
                    
                    {/* <div>
                        <Avatar src={pickingImage} style={{width: 92, height: 92}}/>
                    </div> */}
                    <div style={{width: 72, height: 24, marginTop: 18}}>
                        <input id='image-button' type='file' hidden></input>
                        <Button onClick={handleImagePick} width={72} height={24} fontSize={8} title={'Unggah Foto'}/>
                    </div>
                    <div style={{display: 'flex', width: '98%'}}>
                        <div style={{borderRightWidth: 1, display: 'flex', flex: 1, flexDirection: 'column'}}>
                            Informasi Personal
                            <div>
                                Nama <br/>
                                <input value={nama} onChange={(e)=>setNama(e.target.value)}/>
                            </div>
                            <div>
                                Profesi <br/>
                                <input value={profesi} onChange={(e)=>setProfesi(e.target.value)}/>
                            </div>
                            <div>
                                No. HP <br/>
                                <input value={noHp} onChange={(e)=>setNoHp(e.target.value)}/>
                            </div>
                            <div>
                                Email <br/>
                                <input value={email} onChange={(e)=>setEmail(e.target.value)}/>
                            </div>
                        </div>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                            Jadwal Online
                            {jadwals.map((item, index) => {
                                return(
                                    <Jadwal id={item.id} hari={item.hari} mulai={item.jam_mulai} akhir={item.jam_akhir} onJadwalChange={data=>jadwalChangeHandler(data)}/>
                                )
                            })}

                            {showForm && <Jadwal id={'new'} onJadwalChange={data=>jadwalChangeHandler(data)} hari={false}/>}

                        </div>
                    </div>
                    <div style={{width: 100, height: 24}}>
                        <Button height={32} title={"Simpan"} onClick={submit}></Button>
                    </div>

                    <div className={'fab-add'} onClick={()=>setShowForm(true)}>
                        <AiOutlinePlus color={'white'}/>
                    </div>
            </div>
            {errors.length > 0 && <StackNotification data={errors}/>}
        </div>
    )
}

function Jadwal(props){
    const [hari, setHari] = useState(false)
    const [mulai, setMulai] = useState(null)
    const [akhir, setAkhir] = useState(null)
    useEffect(()=> {
        if(props.id !== "new"){
            const d = new Date()
            d.setHours(parseInt(props.mulai))
            d.setMinutes(0)
            setHari(props.hari)
            setMulai(d)
            setAkhir(props.akhir)
        }
        console.log(props)
    }, [])
    useEffect(()=>{
        if(mulai){
            const d = new Date()
            d.setHours(mulai.getHours()+1)
            d.setMinutes(0)
            setAkhir(d)
        }else{
            setAkhir(null)
        }
    }, [mulai])
    useEffect(()=> {
        if(props.id!=="new"){
            handleJadwalChange()
        }
    }, [hari, mulai, akhir])

    const handleJadwalChange = () => {
        console.log('0')
        if(mulai  && akhir && hari){
            console.log('k')
            if(!props.onJadwalChange({
                id: props.id,
                hari: hari,
                jam_mulai: mulai.getHours()+"",
                jam_akhir: (mulai.getHours()+1)+""
            }))return

            if(props.id === "new"){
                setHari("Senin")
                setMulai(null)
                setAkhir(null)
            }
        }
    }

    if(props.id=='new'){
        return(
            <div className={'jadwal-item'}>
                <div className={'jadwal-form'}>
                    Hari
                    {/*<input />*/}
                    <select value={hari} onChange={(e)=>setHari(e.target.value)}>
                        <option value={false}>Pilih Hari</option>
                        <option value={"Senin"}>Senin</option>
                        <option value={"Selasa"}>Selasa</option>
                        <option value={"Rabu"}>Rabu</option>
                        <option value={"Kamis"}>Kamis</option>
                        <option value={"Jumat"}>Jumat</option>
                        <option value={"Sabtu"}>Sabtu</option>
                        <option value={"Minggu"}>Minggu</option>
                    </select>
                </div>
                <div className={'jadwal-form'}>
                    Jam Awal
                    {/*<input value={mulai} onChange={(e)=>{*/}
                    {/*    let v = e.target.value+""*/}
                    {/*    v = v.replace("-", "")*/}
                    {/*    if(parseInt(v)>=0 && parseInt(e.target.value)<24 || v==""){*/}
                    {/*        setMulai(v)*/}
                    {/*    }*/}
                    {/*}} type={'number'} min={0}/>*/}
                    <DatePicker
                        selected={mulai}
                        onChange={(date) =>{
                            const d = date
                            setMulai(date)
                            setAkhir(new Date().setHours(d.getHours()+1))
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption={"Jam mulai"}
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
                    />
                </div>
                <div className={'jadwal-form'}>
                    Jam Akhir
                    {/*<input value={akhir} onChange={(e)=>setAkhir(e.target.value)} type={'number'} disabled={true}/>*/}
                    <DatePicker
                        selected={akhir}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption={"Jam mulai"}
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
                    />
                </div>
                <div className={'jadwal-add'}>
                    {/*<AiOutlinePlus/>*/}
                    <Button title={"Simpan"} fontSize={12} width={100} height={20} paddingLeft={26} onClick={handleJadwalChange}/>
                    <img />
                </div>
            </div>
        )
    }
    return(
        <div className={'jadwal-item'}>
            <div className={'jadwal-form'}>
                Hari
                {/*<input />*/}
                <select value={hari} onChange={(e)=>setHari(e.target.value)}>
                    <option value={false}>Pilih Hari</option>
                    <option value={"Senin"}>Senin</option>
                    <option value={"Selasa"}>Selasa</option>
                    <option value={"Rabu"}>Rabu</option>
                    <option value={"Kamis"}>Kamis</option>
                    <option value={"Jumat"}>Jumat</option>
                    <option value={"Sabtu"}>Sabtu</option>
                    <option value={"Minggu"}>Minggu</option>
                </select>
            </div>
            <div className={'jadwal-form'}>
                Jam Awal
                {/*<input value={mulai} onChange={(e)=>{*/}
                {/*    let v = e.target.value+""*/}
                {/*    v = v.replace("-", "")*/}
                {/*    if(parseInt(v)>=0 && parseInt(e.target.value)<24 || v==""){*/}
                {/*        setMulai(v)*/}
                {/*    }*/}
                {/*}} type={'number'} min={0}/>*/}
                <DatePicker
                    selected={mulai}
                    onChange={(date) =>{
                        const d = date
                        setMulai(date)
                        // setAkhir(d.setHours(d.getHours()+1))
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption={"Jam mulai"}
                    timeFormat={"HH:mm"}
                    dateFormat={"HH:mm"}
                />
            </div>
            <div className={'jadwal-form'}>
                Jam Akhir
                <DatePicker
                    selected={akhir}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption={"Jam mulai"}
                    timeFormat={"HH:mm"}
                    dateFormat={"HH:mm"}
                />
            </div>
            <div className={'jadwal-add'} style={{opacity: 0}}>
                <Button title={"Simpan"} fontSize={12} width={54} height={20} onClick={()=>{}}/>
            </div>
        </div>
    )
}


const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(EditKonselor)
