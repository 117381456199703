import React from 'react'
import './step.css'
export default function StepContent(props) {
    if (!props.isActive) {
        return null
    }
    return (
        <div className='step-content'>
            <div className='title'>{props.title}</div>
            <div className='description'>{props.description}</div>
            {props.children}
        </div>
    )
}