import React, { useEffect, useState } from 'react'
import Avatar from './../Avatar/Avatar'
import './conselorcards.css'
import { getJadwalOnline } from '../../methods/api/jadwal'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../store/actions'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { BsPencil} from 'react-icons/bs'
import Button from "../Buttons";
import { getUserFromToken } from '../../methods/api/auth/auth'
function MainProfileCard(props) {
    const user = props.konselor?{
        ...props.konselor.user,
        info: {
            ...props.konselor
        }
    }:getUserFromToken()
    const [jadwals, setJadwals] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [isEdit, setEdit] = useState(false)
    useEffect(() => {
        async function getJadwal() {
            console.log('get jadwal')
            const j = await getJadwalOnline(user.info.id)
            console.log(j)
            setJadwals(j.data)
            setLoading(false)
        }
        setLoading(true)
        getJadwal()
    }, [props.konselor])
    const toDate = (data) => {
        let d = ""
        d = data.hari
        if (parseInt(data.jam_mulai) < 10) {
            d += ", 0" + data.jam_mulai + ".00"
        }
        else d += ", " + data.jam_mulai + ".00"
        if (parseInt(data.jam_akhir) < 10) {
            d += ",0" + data.jam_akhir + ".00"
        }
        else d += "-" + data.jam_akhir + ".00"

        return d
    }
    if(isEdit){
        return(
            <div className={'main-profile-card edit'}>
                <Button title={'Simpan'} onClick={()=>setEdit(false)}/>
            </div>
        )
    }
    return (
        <SkeletonTheme >
            <div className='main-profile-card' style={isLoading ? { boxShadow: 'none' } : {}}>

                {isLoading ? <Skeleton height={100} width={100} circle={true} /> : <Avatar style={{ minWidth: 100, minHeight: 100 }} img={user.avatar}/>}
                <div className='bio'>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {isLoading ? <Skeleton height={24} width={150} /> : user.info.nama_konselor}
                        <div style={{float: 'right', cursor: 'pointer'}}>
                            {!isLoading && <BsPencil color={'#F1C40F'} onClick={()=>props.setActiveMenu('edit-konselor')}/>}
                        </div>
                    </div>

                    <table>
                        <tr>
                            <td>
                                {isLoading ? <Skeleton height={24} width={150} /> : user.info.profesi_konselor}
                            </td>
                            <td>
                                {isLoading ? <Skeleton height={24} width={150} /> : user.email}
                            </td>

                        </tr>
                        <tr>
                            <td>
                                {isLoading ? <Skeleton height={24} width={150} /> : 'No hp: ' + user.info.no_hp_konselor}
                            </td>
                            <td>
                                {isLoading ? <Skeleton height={24} width={150} /> : <div>Jadwal online:</div>}
                                {
                                    isLoading ?

                                        <Skeleton height={24} width={150} />
                                        :
                                        jadwals.map((item, index) => {
                                            return (
                                                <div key={index}>{toDate(item)}</div>
                                            )
                                        })
                                }
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </SkeletonTheme>
    )

}

const mapStateToProps = ({ jadwalOnline }) => ({
    jadwalOnline
})

export default connect(mapStateToProps, mapDispatchToProps)(MainProfileCard)