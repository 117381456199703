import { headerTemplate, host } from "../config"



const getLatestChat = async (konseling_id) => {
    const request = await fetch(host + "/api/chatkonseling?konseling_id=" + konseling_id + "&last=", {
        "method": "GET",
        "headers": headerTemplate()
    })
    const response = await request.json()
    return response
}

export {
    getLatestChat
}