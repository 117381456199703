import React, { useEffect, useState } from 'react'
import {connect} from "react-redux";
import { getStatistic } from '../../methods/api/konseling';
import {mapDispatchToProps} from "../../store/actions";
import './style.css'
function AdminHome(props) {

    const [statistic, setStatistic] = useState(null)

    useEffect(()=>{
        getData()
    }, [])

    const getData = async () => {
        const response = await getStatistic()
        if(response.success){
            setStatistic(response.data)
        }
    }
    
    if(props.activeMenu != "Home"){
        return(
            <div/>
        )
    }
    return(
        <div id={"content"} style={{flexDirection: 'row', overflowY: 'scroll'}}>
            <div style={{display: 'flex', flexDirection: 'column',width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className={'stat-single-count'}>
                        <div className={'stat-title'}>Konselor</div>
                        <div className={'stat-count'}>{statistic?statistic.count.total_konseling:0}</div>
                    </div>
                    <div className={'stat-single-count'}>
                        <div className={'stat-title'}>Case Conference</div>
                        <div className={'stat-count'}>{statistic?statistic.count.total_conference:0}</div>
                    </div>
                </div>
                <div className={'stat-multi-count'}>
                    <div className={'stat-single-count'} style={{boxShadow: 'none'}}>
                        <div className={'stat-title'}>Konseling Aktif</div>
                        <div className={'stat-count'}>{statistic?statistic.aktif.total:0}</div>
                    </div>
                    <div style={{height: '100%', width: 1, backgroundColor: 'black', opacity: .2}}/>
                    <div className={'stat-single-count'} style={{boxShadow: 'none'}}>
                        <div className={'stat-title'}>Baru</div>
                        <div className={'stat-count'}>{statistic?statistic.aktif.baru:0}</div>
                    </div>
                    <div className={'stat-single-count'} style={{boxShadow: 'none'}}>
                        <div className={'stat-title'}>Referral</div>
                        <div className={'stat-count'}>{statistic?statistic.aktif.referral:0}</div>
                    </div>
                </div>
                <div className={'stat-multi-count'}>
                    <div className={'stat-single-count'} style={{boxShadow: 'none'}}>
                        <div className={'stat-title'}>Konseling Selesai</div>
                        <div className={'stat-count'}>{statistic?statistic.selesai.total:0}</div>
                    </div>
                    <div style={{height: '100%', width: 1, backgroundColor: 'black', opacity: .2}}/>
                    <div className={'stat-single-count'} style={{boxShadow: 'none'}}>
                        <div className={'stat-title'}>Case Closed</div>
                        <div className={'stat-count'}>{statistic?statistic.selesai.cc:0}</div>
                    </div>
                    <div className={'stat-single-count'} style={{boxShadow: 'none'}}>
                        <div className={'stat-title'}>Referred</div>
                        <div className={'stat-count'}>{statistic?statistic.selesai.r:0}</div>
                    </div>
                    <div className={'stat-single-count'} style={{boxShadow: 'none'}}>
                        <div className={'stat-title'}>Expired</div>
                        <div className={'stat-count'}>{statistic?statistic.selesai.e:0}</div>
                    </div>
                </div>
            </div>
            {/* <div style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'stat-chart'}>
                    <div className={'stat-chart-title'}>
                        Konseling
                    </div>
                </div>
                <div className={'stat-chart'}>
                    <div className={'stat-chart-title'}>
                        Konseling
                    </div>
                </div>
            </div> */}
        </div>
    )
}

const mapStateToProps = ({ activeMenu }) => ({ activeMenu })
export default connect(mapStateToProps, mapDispatchToProps)(AdminHome)
