import { headerTemplate, host } from "../config"
const createPengumuman = async (judul, isi, source_gambar="default.jpg", status="aktif") => {
    const request = await fetch(host+"/api/pengumuman", {
        "method": "POST",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            judul, isi, source_gambar, status
        })
    })
    const response = await request.json()
    return response
}

const updatePengumuman = async (id, judul, isi, source_gambar="default.jpg", status="aktif") => {
    const request = await fetch(host+"/api/pengumuman/"+id, {
        "method": "PUT",
        "headers": headerTemplate(),
        "body": JSON.stringify({
            judul, isi, source_gambar, status
        })
    })
    const response = await request.json()
    return response
}

const getLatestPengumuman = async () => {
    const request = await fetch(host+"/api/pengumuman?latest", {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

const getPengumumans = async (id="") => {
    console.log(host+(id==""?+"/api/pengumuman":("/api/pengumuman/"+id)))
    let endpoint = host+"/api/pengumuman/"
    if(id!==""){
        endpoint+=id
    }
    const request = await fetch(endpoint, {
        "method": "GET",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}
const destroyPengumumans = async (id) => {
    const request = await fetch(host+"/api/pengumuman/"+id, {
        "method": "DELETE",
        "headers": headerTemplate(),
    })
    const response = await request.json()
    return response
}

export {
    createPengumuman,
    getPengumumans,
    updatePengumuman,
    destroyPengumumans,
    getLatestPengumuman
}