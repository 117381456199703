import React, {useEffect, useState} from 'react'
import './style.css'
import NotificationCard from "./NotificationCard";
import { useSnackbar } from 'notistack';
export default function StackNotification(props){
    const [data, setData] = useState(props.data)
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    useEffect(()=>{
        console.log('notification push')
        console.log(props.data)
        const d = props.data.map((item, index) => {
            enqueueSnackbar(item)
            if(Array.isArray(item)){
                return item[0].replace("name", "nama")
            }
            return item.replace("name", "nama")
        })
        
    },[])

    return(
        <ul className='stack-notification-container'>
            {/* {data.map((item, index) => {
                return(
                    <li key={index}>
                        <NotificationCard message={item} index={index}/>
                    </li>
                )
            })} */}
        </ul>
    )
}

StackNotification.defaultProps = {
    data: ['coba', 'haha']
}