import React, { useEffect } from 'react'
import List from '../../components/List'
import ConseliInformation from '../../components/ConseliInformation'
import './Dashboard.css'
import Chat from '../../components/Chat'
import { connect } from 'react-redux'
import CaseConference from './Conselor/CaseConference'
import { Referral } from './Conselor/Referral'

function PageArsip({ setIsChat, isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli }) {
    useEffect(() => {
        console.log({
            page: 'daftarkonseli',
            isChat,
            activeMenu
        })
    })
    if (!(activeMenu == 'Arsip')) {
        return null
    }
    if (isChat === true) {
        return (
            <Chat />
        )
    }

    if (isCaseConferenceOpen) {
        return (
            <CaseConference title='tes' activeConseli={activeConseli}/>
        )
    }

    if (isReferralOpen) {
        return (
            <Referral />
        )
    }

    return (
        <div id="content">
            <List title={"arsip"} />
            <ConseliInformation onChat={() => setIsChat(true)} arsip={true}/>
        </div>
    )
}
const mapStateToProps = ({ isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli }) => ({ isChat, activeMenu, isCaseConferenceOpen, isReferralOpen, activeConseli })

export default connect(mapStateToProps)(PageArsip)