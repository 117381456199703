
import React from 'react'

export default function Footer(props) {
    return (
        <footer className='footer' style={{
            height: 200,
            color: 'white',
            background: '#192B45'
        }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div className='footer-alamat' style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 24,
                        width: 280,
                        alignItems: 'center',

                    }}>
                        <img style={{ height: 92, marginRight: 24 }} src={require('../../assets/images/uksw.png')} />
                        <div>
                            Jl. Diponegoro 52-60 Salatiga-Indonesia 50711 +62 813 9178 2878
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: 180, padding: 28 }}>
                    <a href="https://www.facebook.com/ukswsalatiga1956?_rdc=1&_rdr" target="_blank">
                        <img style={{ height: 42 }} src={require('../../assets/images/landing/icon_landingpage_facebook.svg')} />
                    </a>
                    <a href="https://twitter.com/uksw_salatiga" target="_blank">
                        <img style={{ height: 42 }} src={require('../../assets/images/landing/icon_landingpage_twitter.svg')} />
                    </a>
                    <a href="https://instagram.com/uksw_salatiga" target="_blank">
                        <img style={{ height: 42 }} src={require('../../assets/images/landing/icon_landingpage_instagram.svg')} />
                    </a>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                Copyright 2019 © GMIT - UKSW
            </div>
        </footer>
    )
}