import './style.css'
import Avatar from "../Avatar/Avatar";
import Button from "../Buttons";
import ConselingRecords from "../ConselingRecords";
import {PesanRujukan, RangkumanKonselingPopup} from "../DashboardPopups";
import React, {useEffect, useState} from "react";


export function ArsipInformation(props){
    const [showRangkuman, setShowRangkuman] = useState(false)
    useEffect(()=>{
        console.log(props)
    }, [props])

    if(props.arsipAvailable === false){
        return(
            <div className='information'>
                <div className='conseli-header' >
                    Arsip
                </div>
                <div className='content'>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 32,
                        fontWeight: 'normal'
                    }}>
                        Belum ada Arsip
                    </div>
                </div>
            </div>
        )       
    }

    if(props.activeArsip == null){
        return(
            <div></div>
        )
    }else{
        console.log(props.activeArsip)
    }

    return(
        <div className='information'>
            <div className='conseli-header' >
                Informasi Konseling
            </div>
            <div className='content'>
                <div className='conseli-profile'>
                    <div className={'arsip-konselor-profile'}>
                        <Avatar img={props.activeArsip.konselor.user.avatar}/>
                        <div style={{marginLeft: 8}}>
                            <div style={{fontWeight: 'bold'}}>{props.activeArsip.konselor.nama_konselor}</div>
                            <div>{props.activeArsip.konselor.profesi_konselor}</div>
                            <div>{props.activeArsip.konselor.email_konselor}</div>
                        </div>
                    </div>
                </div>
                <div className='ctas'>
                    <div className='cta' >
                        <Button disabled={true} title='Ruang Konseling' onClick={() => props.onChat()} fontSize={14} width={148} height={28} />
                    </div>
                    <div className='cta'>
                        <Button disabled={true} title='Case Conference' onClick={() => props.openCaseConference()} fontSize={14} width={148} height={28} />
                    </div>
                    <div className='cta' >
                        <Button disabled={true} title='Referal' onClick={() => props.openReferral()} fontSize={12} fontSize={14} width={100} height={28} />
                    </div>
                    <div className='cta'>
                        <Button title='Rangkuman Konseling' onClick={()=>setShowRangkuman(true)} fontSize={14} width={172} height={28} />
                    </div>
                </div>

                <div>
                    <ConselingRecords records={props.activeArsip.rekam_konselings} disabled/>
                </div>
            </div>
            {/*{showPesanRujukan && <PesanRujukan referral_id={activeConseli.referral_id} onPopupDismiss={() => setShowPesanRujukan(false)}/>}*/}
            {showRangkuman && <RangkumanKonselingPopup activeArsip={props.activeArsip} onPopupDismiss={()=>setShowRangkuman(false)}/>}
        </div>
    )
}